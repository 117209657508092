import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Lottie from 'lottie-react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms/ThumborImage/ThumborImage';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { deepLinkParser, getLottieFiles } from '@airtel-tv/utils/GlobalUtil';
import ImageFactory from '@airtel-feature/layout/factories/ImageFactory';
import { ANALYTICS_ACTIONS, RAIL_TYPES } from '@airtel-tv/constants';
import { showModalComponentAction } from '@airtel-tv/ui-lib/molecules/modal-popup/ModalPopupActions';
import RailEventHOC from '../../../shared/hoc/RailEventHOC';
import { ACTION_TO_NOTIFYID, NOTIFY_ID_MODAL_PAYLOAD } from '../../../error-handler/ErrorCodes';
import styles from './xfullbanner.scss';
import { getTileCtaUrl } from '../../../layout/helpers/ClickToActionHelper';
import  CrashCaptureHOC from '@airtel-tv/lib/hoc/CrashCaptureHOC';

const XFullBannerComponent = React.forwardRef((props, ref) => {
    const {
        zionDisplay: { subTitle = '' } = {},
        railTitle = '',
        titleLogo = '',
        tiles = [],
        tileHeight = 0,
        tileWidth = 0,
        showPlayback,
        preferredArtwork = '',
        lottieUrl = '',
        showModalComponentActionDispatch,
        uid,
        railType,
        railId,
        packageId,
        railIndex,
        prevSource,
        currentSource,
        analyticsPageId,
    } = props;
    const [
        railDeepLink,
        handleDeepLink,
    ] = useState('');
    const [
        imageUrl,
        handleImageUrl,
    ] = useState('');
    const [
        animationData,
        handleAnimationData,
    ] = useState(null);

    const bannerClikMeta = {
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        railTitle,
        rail_position: railIndex,
        rail_name: railType,
        rail_id: railId,
        package_id: packageId,
        source_name: currentSource,
        source_page: prevSource,
        rail_type: railType,
        page_id: analyticsPageId,
    };

    useEffect(() => {
        if (lottieUrl) {
            const lottieData = getLottieFiles(lottieUrl);
            lottieData.then((res) => {
                handleAnimationData(res);
            });
        }
        const contentDetails = tiles[0]?.contentDetails || {};
        const deepLink = contentDetails?.deepLink
            ? contentDetails?.deepLink
            : '';
        if (deepLink) {
            // const customDeeplink = 'wynkpremiere://popup/XPP_CLAIM_POPUP_ATB'; // to be removed after testing
            handleDeepLink(deepLinkParser(deepLink));
        }
        else {
            const ctaUrl = getTileCtaUrl({
                category: contentDetails?.programType,
                contentId: contentDetails?.id,
                title: contentDetails?.title,
            });
            if (ctaUrl) {
                handleDeepLink(ctaUrl);
            }
        }
        getImageUrl();
    }, []);

    const getImageUrl = () => {
        const images = props.tiles[0]?.contentDetails.images;
        if (preferredArtwork && images[preferredArtwork]) {
            handleImageUrl(images[preferredArtwork]);
        }
        else {
            handleImageUrl(
                ImageFactory({
                    images,
                    railType: RAIL_TYPES.X_FULL_BANNER,
                }) || '',
            );
        }
    };
    // if(bgImgUrl){
    //     try{
    //         animationData = JSON.parse(bgImgUrl);
    //     }catch(e){
    //         animationData = null;
    //     }
    // }

    const handleOnclick = (e) => {
        if (!railDeepLink) {
            e.preventDefault();
        }

        const notifyId = railDeepLink.substring(1);
        if (ACTION_TO_NOTIFYID[notifyId]) {
            const payload = NOTIFY_ID_MODAL_PAYLOAD[notifyId] || '';
            showModalComponentActionDispatch({
                showModalValue: true,
                componentNameValue: ACTION_TO_NOTIFYID[notifyId],
                overrideCrossButtonValue: false,
                payload: {
                    ...payload,
                    popupVisibleMeta: {
                        asset_name: 'xpp_claim',
                        popup_id: notifyId,
                    },
                    closeClickedMeta: {
                        asset_name: 'Cross Button',
                        popup_id: notifyId,
                    },
                    backGroundCustomClass: '',
                    // popUpcustomClass: '',
                    uid,
                    showLottie: false,
                    closeBtnClickedHandler: () => {},
                },
            });
            e.preventDefault();
        }
    };
    const style = tileHeight > 0 && tileWidth > 0 ? { width: `${tileWidth}px` } : null;
    if (showPlayback) {
        return null;
    }
    return (
        <section 
            className={`x-full-banner-wrapper ${railDeepLink ? '' : 'disable-link'}`}
            ref={ref}
        >
            <section className="d-flex title-wrapper">
                {titleLogo ? (
                    <figure className="title-logo">
                        <ThumborImage
                            src={titleLogo}
                            imageSettings={{
                                1000: 200,
                                500: 200,
                                320: 200,
                            }}
                        />
                    </figure>
                ) : null }
                <div>
                    <h2 className="x-full-title">{railTitle}</h2>
                    <p className="x-full-banner-description">{subTitle}</p>
                </div>
            </section>
            <div
                className="lottie-img"
                style={style}
            >
                <AnalyticsLinkComponent
                    to={railDeepLink || '#'}
                    meta={bannerClikMeta}
                    onClick={(e) => {
                        handleOnclick(e);
                    }}
                >
                    {animationData ? (
                        <Lottie
                            loop
                            animationData={animationData}
                            play
                        />
                    ) : (
                        <ThumborImage
                            src={imageUrl}
                            alt={railTitle}
                            imageSettings={{
                                320: 500,
                                500: 550,
                                1000: 1606,
                                2000: 2000,
                            }}
                            className="alternative-img-lottie"
                        />
                    )}
                </AnalyticsLinkComponent>
            </div>
        </section>
    );
});

function mapStateToProps(state, props) {
    const {
        notify: { showPlayback },
        userConfig: {
            uid = '',
        } = {},
        location: { navigationObj: { prevSource = '', currentSource = '' } = {} } = {},
    } = state;

    return {
        showPlayback,
        uid,
        prevSource,
        currentSource,
    };
}

export default withDeviceUtil(
    withRouter(connect(mapStateToProps, { showModalComponentActionDispatch: showModalComponentAction })(withStyles(styles)(CrashCaptureHOC(RailEventHOC(XFullBannerComponent))))),
);
