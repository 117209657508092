import { EpgAction } from '../actions/EpgAction';

const initialState = JSON.parse(`{
    "fetching": false,
    "programGuide": {},
    "startTime": 0,
    "endTime": 0
}`);

const EpgReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case EpgAction.FETCH_EPG_DETAILS:
            changes = {
                ...state,
                fetching: true,
            };
            break;

        case EpgAction.FETCH_EPG_DETAILS_SUCCESS:
            changes = {
                ...action.epgResponse,
                startTime: action.startTime,
                endTime: action.endTime,
                fetching: false,
            };
            break;

        case EpgAction.FETCH_EPG_DETAILS_ERROR:
            changes = {
                ...state,
                error: action.error,
                fetching: false,
            };
            break;

        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default EpgReducer;
