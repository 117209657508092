/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { getTile } from '../factories/TileFactory';
import { LOCAL_TILE_TYPES } from '@airtel-tv/constants/LayoutConstants';
import ArrowRefCallbackUtil from '../../../utilities/hook-util/ArrowRefCallbackUtil';
import LanguageProvider from '../../../providers/LanguageProvider';
import LinkComponent from '../../shared/components/link/LinkComponent';
import RailEventHOC from '../../shared/hoc/RailEventHOC';
/* COMMENTS TO BE REMOVED WHEN FULL SCHEDULE PAGE IS DEVELOPED */
const ChannelScheduleRailComponent = React.forwardRef((props, ref) => {
    const {
        tiles, currentProgramIndex, channelName, railTitle, railCtaActionUrl, railIndex, packageId,
    } = props;
    const LANGUAGE = LanguageProvider();
    const episodeListRef = React.createRef();

    let tileWidth;
    const init = (setRef) => {
        const localRef = setRef;
        const { clientWidth } = localRef.current;

        // get tile width
        const tileElem = episodeListRef.current.firstChild;
        const { offsetWidth } = tileElem;
        tileWidth = offsetWidth;

        // get current tile to middle of the screen
        const offset = (clientWidth - tileWidth) / 2;
        if (currentProgramIndex !== null) {
            localRef.current.scrollLeft = tileWidth * currentProgramIndex - offset;
        }
    };

    const [
        carouselElementRef,
    ] = ArrowRefCallbackUtil(init);

    function next() {
        if (carouselElementRef.current) {
            carouselElementRef.current.scrollLeft += tileWidth;
        }
    }
    function previous() {
        if (carouselElementRef.current) {
            carouselElementRef.current.scrollLeft -= tileWidth;
        }
    }

    if (!tiles.length) {
        return null;
    }

    const episodesTiles = tiles.map((program, index) => {
        const tileProps = {
            key: `${lodashGet(program, 'contentDetails.id', '')}-${index}`,
            tileType: LOCAL_TILE_TYPES.CHANNEL_SCHEDULE,
            program,
            tilePosition: (index + 1),
            railTitle,
            railPosition: railIndex,
            packageId,
        };
        const tileElement = getTile({
            tileProps,
        });
        return tileElement;
    });


    const channelTitle = LANGUAGE.CHANNEL_SCHEDULE_TITLE
        .replace(/{channelName}/g, channelName);
    /* to be reviewed */
    return (
        <div
            className="catchupWrapper m-b-30 extended-rail-margin"
            ref={ref}
        >
            <div className="title title-with-cta">
                <div className="channelSchedule_title_wrapper">
                    {
                        railCtaActionUrl ? (
                            <>
                                <LinkComponent
                                    to={railCtaActionUrl}
                                    title={channelTitle}
                                >
                                    <h2>
                                        {channelTitle}
                                    </h2>
                                </LinkComponent>
                                <h2 className="channel_fullSchedule">
                                    <LinkComponent
                                        to={railCtaActionUrl}
                                        title={channelTitle}
                                        className="secondary-cta full-schedule-font"
                                    >
                                        {railTitle}
                                        {/* <ThumborImage
                                            src={IMAGE_PATHS.RAIL_TITLE_ARROW}
                                            alt=""
                                        /> */}
                                    </LinkComponent>
                                </h2>
                            </>
                        ) : (
                            <h2>
                                {channelTitle}
                            </h2>
                        )
                    }
                    <div className="channelschedulebutton_holder">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="carousel-arrows">
                                <span className="button-ripple">
                                    <button
                                        type="button"
                                        className="button button-link"
                                        onClick={previous}
                                    >
                                        <i className="icon20 icon-carousel-prev-arrow" />
                                    </button>
                                </span>
                                <span className="button-ripple">
                                    <button
                                        type="button"
                                        className="button button-link"
                                        onClick={next}
                                    >
                                        <i className="icon20 icon-carousel-arrow icon-carousel-next-arrow" />
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="cards-row "
                ref={carouselElementRef}
            >
                <div
                    className="cards-row__inner"
                >
                    <div
                        className="channelSchedule_holder"
                        ref={episodeListRef}
                    >
                        {episodesTiles}
                    </div>

                </div>
            </div>
        </div>
    );
});

ChannelScheduleRailComponent.defaultProps = {
    tiles: [],
    channelName: '',
    railCtaActionUrl: null,
    railIndex: 0,
    packageId: '',
};

ChannelScheduleRailComponent.propTypes = {
    tiles: PropTypes.array, // eslint-disable-line react/forbid-prop-types,
    railTitle: PropTypes.string.isRequired,
    currentProgramIndex: PropTypes.number.isRequired,
    channelName: PropTypes.string,
    railCtaActionUrl: PropTypes.string,
    railIndex: PropTypes.number,
    packageId: PropTypes.string,
};

export default React.memo(withDeviceUtil(RailEventHOC(ChannelScheduleRailComponent)));
