import {
    select,
    call,
    put,
    getContext,
    delay,
    fork,
    takeEvery,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import { RAIL_LIMIT, RAIL_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { apiLogEvent } from '@airtel-tv/analytics/FunctionalEvents';
import LayoutActions, {
    layoutFetchActionSuccess, isSSRDataReset, purgeLayoutData, layoutFetchActionError, searchLayoutUpdate, searchLayoutReset, layoutReset,
} from '@airtel-feature/layout/actions/LayoutActions';
import {
    getAppConfigFromReducer, getAuthConfigFromReducer, getLayoutDetailsFromReducer, getPackageContentsFromReducer, getUserConfigFromReducer,
} from '@airtel-tv/redux/StoreListing';
import LocalRailUtil from '@airtel-feature/layout/utils/LocalRailUtil';
import { mockRailsIfNeeded } from '@airtel-tv/utils/GlobalUtil';
import { LAYOUT_MOCK_PAGES } from '@airtel-feature/layout/sagas/LayoutMock';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { purgePackageData, packageFetchActionSuccess } from '../actions/PackageActions';
import { getAdConfigContentFromRail, getPackageContentFromRailList } from '../helpers/LayoutHelper';
import { filterStringWithPrefix } from '../../../utilities/StringUtil';
// import LocalRailUtil from '../util/LocalRailUtil';
import { getPaginatedPageLayout } from '../../../service/end-points/LayoutApiService';
import { updateUserConfig } from '../../user/UserAction';

// LOCAL FUNCTIONS

// function* fetchPackageData({
//     pageIds,
// } = {}) {
//     const layoutDataCollection = yield select(getLayoutDetailsFromReducer);
//     let packageIdsToFetch = [];
//     if (!pageIds) {
//         return;
//     }

//     for (let index = 0; index < pageIds.length; index++) {
//         const pageId = pageIds[index];
//         const { layoutPackages } = layoutDataCollection[pageId] || {};

//         if (Array.isArray(layoutPackages) && layoutPackages.length > 0) {
//             // get packages ids
//             const packageIds = getPackIdsFromLayout(layoutPackages);

//             // check package ids data is there in reducer
//             const allPackagesContent = yield select(getPackageContentsFromReducer);

//             const packageIdsWithNoData = packageIds.filter(packId => !allPackagesContent[packId]);

//             packageIdsToFetch = [
//                 ...packageIdsToFetch,
//                 ...packageIdsWithNoData,
//             ];

//             // check if there are IDs whose data is not present
//             if (packageIdsWithNoData.length > 0) {
//                 // make key value pair of page rails and put it into rail meta reducer for listing page reuse
//                 const railMetaPayload = {};
//                 layoutPackages.forEach((rail) => {
//                     railMetaPayload[rail.id] = rail;
//                 });

//                 yield put(railFetchActionSuccess({ payload: railMetaPayload }));
//             }
//         }
//     }

//     // fetch data of package ids whose data is not there
//     yield put(packagePartialFetchAction({
//         packageIds: packageIdsToFetch,
//         offset: 0,
//     }));
// }

// function* getLayoutData({
//     pageId,
// }) {
//     const deviceUtil = yield getContext('deviceUtil');
//     const { userProperties } = yield select(getUserConfigFromReducer);

//     // get layout details
//     const layoutPackages = yield call(getPageLayout, {
//         userProperties,
//         pageId,
//         deviceUtil,
//     });

//     const layoutPayload = {
//         [pageId]: {
//             layoutPackages: LocalRailUtil.mapLocalPackageToRail(layoutPackages),
//             error: null,
//             isSSRData: false,
//         },
//     };
//     // update layout
//     yield put(layoutFetchActionSuccess({ payload: layoutPayload }));
// }

function* getPaginatedLayoutData({
    pageId, count,
}) {
    const deviceUtil = yield getContext('deviceUtil');
    const { userContentProperties } = yield select(getUserConfigFromReducer);
    const authConfig = yield select(getAuthConfigFromReducer);

    // get layout details
    const layoutPackages = yield call(getPaginatedPageLayout, {
        userContentProperties,
        pageId,
        deviceUtil,
        offset: 0,
        count,
        token: authConfig.token,
        uid: authConfig.uid,
        au: authConfig.isAuthenticated,
    });

    const railList = LocalRailUtil.mapLocalPackageToRail(layoutPackages.railList);
    const totalFetched = lodashGet(layoutPackages, 'total', 0);
    const fetchMore = lodashGet(layoutPackages, 'nextPage', false);
    const packageData = getPackageContentFromRailList(railList);
    const layoutPayload = {
        [pageId]: {
            layoutPackages: [
                ...railList,
            ],
            error: null,
            isSSRData: false,
            totalFetched,
            fetchMore,
        },
        allLayoutFetch: true,
    };
    // update layout
    yield put(packageFetchActionSuccess({ payload: packageData }));
    yield put(layoutFetchActionSuccess({ payload: layoutPayload }));
}

function* fetchPageData({ pageId }) {
    try {
        yield call(getPaginatedLayoutData, {
            pageId,
            count: RAIL_LIMIT.USER,
        });
    }
    catch (error) {
        console.error(`error fetching${pageId}`, error);
    }
}

// END LOCAL FUNCTIONS

function* fetchAllPageLayoutSaga({
    delaySeconds = 0,
}) {
    yield call(delay, delaySeconds);
    const layoutDataCollection = yield select(getLayoutDetailsFromReducer);

    const { pages } = yield select(getAppConfigFromReducer);
    if (pages) {
        const pageIds = [];
        for (let index = 0; index < pages.length; index++) {
            const page = pages[index];
            // if layout data does not exists
            if (!layoutDataCollection[page.id]) {
                pageIds.push(page.id);
                yield call(fetchPageData, { pageId: page.id });
            }
        }

        // yield call(fetchPackageData, {
        //     pageIds,
        // });
    }

    yield put(isSSRDataReset());
}

// function* fetchPageLayoutSaga(action) {
//     const { pageId } = action;

//     try {
//         yield call(getLayoutData, action);
//         // get package data
//         yield call(fetchPackageData, {
//             pageIds: [
//                 pageId,
//             ],
//         });

//         // FETCH OTHER LAYOUT PACKAGES : "fork" starts a new thread and let this saga finish
//         yield put(allLayoutFetchAction({
//             delaySeconds: ALL_PAGE_DATA_FETCH_DELAY,
//         }));
//     }
//     catch (e) {
//         console.error(e);
//         let error = e && e.data ? e.data : e;

//         if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
//             error = { errorcode: ERROR_CODES.LOCAL1002 };
//         }

//         const payload = {
//             pageId,
//             error,
//         };

//         yield put(layoutFetchActionError({ payload }));
//     }

//     yield put(isSSRDataReset());
// }

function* resetLayoutSaga() {
    const allPackagesContent = yield select(getPackageContentsFromReducer);
    const { staticPackagePrefix } = yield select(getAppConfigFromReducer);
    let staticPackages = {};
    filterStringWithPrefix(Object.keys(allPackagesContent), staticPackagePrefix)
        .forEach((packageID) => {
            staticPackages = {
                ...staticPackages,
                [packageID]: allPackagesContent[packageID],
            };
        });
    const payload = {
        staticPackages,
    };
    yield put(purgeLayoutData());
    yield put(purgePackageData(payload));
}

function* callLayoutLatency(meta) {
    yield call(apiLogEvent, meta);
}

function* fetchPaginatedLayoutSaga(action) {
    const deviceUtil = yield getContext('deviceUtil');
    const layoutDetails = yield select(getLayoutDetailsFromReducer);
    const authConfig = yield select(getAuthConfigFromReducer);
    const { web_pageId: webPageId } = yield select(getAppConfigFromReducer);
    const { token, uid, isAuthenticated } = authConfig;
    const { userContentProperties, userLocation } = yield select(getUserConfigFromReducer);
    const {
        pageId, count, contentId, paramsFromUrl = {}, isSearchLayout = false, query,
        isOnehubRoute,
        useFallBackLayout, isLandingLangaugePage,
    } = action;
    const {
        MORE_LIKE_THIS,
        CAST_N_MORE,
        CONTENT_DETAIL,
        SDK_AD_CONFIG = 'adsConfig',
    } = webPageId;
    const isCdp = pageId === CONTENT_DETAIL;
    const isMultiListRail = [
        MORE_LIKE_THIS,
        CAST_N_MORE,
    ].includes(pageId);
    const customPageId = query ? `${pageId}_${query}` : pageId;
    const currentLayoutData = lodashGet(layoutDetails, `[${customPageId}]`, {});
    const { isSSRData } = currentLayoutData;
    let { layoutPackages: currentRailData = [], totalFetched: offset = 0 } = currentLayoutData;
    if (isCdp && isSSRData && isAuthenticated && currentRailData && currentRailData.length > 0) {
        currentRailData = [
            currentRailData[0],
        ];
        offset = 1;
    }
    if (!isCdp && isSSRData && isAuthenticated) {
        currentRailData = [];
        offset = 0;
    }

    try {
        if (isSearchLayout) {
            yield put(searchLayoutReset({ payload: { isSearchLayout } }));
        }
        const layoutPackages = yield call(getPaginatedPageLayout, {
            userContentProperties,
            pageId,
            offset,
            deviceUtil,
            contentId,
            count,
            token,
            uid,
            au: isAuthenticated,
            userLocation,
            paramsFromUrl,
        });

        yield fork(callLayoutLatency, {
            page_id: pageId,
            offset,
            count,
            request_response_time: layoutPackages?.api_response_time,
        });

        // removed all layout api call from home page for optimization
        // if (!allLayoutFetch) {
        //     yield put(allLayoutFetchAction({
        //         delaySeconds: ALL_PAGE_DATA_FETCH_DELAY,
        //     }));
        // }

        // if( isSSRData && isCdp && layoutPackages?.railList && isArray(layoutPackages.railList) && currentRailData.length > 0){
        //     layoutPackages.railList = layoutPackages.railList.filter((rail)=>rail.format.ty !=='BANNER_CONTENT_RAIL');
        // }
        let railList = LocalRailUtil.mapLocalPackageToRail(layoutPackages.railList);

        railList = mockRailsIfNeeded(railList);

        let adConfigCpMap = {};
        try {
            if (pageId === SDK_AD_CONFIG) {
                adConfigCpMap = getAdConfigContentFromRail(railList);
            }
        }
        catch (err) {
            console.error('Ad config parsing error', err);
        }
        const totalFetched = lodashGet(layoutPackages, 'total', 0);
        const fetchMore = lodashGet(layoutPackages, 'nextPage', false);
        const packageData = getPackageContentFromRailList(railList);
        const languageOfLandingPage = paramsFromUrl ? paramsFromUrl.context_language : '';
        const key = `${pageId}${isMultiListRail && contentId ? `_${contentId}` : ''}${isSearchLayout || (isOnehubRoute && query) ? `_${query}` : ''}${isLandingLangaugePage ? `_${languageOfLandingPage}` : ''}`;
        const layoutPayload = {
            [key]: {
                layoutPackages: [
                    ...currentRailData,
                    ...railList,
                ],
                ...(Object.keys(adConfigCpMap).length ? { adConfigCpMap } : {}),
                error: null,
                isSSRData: false,
                totalFetched: offset + totalFetched,
                fetchMore,
            },
        };
        // update layout
        yield put(packageFetchActionSuccess({ payload: packageData }));
        yield put(layoutFetchActionSuccess({ payload: layoutPayload }));
        if (isSearchLayout) {
            // update searchLayoutInfo
            const searchLayoutPayload = {
                isSearchLayout,
                isEmptyRail: ![
                    ...currentRailData,
                    ...railList,
                ]?.length,
                layoutApiError: false,
                isFetchedData: true,
            };
            yield put(searchLayoutUpdate({ payload: searchLayoutPayload }));
        }
    }
    catch (e) {
        if (useFallBackLayout && LAYOUT_MOCK_PAGES[pageId]) {
            const layoutPackages = LAYOUT_MOCK_PAGES[pageId];
            const railList = LocalRailUtil.mapLocalPackageToRail(layoutPackages.railList);
            const totalFetched = lodashGet(layoutPackages, 'total', 0);
            const fetchMore = lodashGet(layoutPackages, 'nextPage', false);
            const packageData = getPackageContentFromRailList(railList);
            const key = `${pageId}${isMultiListRail && contentId ? `_${contentId}` : ''}${isSearchLayout || (isOnehubRoute && query) ? `_${query}` : ''}`;
            const layoutPayload = {
                [key]: {
                    layoutPackages: [
                        ...currentRailData,
                        ...railList,
                    ],
                    error: null,
                    isSSRData: false,
                    // totalFetched: offset + totalFetched,
                    totalFetched: totalFetched,
                    fetchMore,
                },
            };
            // update layout
            yield put(packageFetchActionSuccess({ payload: packageData }));
            yield put(layoutFetchActionSuccess({ payload: layoutPayload }));
            return;
        }
        const error = e.data ? e.data : e;
        yield put(layoutFetchActionError({ payload: { error } }));
        if (!(currentLayoutData?.packages?.length)) {
            if (isSearchLayout) {
                const searchLayoutPayload = {
                    isSearchLayout,
                    isEmptyRail: true,
                    layoutApiError: true,
                    isFetchedData: true,
                };
                yield put(searchLayoutUpdate({ payload: searchLayoutPayload }));
            }
        }
    }
}


function* fetchSubscriptionLayoutSaga(action) {
    const deviceUtil = yield getContext('deviceUtil');
    const { userContentProperties } = yield select(getUserConfigFromReducer);
    const layoutDetails = yield select(getLayoutDetailsFromReducer);
    const authConfig = yield select(getAuthConfigFromReducer);


    const { pageId, count, cpId } = action;
    const currentLayoutData = lodashGet(layoutDetails, `[${pageId}]`, {});
    const currentRailData = lodashGet(currentLayoutData, 'layoutPackages', []);
    const offset = lodashGet(currentLayoutData, 'totalFetched', 0);
    delete userContentProperties.paidCp;
    try {
        const layoutPackages = yield call(getPaginatedPageLayout, {
            userContentProperties,
            pageId,
            cpId,
            offset,
            deviceUtil,
            count,
            token: authConfig.token,
            uid: authConfig.uid,
        });

        const railList = LocalRailUtil.mapLocalPackageToRail(layoutPackages.railList);
        const totalFetched = lodashGet(layoutPackages, 'total', 0);
        const fetchMore = lodashGet(layoutPackages, 'nextPage', false);
        const packageData = getPackageContentFromRailList(railList);
        const layoutPayload = {
            [`${pageId}:${cpId}`]: {
                layoutPackages: [
                    ...currentRailData,
                    ...railList,
                ],
                error: null,
                isSSRData: false,
                totalFetched: offset + totalFetched,
                fetchMore,
            },
        };
        // update layout
        yield put(packageFetchActionSuccess({ payload: packageData }));
        yield put(layoutFetchActionSuccess({ payload: layoutPayload }));
    }
    catch (e) {
        const error = e.data ? e.data : e;
        yield put(layoutFetchActionError({ payload: { error } }));
    }
}

function* updateUserConfigPurgeLayout() {
    yield put(updateUserConfig({ stopLayoutReset: true }));
    yield put(layoutReset());
}


export default [
    // takeFirst(LayoutActions.LAYOUT_FETCH, fetchPageLayoutSaga),
    takeFirst(LayoutActions.ALL_LAYOUT_FETCH, fetchAllPageLayoutSaga),
    takeFirst(LayoutActions.LAYOUT_RESET, resetLayoutSaga),
    takeEvery(LayoutActions.LAYOUT_PAGINATED_FETCH, fetchPaginatedLayoutSaga),
    takeFirst(LayoutActions.SUBSCRIPTION_LAYOUT_PAGINATED_FETCH, fetchSubscriptionLayoutSaga),
    takeFirst(LayoutActions.UPDATE_USER_CONFIG_PURGE_LAYOUT, updateUserConfigPurgeLayout),

];
