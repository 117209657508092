import {
    call, put, select, getContext,
} from 'redux-saga/effects';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { ChannelAction, fetchAllChannelsSuccess } from '../actions/ChannelAction';
import { getChannelsApi } from '../../../service/end-points/EpgApiService';
import { DEFAULT_CHANNEL_PRODUCT_ID } from '../../../constants/ContentProviderConst';
import { fetchEpgChannelDetails } from '../actions/EpgAction';
import { getUserConfigFromReducer, getEpgFromReducer } from '@airtel-tv/redux/StoreListing';
import { getDistroEpgDetails } from '@airtel-tv/services/DistroApiService';


function* fetchChannelContent({ fetchEpg = true }) {
    try {
        const userConfig = yield select(getUserConfigFromReducer);

        const {
            ln,
            mwTvPack = DEFAULT_CHANNEL_PRODUCT_ID,
        } = userConfig.userContentProperties;

        const { lang } = userConfig.userInfo;
        const deviceUtil = yield getContext('deviceUtil');

        // const channelResponse = yield call(getChannelsApi, {
        //     userContentProperties: userConfig.userContentProperties,
        //     mwTvPack,
        //     ln: lang && lang.length > 0 ? lang[0] : ln,
        //     deviceUtil,
        // });
        const response = yield call(getDistroEpgDetails , {
            deviceUtil,
            cpId: 'DISTROTV',
            includePlayableOnly: true,
            start: 'now',
            end: 'nowPLUS1d',
        });
        yield put(fetchAllChannelsSuccess({
            channelResponse: { channels: response.data },
        }));
    }
    catch (e) {
        console.error(e);
    }
    finally {
        const epgDetails = yield select(getEpgFromReducer);
        if (!epgDetails.fetching && Object.keys(epgDetails.programGuide).length < 1 && fetchEpg) {
            yield put(fetchEpgChannelDetails());
        }
    }
}

export default [
    takeFirst(ChannelAction.FETCH_CHANNELS, fetchChannelContent),
];
