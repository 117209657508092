import { ChannelAction } from '../actions/ChannelAction';

const initialState = JSON.parse(`{
    "channels": [],
    "promotions": [
        {
            "channelId": ""
        }
    ]
}`);

const ChannelReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case ChannelAction.FETCH_CHANNELS_SUCCESS:
            changes = {
                ...action.channelResponse,
            };
            break;

        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default ChannelReducer;
