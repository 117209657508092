import {
    call, put, select, getContext,
} from 'redux-saga/effects';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { LISTING_PAGE } from '@airtel-tv/constants/LayoutConstants';
import { ERROR_PAGE_ACTIONS } from '@airtel-tv/constants';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { isOnline } from '../../../utilities/WindowUtil';
import { getPaginatedPackageDetails, getPackageDetails } from '../../../service/end-points/PackageApiService';
import PackageActions, { packageFetchActionError, packageFetchActionSuccess, packagePartialFetchActionSuccess } from '../actions/PackageActions';
import { getUserConfigFromReducer, getPackageContentsFromReducer } from '@airtel-tv/redux/StoreListing';
import { packageIdForListingPage } from '../../../utilities/CommonUtil';
import { showErrorPage } from '../../notify/NotifyActions';
import LocalRailUtil from '@airtel-feature/layout/utils/LocalRailUtil';


export function* fetchPackages(action) {
    const { packageIds } = action;
    const { userContentProperties } = yield select(getUserConfigFromReducer);
    const deviceUtil = yield getContext('deviceUtil');

    try {
        const packagesDetail = yield call(getPackageDetails, {
            packageIds,
            deviceUtil,
            userContentProperties,
        });
        const payload = {
            ...packagesDetail,
            error: null,
        };

        yield put(packageFetchActionSuccess({ payload }));
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;

        if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        const payload = {
            error,
        };

        yield put(packageFetchActionError({ payload }));
    }
}


function* fetchPartialPackages(action) {
    const { packageIds, offset, count } = action;
    const { userContentProperties } = yield select(getUserConfigFromReducer);
    const deviceUtil = yield getContext('deviceUtil');

    try {
        const packagesDetail = yield call(getPackageDetails, {
            packageIds,
            offset,
            count,
            deviceUtil,
            userContentProperties,
        });

        const continueWatchingData = LocalRailUtil.syncUserDataInPackage();

        const payload = {
            ...packagesDetail,
            ...continueWatchingData,
            error: null,
        };

        yield put(
            packagePartialFetchActionSuccess({
                payload,
                packageIds,
            }),
        );
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;

        if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        const payload = {
            error,
        };

        yield put(packageFetchActionError({ payload }));
    }
}

function* fetchPackage(action) {
    const { packageId } = action;
    const { userContentProperties } = yield select(getUserConfigFromReducer);
    const deviceUtil = yield getContext('deviceUtil');
    try {
        let packageDetail = yield call(getPackageDetails, {
            packageIds: [
                packageId,
            ],
            deviceUtil,
            userContentProperties,
        });

        if (!packageDetail || Object.keys(packageDetail).length < 1) {
            packageDetail = {
                [packageId]: {},
            };
        }

        const payload = {
            ...packageDetail,
            error: null,
        };

        yield put(packageFetchActionSuccess({ payload }));
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;
        if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        const payload = {
            error,
        };

        yield put(packageFetchActionError({ payload }));
    }
}

function* fetchListingPackage(action) {
    const { packageId, offset } = action;
    const { userContentProperties } = yield select(getUserConfigFromReducer);
    const deviceUtil = yield getContext('deviceUtil');
    try {
        let paginatedPackageData = yield call(getPaginatedPackageDetails, {
            packageIds: [
                packageId,
            ],
            deviceUtil,
            count: LISTING_PAGE.COUNT,
            offset,
            userContentProperties,
        });
        const packageContents = yield select(getPackageContentsFromReducer);
        const { id } = paginatedPackageData;
        const listingPackageId = packageIdForListingPage(id);
        if (!paginatedPackageData || Object.keys(paginatedPackageData).length < 1) {
            paginatedPackageData = {
                [listingPackageId]: {},
            };
        }

        let payload = {
            [listingPackageId]: {
                ...paginatedPackageData,
                error: null,
            },
        };

        const packageDetailInLocal = packageContents[listingPackageId];
        if (packageDetailInLocal) {
            payload = {
                [listingPackageId]: {
                    ...paginatedPackageData,
                    content: [
                        ...packageDetailInLocal.content,
                        ...paginatedPackageData.content,
                    ],
                    error: null,
                },
            };
        }
        yield put(packageFetchActionSuccess({ payload }));
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;
        if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        const payload = {
            error,
        };
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        const hasInternet = isOnline();
        const {
            BUTTON_RETRY = 'Retry',
            ERROR_CONST_BLOCKHOLE:
            {
                TITLE = 'Aw snap, something went wrong!',
                SUB_TITLE = 'We are working to get things back up.',
                NO_NETWORK_TITLE = 'Oops! Network Lost',
                NO_NETWORK_SUB_TITLE = "You're not connected to the internet.Please check your connection and try again.",
                NO_NETWORK_IMAGEURL = 'https://image.airtel.tv/grandslam/content/2023_9_14/image_(2).png',
                IMAGE_URL = 'https://image.airtel.tv/grandslam/content/2023_7_5/error_img.png',
                CTA_TEXT = 'Retry',
            }
            = {},
        } = LANGUAGE;
        yield put(showErrorPage({
            errorPageData: {
                message: !hasInternet ? NO_NETWORK_TITLE : TITLE,
                subText: !hasInternet ? NO_NETWORK_SUB_TITLE : SUB_TITLE,
                imageUrl: !hasInternet ? NO_NETWORK_IMAGEURL : IMAGE_URL,
                buttonText: !hasInternet ? CTA_TEXT : BUTTON_RETRY,
                errorAction: ERROR_PAGE_ACTIONS.RELOAD,
            },
        }));
        yield put(packageFetchActionError({ payload }));

        yield put(packageFetchActionError({ payload }));
    }
}


export default [
    takeFirst(PackageActions.PACKAGES_FETCH, fetchPackages),
    takeFirst(PackageActions.PACKAGE_FETCH, fetchPackage),
    takeFirst(PackageActions.PACKAGE_FETCH_PARTIAL, fetchPartialPackages),
    takeFirst(PackageActions.PACKAGE_FETCH_ALL_DATA, fetchListingPackage),
];
