import PackageActions from '../actions/PackageActions';

const initialState = JSON.parse('{}');

const PackageReducer = (state = initialState, action = {}) => {
    let changes = {};
    switch (action.type) {
        case PackageActions.PACKAGE_FETCH_COMPLETED:
            changes = {
                ...action.payload,
            };
            break;

        case PackageActions.PACKAGE_FETCH_ERROR: {
            const { error } = action.payload;

            changes = {
                ...state,
                error,
            };
        }
            break;

        case PackageActions.PACKAGE_FETCH_PARTIAL_SUCCESS:
            changes = {
                ...action.payload,
            };
            break;
        case PackageActions.PURGE_PACKAGE_DATA:
            return {
                ...action.staticPackages,
                error: null,
            };
        case PackageActions.REMOVE_PACKAGE_ERROR:
            changes = {
                error: null,
            };
            break;
        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default PackageReducer;
