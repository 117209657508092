/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { TILE_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { ELEMENT_ID } from '@airtel-tv/constants/GlobalConst';
import { checkWindowExist } from '@airtel-tv/utils';
import getTile from '../factories/TileFactory';
import HorizontalLazyLoaderHOC from '../../shared/hoc/HorizontalLazyLoaderHOC';
import LanguageProvider from '../../../providers/LanguageProvider';
import { IMAGE_PATHS } from '../../../constants/AppConst';
import { getCurrentWindowSourceName } from '../../../utilities/WindowUtil';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import RailEventHOC from '../../shared/hoc/RailEventHOC';

const TrailersRailComponent = (props) => {
    const LANGUAGE = LanguageProvider();
    const [
        hoveredTile,
        setHoveredTile,
    ] = useState('');

    const {
        titleLogo, titleColor, tiles = [], railCtaActionUrl, railIndex, imageSettings, railId, packageId, pageId, railTitle, deviceUtil: { isMobile }, showAll, showMore,
    } = props;

    let lazyTiles = [];
    const carouselElementRef = useRef();
    const trailersRailRef = useRef();
    const [
        indexOfRail,
        setRailIndex,
    ] = useState(0);

    const moreAnalyticsMeta = {
        rail_id: railId,
        // action: ANALYTICS_ACTIONS.MORE_CLICK,
        action: ANALYTICS_ACTIONS.RAIL_CTA_CLICK,
        // source_name: getCurrentWindowSourceName(),
        asset_name: ANALYTICS_ASSETS.SEE_ALL,
        package_id: packageId,
    };

    const tileElements = tiles.map((tile, i) => {
        const tileProps = {
            key: `${tile.id}-${i}`,
            ...tile,
            railCtaActionUrl,
            railPosition: railIndex,
            tilePosition: i,
            railId,
            imageSettings,
            tileType: TILE_TYPES.TRAILERS_RAIL,
            pageId,
            trailersRailRef,
            hoveredTile,
            setHoveredTile,
            packageId,
        };
        const tileElement = getTile({
            tileProps,
        });
        return tileElement;
    });
    const key = `${railId}-${railIndex}`;
    lazyTiles = (
        <HorizontalLazyLoaderHOC
            railKey={key}
            tileElements={tileElements}
        />
    );

    useEffect(() => {
        if (carouselElementRef && carouselElementRef.current) {
            const images = carouselElementRef.current?.children;
            carouselElementRef.current?.addEventListener('scroll', () => {
                const railInd = Math.ceil(carouselElementRef?.current?.scrollLeft / (images[0].offsetWidth + 4));
                setTimeout(() => {
                    setRailIndex(railInd);
                }, 500);
            });
        }
    }, [
        tileElements,
    ]);

    const openAllTiles = () => {
        const { history } = props;
        history.push({
            pathname: railCtaActionUrl,
        });
    };
    const { SEE_ALL = 'See all' } = LANGUAGE;

    const previous = () => {
        if (carouselElementRef.current) {
            const scrollLeft = carouselElementRef.current.clientWidth - 170;
            carouselElementRef.current.scrollLeft -= scrollLeft;
            setRailIndex(indexOfRail - 1);
        }
    };

    const next = () => {
        if (carouselElementRef.current) {
            const images = carouselElementRef.current.children;
            if (images && images.length > 0) {
                const scrollLeft = carouselElementRef.current.clientWidth - 170;
                carouselElementRef.current.scrollLeft += scrollLeft;
                setTimeout(() => {
                    setRailIndex(indexOfRail + 1);
                }, 500);
            }
        }
    };

    const showScrollButtons = () => {
        if (!checkWindowExist()) {
            return;
        }

        const { availWidth } = window.screen;
        const totalTileWidth = tileElements.length * 157;

        return (availWidth - 160) / totalTileWidth < 1;
    };

    const { scrollLeft = 0, clientWidth = 0, scrollWidth = 0 } = carouselElementRef && carouselElementRef.current ? carouselElementRef.current : {};
    const showNext = scrollLeft < (scrollWidth - clientWidth) || (scrollLeft === 0 && tileElements.length > 7);

    return (
        <>
            <section className="m-b-30 home-row margin-padding-0-mweb m-30-bottom">
                <article className="title-side-margin title title-with-cta d-flex justify-content-between align-items-center watch-list-align continue-Watching-row">
                    { showAll && showMore
                        ? (
                            <AnalyticsLinkComponent
                                meta={moreAnalyticsMeta}
                                className="home-page-font text-bold-20 mobile-text-bold-16"
                                to={railCtaActionUrl}
                                id={`${ELEMENT_ID.RAIL_TITLE}-${railIndex}`}
                            >
                                {titleLogo ? (
                                    <ThumborImage
                                        className="cdp-rail-title-logo"
                                        src={titleLogo}
                                        alt=""
                                        imageSettings={{
                                            300: 50,
                                            1000: 100,
                                        }}
                                    />
                                ) : ''}
                                <span style={{ color: titleColor }}>{ railTitle }</span>
                            </AnalyticsLinkComponent>
                        ) : (
                            <span
                                className="home-page-font text-bold-20 mobile-text-bold-16"
                                id={`${ELEMENT_ID.RAIL_TITLE}-${railIndex}`}
                            >
                                {titleLogo ? (
                                    <ThumborImage
                                        className="cdp-rail-title-logo"
                                        src={titleLogo}
                                        alt=""
                                        imageSettings={{
                                            300: 50,
                                            1000: 100,
                                        }}
                                    />
                                ) : ''}
                                <span style={{ color: titleColor }}>{ railTitle }</span>
                            </span>
                        )}
                    {showScrollButtons() }
                    {/* <a className="cdp-see-all-button">
                        {railCtaActionUrl !== null && packageId && packageId !== '' && <button onClick={openAllTiles}>{SEE_ALL}</button> }
                        <svg
                            width="7"
                            height="12"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.25 10.375L5.625 6L1.25 1.625"
                                stroke="#636972"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </a> */}
                </article>
                <section
                    className="pos-relative buttons-external-holder height-fill small gradient-holder"
                    ref={trailersRailRef}
                >
                    { !isMobile() && scrollLeft > 0 && (
                        <AnalyticsButtonComponent
                            className="slide-btn-cdp left trailer-rail landscape"
                            onClick={previous}
                            aria-label="Watch Trailer Rail Scroll Left"
                        >
                            <ThumborImage
                                src={IMAGE_PATHS.SIDE_CHEVRON}
                            />
                        </AnalyticsButtonComponent>
                    ) }
                    <section
                        className="cards-row continue-row cw-only-padding margin-padding-0-mweb m-30-bottom"
                        ref={carouselElementRef}
                    >
                        <ul
                            className="cards-row__inner"
                            id={`${railId}`}
                        >
                            {lazyTiles}
                        </ul>
                    </section>
                    { !isMobile() && showScrollButtons() && showNext && (
                        <AnalyticsButtonComponent
                            className="slide-btn-cdp right trailer-rail landscape"
                            onClick={() => next(lazyTiles.length)}
                            aria-label="Watch Trailer Rail Scroll Right"
                        >
                            <ThumborImage
                                src={IMAGE_PATHS.SIDE_CHEVRON}
                            />
                        </AnalyticsButtonComponent>
                    ) }
                </section>

            </section>
        </>
    );
};

TrailersRailComponent.propTypes = {
    contentDetails: PropTypes.object,
    tiles: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    railCtaActionUrl: PropTypes.string,
    titleLogo: PropTypes.string,
    titleColor: PropTypes.string,
    packageId: PropTypes.string,
};

TrailersRailComponent.defaultProps = {
    contentDetails: {},
    railCtaActionUrl: '',
    titleLogo: '',
    titleColor: '',
    packageId: '',
};

export default withDeviceUtil(withRouter(RailEventHOC(TrailersRailComponent)));
