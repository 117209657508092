export const RelatedContentActions = {
    FETCH_RELATED_CONTENT: 'FETCH_RELATED_CONTENT',
    FETCH_RELATED_CONTENT_SUCCESS: 'FETCH_RELATED_CONTENT_SUCCESS',
    FETCH_RELATED_CONTENT_ERROR: 'FETCH_RELATED_CONTENT_ERROR',

    FETCH_PACKAGE_CONTENT: 'FETCH_PACKAGE_CONTENT',
    FETCH_PACKAGE_CONTENT_SUCCESS: 'FETCH_PACKAGE_CONTENT_SUCCESS',
    FETCH_PACKAGE_CONTENT_ERROR: 'FETCH_PACKAGE_CONTENT_ERROR',

    PEOPLE_RELATED_CONTENT_FETCH: 'PEOPLE_RELATED_CONTENT_FETCH',
    PEOPLE_RELATED_CONTENT_FETCH_SUCCESS: 'PEOPLE_RELATED_CONTENT_FETCH_SUCCESS',
};

export const getRelatedContent = payload => ({
    type: RelatedContentActions.FETCH_RELATED_CONTENT,
    ...payload,
});

export const getRelatedContentSuccess = payload => ({
    type: RelatedContentActions.FETCH_RELATED_CONTENT_SUCCESS,
    ...payload,
});

export const getRelatedContentError = payload => ({
    type: RelatedContentActions.FETCH_RELATED_CONTENT_ERROR,
    ...payload,
});

export const peopleRelatedContentSuccessAction = payload => ({
    type: RelatedContentActions.PEOPLE_RELATED_CONTENT_FETCH_SUCCESS,
    ...payload,
});

export const peopleRelatedContentAction = payload => ({
    type: RelatedContentActions.PEOPLE_RELATED_CONTENT_FETCH,
    ...payload,
});


export default {
    RelatedContentActions,

    peopleRelatedContentAction,
    getRelatedContent,
    getRelatedContentSuccess,
    getRelatedContentError,
};
