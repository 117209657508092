import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import { getTile } from '../factories/TileFactory';
import {
    LOCAL_PACKAGE_NAME, RAIL_TYPES, RAIL_CTA_LIST, TILE_TYPES,
} from '@airtel-tv/constants/LayoutConstants';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { buildTile } from '@airtel-feature/layout/builders/TileBuilder';

import HorizontalLazyLoaderHOC from '../../shared/hoc/HorizontalLazyLoaderHOC';
import { findTileLimit } from '../../../utilities/CommonUtil';
import { CONTENT_IMAGE_TYPES } from '../../../constants/ImagesConst';
import SubscriptionUtil  from '@airtel-tv/utils/SubscriptionUtil';
import { filterOutDuplicate } from '@airtel-tv/utils/ArrayListUtil';
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './SubscribedChannelsRailContainer.scss';

const cssClasses = {
    RAIL: {
        OUTER: '  component-top-padding potrait-rails extended-rail-margin',
    },
    GRID: {
        OUTER: 'partner-channel-section',
    },
};
class SubscribedChannelsRailContainer extends PureComponent {
    
    constructor(props) {

        super(props);
        this.deviceUtil = props.deviceUtil;

        this.isMobile = this.deviceUtil.isMobile();
        this.carouselElementRef = React.createRef();
        this.state = {
            tiles: [],
            hoveredTile: '',
        };
        const windowWidth = this.deviceUtil.getWindowWidth();
        const { tileType } = props;
        this.tileLimit = findTileLimit({
            windowWidth,
            tileType,
        });
    }
    
    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            subscriptions,
            cpDetailsById,
            subscriptionDataById,
        } = nextProps;

        if (!prevState || subscriptions !== prevState.subscriptions) {
            return {
                subscriptions,
                tiles: SubscribedChannelsRailContainer.getComputedTiles({
                    subscriptions,
                    cpDetailsById,
                    subscriptionDataById,
                }),
            };
        }

        return null;
    }

    static getComputedTiles({
        subscriptions,
        cpDetailsById,
        subscriptionDataById,
    }) {
        const railType = RAIL_TYPES.YOUR_CHANNELS_RAIL;
        const tileCtaType = RAIL_CTA_LIST.LISTING;
        const tiles = [];
        const content = subscriptions;
        const removeDuplicateChannel = filterOutDuplicate();
        content
            .filter(item => SubscriptionUtil.isPartnerChannel(cpDetailsById[item.cp]))
            .filter(item => SubscriptionUtil.isSubscribed(item, lodashGet(cpDetailsById[item.cp], 'targetHierarchy', 0)))
            .filter(item => removeDuplicateChannel(item.cp))
            .forEach((item) => {
                const curCp = cpDetailsById[item.cp];
                const images = {
                    [CONTENT_IMAGE_TYPES.LOGOS_TNPT]: curCp.cpIconURL,
                    [CONTENT_IMAGE_TYPES.LOGO]: curCp.logoUrl,
                    [CONTENT_IMAGE_TYPES.CIRCULAR]: curCp.roundLogoUrl,
                    [CONTENT_IMAGE_TYPES.FEATURE_BANNER]: curCp.bannerImageUrl,

                };
                tiles.push(buildTile({
                    item: {
                        ...item,
                        isPartnerChannel: true,
                        images,
                        title: curCp.title,
                        pageLink: curCp.pageLink,
                        cpId: item.cp,
                    },
                    tileCtaType,
                    railType,
                    tileType: TILE_TYPES.PARALLAX,
                    cpDetailsById,
                    subscriptionDataById,
                }));
            });
        return tiles;
    }

    next = () => {
        if (this.carouselElementRef.current) {
            const { clientWidth } = this.carouselElementRef.current;
            this.carouselElementRef.current.scrollLeft += clientWidth / 2;
        }
    }

    previous = () => {
        if (this.carouselElementRef.current) {
            const { clientWidth } = this.carouselElementRef.current;
            this.carouselElementRef.current.scrollLeft -= clientWidth / 2;
        }
    }
    setHoveredTile = (hoveredTile) => {
        this.setState({
            hoveredTile
        })
    }

    render() {
        const {
            railTitle, railCtaActionUrl, railIndex, railId, imageSettings, showMore, showGrid, packageId,
        } = this.props;
        const { tiles, hoveredTile } = this.state;
        let lazyTiles = [];

        if (tiles && Array.isArray(tiles) && tiles.length < 1) {
            return null;
        }

        let showArrows = false;

        // const tilesToShow = this.isMobile ? tiles.slice(0, MAX_TILE_LIMIT_MWEB.LANDSCAPE) : tiles.slice(0, MAX_TILE_LIMIT_WEB.LANDSCAPE);
        if (tiles && Array.isArray(tiles)) {
            const tileElements = tiles.map((tile, i) => {
                const tileProps = {
                    key: `${tile.id}-${railId}`,
                    ...tile,
                    railCtaActionUrl,
                    railPosition: railIndex,
                    tilePosition: i,
                    railId,
                    imageSettings,
                    hoveredTile,
                    setHoveredTile: this.setHoveredTile,
                    packageId,
                };

                const tileElement = getTile({
                    tileProps,
                    onRemoveIconClick: this.removeContinueWatchingContent,
                });

                return tileElement;
            });
            const key = `${railId}-${railIndex}`;
            lazyTiles = (
                <HorizontalLazyLoaderHOC
                    tileElements={tileElements}
                    tileLimit={this.tileLimit}
                    railKey={key}
                />
            );
            showArrows = (tileElements && tileElements.length > this.tileLimit - 1);
        }
        const cssClassApplicable = showGrid ? cssClasses.GRID : cssClasses.RAIL;
        return (
            <div className={cssClassApplicable.OUTER}>
                <div className="title title-with-cta d-flex justify-content-between align-items-center watch-list-align">
                    <h2 className="home-page-font text-bold-20 mobile-text-bold-16">{`${railTitle || LOCAL_PACKAGE_NAME.YOUR_CHANNELS}`}</h2>
                    <div className={(showArrows || showMore) ? 'd-flex justify-content-between align-items-center' : 'd-none'}>
                        <div className={!showArrows ? 'd-none' : 'carousel-arrows'}>
                            <span className="button-ripple">
                                <button
                                    type="button"
                                    className="button button-link"
                                    onClick={this.previous}
                                >
                                    { /* eslint-enable */}
                                    <i className="icon20 icon-carousel-prev-arrow" />
                                </button>
                            </span>

                            <span className="button-ripple">
                                <button
                                    type="button"
                                    className="button button-link"
                                    onClick={this.next}
                                >
                                    { /* eslint-enable */}
                                    <i className="icon20 icon-carousel-arrow icon-carousel-next-arrow" />
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className="cards-row"
                    ref={this.carouselElementRef}
                    draggable="false"
                    // eslint-disable-next-line jsx-a11y/aria-role
                    role="carousel"
                >
                    <div className="cards-row__inner">
                        {lazyTiles}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        userConfig: {
            subscriptions,
            subscriptionDataById,
        },
        appConfig: {
            cpDetailsById,
        },
    } = state;
    return {
        subscriptions,
        cpDetailsById,
        subscriptionDataById,
    };
}

SubscribedChannelsRailContainer.defaultProps = {
    railIndex: 0,
    railId: '',
    railCtaActionUrl: '',
    showMore: false,
    showGrid: false,
    packageId: '',
};

SubscribedChannelsRailContainer.propTypes = {
    railTitle: PropTypes.string.isRequired,
    // railCtaText: PropTypes.string.isRequired,
    showGrid: PropTypes.bool,
    railCtaActionUrl: PropTypes.string,
    railIndex: PropTypes.number,
    railId: PropTypes.string,
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showMore: PropTypes.bool,
    tileType: PropTypes.string.isRequired,
    cpDetailsById: PropTypes.objectOf(PropTypes.object).isRequired,
    subscriptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    subscriptionDataById: PropTypes.objectOf(PropTypes.object).isRequired,
    packageId: PropTypes.string,
};

export default connect(mapStateToProps, null)(withDeviceUtil(withStyles(styles)(SubscribedChannelsRailContainer)));
