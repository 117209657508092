import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { getCurrentProgram } from '../../../utilities/CommonUtil';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { ANALYTICS_ACTIONS } from '../../../constants/AnalyticsConst';
// import { tileClickGaEvent } from '../../../utilities/GaEvents';

const ChannelTileContainer = (props) => {
    const {
        imageUrl, tileCtaActionUrl, id, title, programGuide, railPosition, tileType, railId, tilePosition, cpId, imageSettings, packageId,
    } = props;

    const tileAnalyticsMeta = {
        cp_name: !cpId ? '' : cpId,
        content_id: id,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        package_id: packageId,
    };

    // const newClass = `cards ${isMobile ? 'cards-channel-landscape-mobile' : 'cards-channel-landscape'}`
    const program = programGuide && programGuide[id] ? getCurrentProgram(programGuide[id]) : false;
    return (
        <AnalyticsLinkComponent
            className="cards cards-channel-landscape contain-layout"
            key={id}
            to={tileCtaActionUrl}
            meta={tileAnalyticsMeta}
            title={title}
            id={`atm_channel-tile-${tilePosition + 1}`}
            // onClick={() => tileClickGaEvent(id)}
        >
            <div className="cards-img-wrap">
                <ThumborImage
                    className="card-img"
                    src={imageUrl}
                    alt={title}
                    imageSettings={imageSettings}
                    transparent
                />
                <div className="overlay">
                    <div className="card-center-play-icon">
                        <i className="icon40 icon-player-play" />
                    </div>
                </div>
            </div>
            <h6 className="card-title channel-text">{(program && program.title) ? program.title : title}</h6>
        </AnalyticsLinkComponent>
    );
};

ChannelTileContainer.defaultProps = {
    imageUrl: '',
    tileCtaActionUrl: '',
    title: '',
    programGuide: {},
    cpId: '',
    tileType: '',
    railPosition: 0,
    tilePosition: 0,
    railId: '',
    packageId: '',
};

ChannelTileContainer.propTypes = {
    imageUrl: PropTypes.string,
    tileCtaActionUrl: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string.isRequired,
    programGuide: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    cpId: PropTypes.string,
    railPosition: PropTypes.number,
    tileType: PropTypes.string,
    tilePosition: PropTypes.number,
    railId: PropTypes.string,
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    packageId: PropTypes.string,
};

const mapStateToProps = (state) => {
    const { epg } = state;
    return {
        programGuide: epg.programGuide,
    };
};

export default connect(mapStateToProps, {})(React.memo(ChannelTileContainer));
