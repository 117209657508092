import lodashGet from 'lodash/get';
import lodashIsEqual from 'lodash/isEqual';
import lodashStartCase from 'lodash/startCase';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { PropTypes } from 'prop-types';
import { LISTING_PAGE_ID_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { buildRail } from '@airtel-feature/layout/builders/RailBuilder';
import { checkWindowExist, LocationUtil } from '@airtel-tv/utils';
import CrashCaptureHOC from '@airtel-tv/lib/hoc/CrashCaptureHOC';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { DEFAULT_NAV } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { getLangfromCode } from '@airtel-tv/utils/ContentMetaUtil';
import { BREADCRUMBS_NAME, DIMENSION_PAGE_MAP } from '@airtel-tv/constants/GlobalConst';
import withStyles from 'isomorphic-style-loader/withStyles';
import { packageFetchAllDataAction } from '../actions/PackageActions';
import ErrorHandler from '../../error-handler/ErrorHandler';
import LoaderComponent from '../../shared/components/loader/LoaderComponent';
import { getRail } from '../../rail/factories/RailFactory';
import {
    urlStringToCaption, replaceAll, packageIdForListingPage, getTileType, getDimensionMeta, getDimensionPageContent,
} from '../../../utilities/CommonUtil';
import { SORT_TYPES, INTERSECTION_OBSERVER_THRESHOLD } from '../../../constants/AppConst';
import LanguageProvider from '../../../providers/LanguageProvider';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { changeMetaData } from '../../notify/NotifyActions';
import { railFetchAction } from '../actions/RailActions';
import { fetchAllChannels } from '../../rail/actions/ChannelAction';
import IntersectionObservableHOC from '../../shared/hoc/IntersectionObservableHOC';
import SmallLoaderComponent from '../../shared/components/loader/SmallLoaderComponent';
import MarkupFactory from '../../watch-actions/factories/MarkupFactory';
import { updatePageBreadCrumbs } from '../../breadcrumbs/actions/BreadCrumbsActions';
import BreadCrumbsUtil from '../../breadcrumbs/util/BreadCrumbsUtil';
import { updateOnPageDescriptionAction } from '../../footer/actions/FooterAction';
import { fetchBlogs } from '../../blog/BlogActions';
import { formTitle, newDimensionUrlHelper } from '../../dimension-pages/helper/DimensionPageHelper';
import styles from './ListingContainer.scss';

class ListingContainer extends Component {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.state = {
            errorLocal: null,
        };
        this.LANGUAGE = LanguageProvider();
        if (!checkWindowExist()) {
            this.updateSeoMeta();
        }
        this.queryParams = LocationUtil.getQueryParams(this.props);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            packageId,
            packageDetails,
            railDetails,
            railId,
            channels,
        } = nextProps;

        // check if there is new content on page
        const newContentAvailable = !lodashIsEqual(packageDetails, prevState.packageDetails);
        const newRailAvailable = !lodashIsEqual(railDetails, prevState.railDetails);

        if (packageId !== prevState.packageId
            || newContentAvailable
            || newRailAvailable
            || channels !== prevState.channels) {
            return {
                packageId,
                packageDetails,
                railId,
                railDetails,
                channels,
            };
        }

        return null;
    }

    componentDidMount() {
        const { packageId } = this.state;
        const { fetchBlogsDispatch, cdpPostFooterMapping, blogConfig } = this.props;
        if (packageId !== '' && cdpPostFooterMapping && Object.keys(cdpPostFooterMapping).length > 0 && cdpPostFooterMapping[packageId] !== '') {
            const postId = cdpPostFooterMapping?.[packageId] || '';
            if (postId) {
                if (blogConfig?.cdpOnPageData && blogConfig?.cdpOnPageData[postId]) {
                    return;
                }
                fetchBlogsDispatch({ postId });
            }
        }
        this.fetchChannels();
        this.init();
    }

    componentDidUpdate(prevState, prevProps) {
        this.init(prevState, prevProps);
    }

    componentWillUnmount() {
        const { updateOnPageDescriptionActionDispatch } = this.props;
        updateOnPageDescriptionActionDispatch({
            onPageDescription: null,
        });
    }

    getTitle = () => {
        const { railDetails, packageDetails } = this.state;
        const {
            match: {
                params: {
                    title: titleFromParams = '',
                    category = '',
                    pageTitle = '',
                },
            } = {},
        } = this.props;
        if (pageTitle && category) {
            return urlStringToCaption(category);
        }
        return urlStringToCaption(titleFromParams);

        //return this.queryParams?.l2 ? urlStringToCaption(titleFromParams) : lodashGet(railDetails, 'format.t', lodashGet(packageDetails, 'title', urlStringToCaption(titleFromParams)));
    };

    fetchMore = () => {
        const {
            packageId,
            packageDetails,
        } = this.state;
        const {
            packageFetchAllDataActionDispatch,
        } = this.props;
        const offset = lodashGet(packageDetails, 'content.length', 0);
        if (packageId) {
            packageFetchAllDataActionDispatch({
                packageId,
                offset,
            });
        }
        else {
            this.setState({
                errorLocal: ERROR_CODES.LOCAL1010,
            });
        }
    };


    findCurrentPageId = (pageUrlName, pageId) => {
        const { pageDataById } = this.props;
        return pageDataById[pageId].urlName === pageUrlName;
    };

    fetchChannels() {
        const { channels, fetchAllChannelsDispatch } = this.props;

        if (channels.length <= 1) {
            fetchAllChannelsDispatch();
        }
    }

    // eslint-disable-next-line consistent-return
    init(prevState = {}, prevProps = {}) {
        // packageDetails :: default value is null
        const {
            packageId, packageDetails,
        } = this.state;

        const { packageId: previousPackageId, railId: previousRailId } = prevProps;

        // fetch layout
        const {
            railId,
            railFetchActionDispatch,
            railDetails,
        } = this.props;

        // fetch package data
        if (previousPackageId !== packageId && packageId) {
            if ((!packageDetails || Object.keys(packageDetails).length < 1) || packageDetails.content.length < 1 || packageDetails.count > packageDetails.content.length) {
                this.fetchMore();
            }
        }

        // fetch rail data
        if (previousRailId !== railId && railId) {
            if (!railDetails || Object.keys(railDetails).length < 1) {
                return railFetchActionDispatch({
                    railIds: [
                        railId,
                    ],
                });
            }
        }
        // update page description in footer
        const { location: prevLocation = {} } = prevState;
        const { pathname: prevPathname = '' } = prevLocation;
        const { pathname = '' } = location;
        if (prevState.packageDetails != packageDetails || prevPathname !== pathname) {
            this.updateSeoMeta();
        }
    }

    updateSeoMeta = () => {
        const {
            packageDetails,
        } = this.state;

        // fetch layout
        const {
            changeMetaDataDispatch,
            match,
            railId,
            railFetchActionDispatch,
            railDetails,
            updatePageBreadCrumbsDispatch,
            pageDataById,
            updateOnPageDescriptionActionDispatch,
            cpDetailsById,
            blogConfig,
            cdpPostFooterMapping,
            pageTitle,
            category,
            packageId,
            lang,
            dimensionPageTitle,
            customCdpContent,
            location: { pathname = '' },
            genres,
            isListPage
        } = this.props;
        // const packageName = lodashGet()
        const packageTitle = lodashGet(packageDetails, 'title', '');
        const fallBackTitle = lodashGet(railDetails, 't', packageTitle);
        const metaTitle = isListPage ? railDetails?.format?.t || lodashStartCase(this.getTitle() || '') || fallBackTitle : railDetails?.format?.t || fallBackTitle;

        // custom seo meta
        let pageUrlName;
        let pageId;
        if (
            packageDetails
            && packageDetails.id
            && ((railDetails && railId) || (!pageTitle && !category))) {
            if (railDetails && railId) {
                pageUrlName = lodashGet(railDetails, 'format.action.meta.pageUrlName', 'home');
                pageId = Object.keys(pageDataById).find(page => this.findCurrentPageId(pageUrlName, page));
            }
            else if (!pageTitle
                && !category) {
                pageId = Object.keys(pageDataById).find(page => this.findCurrentPageId(DEFAULT_NAV.HOME, page));
            }

            const onPageDescription = lodashGet(packageDetails, 'seoData.seoOnPageContent', '');
            const { cdpOnPageData = {} } = blogConfig;
            const postId = (cdpPostFooterMapping?.[packageDetails?.id] || cdpPostFooterMapping?.[pathname]) || '';
            const { content = {}, yoast_head_json: headJson = {} } = (postId && cdpOnPageData?.[postId]) || {};

            const seoTitle = (headJson && headJson.title) || lodashGet(packageDetails, 'seoData.seoTitle', '');
            const seoDescription = (headJson && headJson.description) || lodashGet(packageDetails, 'seoData.seoDescription', '');
            const seoKeywords = lodashGet(packageDetails, 'seoData.seoKeywords', '');
            if (content && Object.keys(content).length > 0) {
                const { rendered } = content;
                updateOnPageDescriptionActionDispatch({
                    onPageDescription: rendered,
                });
            }
            else if (onPageDescription) {
                updateOnPageDescriptionActionDispatch({
                    onPageDescription,
                });
            }

            const cpId = lodashGet(packageDetails, 'content[0].cpId', null);
            const seoPackageTitle = lodashGet(cpDetailsById[cpId], 'seoPackageTitle', '');
            const seoPackageDescription = lodashGet(cpDetailsById[cpId], 'seoPackageDescription', '');
            const pageTitle = pageDataById[pageId]?.title;
            const curPageLink = RoutingUtil.getPackageListingUrlV2({
                packageId: packageDetails.id,
                title: metaTitle,
                railId,
            });
            const breadCrumbsList = BreadCrumbsUtil.listingPageBreadCrumbs({
                contentDetails: {
                    title: metaTitle,
                    programType: pageUrlName,
                    pageTitle,
                },
                curPageLink,
            });
            const seoMarkups = MarkupFactory({
                itemList: packageDetails,
                baseUrl: this.deviceUtil.getBaseUrl(),
                breadCrumbsList,
            });
            let finalTitle;
            let finalDescription;
            if (seoPackageTitle && seoPackageDescription) {
                finalTitle = seoTitle || replaceAll(seoPackageTitle, '{railName}', metaTitle);
                finalDescription = seoDescription || replaceAll(seoPackageDescription, '{railName}', metaTitle);
            }
            else {
                finalTitle = seoTitle || replaceAll(this.LANGUAGE.LISTING_PAGE_META_TITLE, '{railName}', metaTitle);
                finalDescription = seoDescription || replaceAll(this.LANGUAGE.LISTING_PAGE_META_DESCRIPTION, '{railName}', metaTitle);
            }
            changeMetaDataDispatch({
                headMetaData: {
                    title: finalTitle,
                    description: finalDescription,
                    link: curPageLink,
                    keywords: seoKeywords,
                    watchActionCatalogues: seoMarkups,
                },
            });
            updatePageBreadCrumbsDispatch({ breadCrumbsList });
        }
        else if (packageId && pageTitle && dimensionPageTitle && category) {
            // seo update for dimension page
            const queryParams = LocationUtil.getQueryParams(this.props);
            const genreName = genres && genres?.length ? genres[0] : '';
            const language = lang && lang?.length ? lang[0] : null;
            // breadcrumb update
            const breadCrumbTitle = formTitle({
                genres: genreName,
                pageTitle: dimensionPageTitle[0],
                lang: getLangfromCode({ lang: language }),
            });

            const canonicalUrl = RoutingUtil.getDimensionPageUrlV2({
                pageTitle: DIMENSION_PAGE_MAP[dimensionPageTitle[0]],
                genres: genreName,
                lang: getLangfromCode({ lang: language }),
                queryParams,
            });
            const breadCrumbsList = BreadCrumbsUtil.dimensionPageBreadCrumbs({
                pageTitle: dimensionPageTitle[0],
                prefix: language,
                title: breadCrumbTitle,
                curPageLink: canonicalUrl,
            });

            updatePageBreadCrumbsDispatch({ breadCrumbsList });
            // head meta and footer page description update
            let seoTitle; let seoDescription; let
                seoFooterContent;

            // write blogPost logic here
            // this is dimention page, whose footer is defined in cms customMapping
            const { cdpOnPageData = {} } = blogConfig;
            const postId = pathname && cdpPostFooterMapping?.[pathname];
            if (postId) {
                const { content = {}, yoast_head_json: headJson = {} } = (postId && cdpOnPageData?.[postId]) || {};
                if (content && Object.keys(content).length > 0) {
                    const { rendered } = content;
                    updateOnPageDescriptionActionDispatch(
                        { onPageDescription: rendered },
                    );
                }
                if (headJson) {
                    const { description = '', title = '', og_description = '' } = headJson;
                    const headMetaData = {
                        title,
                        description: description || og_description,
                        link: canonicalUrl,
                    };
                    changeMetaDataDispatch({
                        headMetaData,
                    });
                }
            }
            else {
                ({ title: seoTitle, description: seoDescription } = getDimensionMeta({
                    genres: genreName,
                    lang: getLangfromCode({ lang: language }),
                    pageTitle: dimensionPageTitle[0],
                }));

                (seoFooterContent = getDimensionPageContent({
                    pageTitle: dimensionPageTitle[0],
                    genres: genreName,
                    lang: getLangfromCode({ lang: language }),
                }));
                updateOnPageDescriptionActionDispatch({
                    onPageDescription: seoFooterContent,
                });
                const watchActionCatalogues = MarkupFactory({
                    contentDetails: {
                        programType: BREADCRUMBS_NAME.ARTISTS,
                    },
                    baseUrl: this.deviceUtil.getBaseUrl(),
                    breadCrumbsList,
                });
                changeMetaDataDispatch({
                    headMetaData: {
                        title: seoTitle,
                        description: seoDescription,
                        link: canonicalUrl,
                        watchActionCatalogues,
                    },
                });
            }
        }
    };


    render() {
        const {
            packageDetails,
            railDetails,
            channels,
            errorLocal,
        } = this.state;


        const {
            error, cpDetailsById, subscriptionDataById, themeConfig,
            artwork,
            match: {
                params: {
                    pageTitle = '',
                    category = '',
                },
            },
            location: {
                search = '',
            } = {},
        } = this.props;


        const title = this.getTitle();

        if (error || errorLocal) {
            ErrorHandler({
                error,
                code: error.errorCode || errorLocal,
            });
            return null;
        }

        // check no content found
        const noContent = lodashGet(railDetails, 'noData') || (packageDetails && (Object.keys(packageDetails).length < 1 || packageDetails.content.length < 1));

        // show no content page
        if (noContent) {
            // no content  found
            ErrorHandler({ code: ERROR_CODES.LOCAL1004 });
            return null;
        }


        if (packageDetails === null) {
            return <LoaderComponent pageType="listing" />;
        }

        let railProps = null;
        const explicitTileType = lodashGet(railDetails, 'format.ty', '');
        const programType = packageDetails.content[0].programType ? packageDetails.content[0].programType : null;
        const cpId = packageDetails.content[0].cpId ? packageDetails.content[0].programType : null;
        const railTitle = lodashStartCase(title);
        const preferredArtwork = this.queryParams.pa || artwork;
        const urlParams = new URLSearchParams(search);
        const showCatalogTag = urlParams.get('showCatalogTag') || false;
        let excludeCatalogTag = urlParams.get('excludeCatalogTag') || [];
        if (excludeCatalogTag.length) {
            try {
                excludeCatalogTag = JSON.parse(excludeCatalogTag);
            } catch (error) {
                console.error('Error parsing query parameter:', error);
            }
        }

        const tileType = getTileType({
            pageTitle: programType,
            explicitTileType,
            cpId,
            preferredArtwork,
        });
        railProps = buildRail({
            items: packageDetails.content,
            totalCount: packageDetails.totalContentCount,
            explicitTileType: tileType,
            explicitRailTitle: railTitle,
            channels,
            explicitShowGrid: true,
            themeConfig,
            cpDetailsById,
            subscriptionDataById,
            preferredArtwork,
            showCatalogTag,
            excludeCatalogTag,
        });

        railProps.sortConfig = [
            {
                id: 1,
                title: this.LANGUAGE.SORT_A2Z,
                sortingType: SORT_TYPES.A2Z,
                sortTargetPath: 'title',
            },
            {
                id: 2,
                title: this.LANGUAGE.SORT_Z2A,
                sortingType: SORT_TYPES.Z2A,
                sortTargetPath: 'title',
            },
            {
                id: 3,
                title: this.LANGUAGE.SORT_NEWLY_ADDED,
                sortingType: SORT_TYPES.DATE,
                sortTargetPath: 'createdAt',
            },
        ];

        const railElement = getRail({ railProps });
        const loader = lodashGet(packageDetails, 'content.length', 0) < lodashGet(packageDetails, 'totalContentCount', 0) ? <SmallLoaderComponent /> : null;
        return (
            <div className={`${pageTitle && category ? 'dimension-wrapper' : ''}`}>
                <IntersectionObservableHOC
                    fetchMore={this.fetchMore}
                    hasMore={lodashGet(packageDetails, 'content.length', 0) < lodashGet(packageDetails, 'totalContentCount', 0)}
                    loaderElem={loader}
                    threshold={INTERSECTION_OBSERVER_THRESHOLD.NOT_LOADED}
                >
                    {railElement}
                </IntersectionObservableHOC>
            </div>
        );
    }
}

ListingContainer.defaultProps = {
    error: null,
    railId: null,
    railDetails: null,
    packageDetails: null,
    packageId: null,
    channels: [],
    cdpPostFooterMapping: {},
};

ListingContainer.propTypes = {
    changeMetaDataDispatch: PropTypes.func.isRequired,
    railFetchActionDispatch: PropTypes.func.isRequired,
    packageFetchAllDataActionDispatch: PropTypes.func.isRequired,
    error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    railId: PropTypes.string,
    railDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    packageId: PropTypes.string,
    packageDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    channels: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    fetchAllChannelsDispatch: PropTypes.func.isRequired,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    updatePageBreadCrumbsDispatch: PropTypes.func.isRequired,
    pageDataById: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    themeConfig: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    cpDetailsById: PropTypes.objectOf(PropTypes.object).isRequired,
    subscriptionDataById: PropTypes.objectOf(PropTypes.object).isRequired,
    updateOnPageDescriptionActionDispatch: PropTypes.func.isRequired,
    fetchBlogsDispatch: PropTypes.func.isRequired,
    cdpPostFooterMapping: PropTypes.object,
};

function mapStateToProps(state, props) {
    const {
        packageContents, railsMeta, channel,
        appConfig: {
            pageDataById,
            cpDetailsById,
            cdpPostFooterMapping,
            dimensionUrlPackageIdMap = {},
            customCdpContent,
        },
        userConfig: {
            subscriptionDataById,
        },
        themeConfig: {
            configs,
        },
        blogConfig,
        appConfig,
    } = state;
    const { match, location: { pathname = '' } } = props;
    const [, list, ] = (pathname.split('/') || []).filter(r => !!r)
    const isListPage = list === 'list';
    const { params: { id, pageTitle, category } } = match;
    let genres; let
        lang;
    let dimensionPageTitle = [];

    // THIS COMPONENT CAN HANDLE 2 CASES
    // FIRST CASE : list by package
    // SECOND CASE : list by rail
    const query = LocationUtil.getQueryParams(props) || {};
    const { type: idType = LISTING_PAGE_ID_TYPES.PACKAGE, artwork = '' } = query;

    const railId = idType === LISTING_PAGE_ID_TYPES.PACKAGE ? null : id;
    const railDetails = railId ? railsMeta[railId] : null;

    let packageId = idType === LISTING_PAGE_ID_TYPES.PACKAGE ? id : lodashGet(railDetails, 'contents[0].packageId', null);
    if (!packageId && pageTitle && category) {
        packageId = dimensionUrlPackageIdMap[pathname] || null;
        ({ genres, lang, pageTitle: dimensionPageTitle } = newDimensionUrlHelper(
            appConfig,
            match,
            query,
        ));
    }

    const listingPagePkgId = packageIdForListingPage(packageId);
    const packageDetails = listingPagePkgId ? (packageContents[listingPagePkgId] || null) : null;
    return {
        packageDetails,
        railDetails,
        error: packageContents.error,
        packageId,
        railId,
        channels: channel.channels,
        pageDataById,
        themeConfig: configs,
        cpDetailsById,
        subscriptionDataById,
        artwork,
        blogConfig,
        cdpPostFooterMapping,
        genres,
        lang,
        dimensionPageTitle,
        pageTitle,
        category,
        customCdpContent,
        isListPage,
    };
}

export default withRouter(connect(
    mapStateToProps, {
        changeMetaDataDispatch: changeMetaData,
        railFetchActionDispatch: railFetchAction,
        fetchAllChannelsDispatch: fetchAllChannels,
        packageFetchAllDataActionDispatch: packageFetchAllDataAction,
        updatePageBreadCrumbsDispatch: updatePageBreadCrumbs,
        updateOnPageDescriptionActionDispatch: updateOnPageDescriptionAction,
        fetchBlogsDispatch: fetchBlogs,
    },
)(CrashCaptureHOC(withDeviceUtil(withStyles(styles)(ListingContainer)))));
