/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { getTile } from '../factories/TileFactory';
import { LOCAL_TILE_TYPES } from '@airtel-tv/constants/LayoutConstants';
import HorizontalLazyLoaderHOC from '../../shared/hoc/HorizontalLazyLoaderHOC';
import { findTileLimit } from '../../../utilities/CommonUtil';

const CatchupEpisodeRailComponent = (props) => {
    const {
        tiles, railTitle, deviceUtil, channelId, railIndex, packageId,
    } = props;
    const windowWidth = deviceUtil.getWindowWidth();
    const tileLimit = findTileLimit({
        windowWidth,
        tileType: LOCAL_TILE_TYPES.CATCHUP_EPISODE_TILE,
    });

    const episodesTiles = tiles.map((episode) => {
        const tileProps = {
            tileType: LOCAL_TILE_TYPES.CATCHUP_EPISODE_TILE,
            episode,
            packageId,
        };

        const tileElement = getTile({
            tileProps,
        });

        return (
            <div
                className="catchupList"
                key={episode.refId}
            >
                {tileElement}
            </div>
        );
    });

    const key = `${channelId}-${railIndex}`;
    const lazyTiles = (
        <HorizontalLazyLoaderHOC
            tileElements={episodesTiles}
            tileLimit={tileLimit}
            railKey={key}
        />
    );
    /* to be reviewed */
    return (
        <div className="catchupWrapper carousel-left-margin carousel-right-margin m-b-30 extended-rail-margin">
            <div className="title title-with-cta">
                <h2>{railTitle}</h2>
            </div>
            <div className="cards-row">
                <div className="cards-row__inner">{lazyTiles}</div>
            </div>
        </div>
    );
};

CatchupEpisodeRailComponent.defaultProps = {
    tiles: [],
    railIndex: 0,
    packageId: '',
};

CatchupEpisodeRailComponent.propTypes = {
    tiles: PropTypes.array, // eslint-disable-line react/forbid-prop-types,
    railTitle: PropTypes.string.isRequired,
    deviceUtil: PropTypes.object.isRequired,
    channelId: PropTypes.string.isRequired,
    railIndex: PropTypes.number,
    packageId: PropTypes.string,
};

export default withDeviceUtil(CatchupEpisodeRailComponent);
