// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".partner-channel-section{padding:48px 90px 0px 90px}@media screen and (max-width: 991px){.partner-channel-section{padding:48px 43px 0px 43px}}@media screen and (max-width:767px),(max-height:500px)and (max-width:990px){.partner-channel-section{padding:34px 16px 0px 16px}.partner-channel-section .rail-loader{width:auto;min-height:auto}}", ""]);
// Exports
exports.locals = {
	"mobileWidth": "767px",
	"mediaLandscapeHeight": "500px",
	"mediaLandscapeWidth": "990px",
	"tabletLargeWidth": "1200px"
};
module.exports = exports;
