import {
    call, put, getContext, select,
} from 'redux-saga/effects';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { getUserConfigFromReducer } from '@airtel-tv/redux/StoreListing';
import ChannelScheduleAction, { fetchChannelScheduleSuccess, fetchFullChannelScheduleSuccess } from '../actions/ChannelScheduleAction';
import { getStartTime, getEndTime } from '../../../utilities/CommonUtil';
import { CHANNEL_SCHEDULE_TIME_GAP } from '../../../constants/AppConst';
import { MS_IN_A_DAY } from '@airtel-tv/constants/DateTimeConst';
import { DEFAULT_CHANNEL_PRODUCT_ID } from '../../../constants/ContentProviderConst';
import { getChannelScheduleApi } from '../../../service/end-points/EpgApiService';

function* fetchChannelSchedule({ startTimeStamp, channelId }) {
    const currentTimeStamp = startTimeStamp || new Date().getTime();
    const startTime = getStartTime(new Date(currentTimeStamp), CHANNEL_SCHEDULE_TIME_GAP.DAILY);
    const endTime = getEndTime(new Date(currentTimeStamp), CHANNEL_SCHEDULE_TIME_GAP.DAILY);
    const userConfig = yield select(getUserConfigFromReducer);
    const {
        ln,
        mwTvPack = DEFAULT_CHANNEL_PRODUCT_ID,
    } = userConfig.userContentProperties;

    const { lang } = userConfig.userInfo;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const channelSchedule = yield call(getChannelScheduleApi, {
            userContentProperties: userConfig.userContentProperties,
            mwTvPack,
            ln: lang && lang.length > 0 ? lang[0] : ln,
            channelId,
            startTime,
            endTime,
            deviceUtil,
        });
        const payload = {
            ...channelSchedule.programGuide,
        };
        yield put(fetchChannelScheduleSuccess({
            payload,
        }));
    }
    catch (e) {
        console.error(e);
    }
}

function* fetchFullChannelSchedule({ timeStamp, channelId }) {
    const endTime = timeStamp + MS_IN_A_DAY - 1;
    const startTime = timeStamp + 1;
    const userConfig = yield select(getUserConfigFromReducer);
    const {
        ln,
        mwTvPack = DEFAULT_CHANNEL_PRODUCT_ID,
    } = userConfig.userContentProperties;

    const { lang } = userConfig.userInfo;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const channelSchedule = yield call(getChannelScheduleApi, {
            userContentProperties: userConfig.userContentProperties,
            mwTvPack,
            ln: lang && lang.length > 0 ? lang[0] : ln,
            channelId,
            startTime,
            endTime,
            deviceUtil,
        });
        const payload = {
            channel: channelSchedule.programGuide,
            timeStamp,
        };

        yield put(fetchFullChannelScheduleSuccess({
            payload,
        }));
    }
    catch (e) {
        console.error(e);
    }
}

export default [
    takeFirst(ChannelScheduleAction.FETCH_CHANNEL_SCHEDULE, fetchChannelSchedule),
    takeFirst(ChannelScheduleAction.FETCH_FULL_CHANNEL_SCHEDULE, fetchFullChannelSchedule),
];
