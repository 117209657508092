import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRelatedContent, peopleRelatedContentAction } from '../actions/RelatedContentActions';
import { getRail } from '../factories/RailFactory';
import { RELATION_TYPES } from '@airtel-tv/constants/LayoutConstants';
import LanguageProvider from '../../../providers/LanguageProvider';
import { buildRail } from '@airtel-feature/layout/builders/RailBuilder';

import {
    isItemObject, getTileFromProgramType,
} from '../../../utilities/CommonUtil';
import { PROGRAM_TYPES } from '../../../constants/AppConst';
import { fetchAllChannels } from '../actions/ChannelAction';
import { CONTENT_IMAGE_TYPES } from '@airtel-tv/constants/ImagesConst';

class RelatedContentContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            targetId: '',
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let changes = {};

        const {
            targetId,
            items,
            channels,
        } = nextProps;

        if (
            targetId !== prevState.targetId
            || items !== prevState.items
            || channels !== prevState.channels
        ) {
            changes = {
                targetId,
                items,
                channels,
            };
        }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    componentDidMount() {
        const { items } = this.props;

        if (items.length > 0) {
            return;
        }
        this.getRelatedContent();
        this.fetchChannels();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState;
    }

    componentDidUpdate(prevProps) {
        const { items } = this.props;
        if (items.length > 0) {
            return;
        }

        this.getRelatedContent(prevProps);
    }

    getRelatedContent = (prevProps = {}) => {
        //  dispatch fetch rail content
        const {
            getRelatedContentDispatch,
            peopleRelatedContentActionDispatch,
            targetId,
            relationType,
            programTypes,
        } = this.props;
        if (prevProps.targetId !== targetId) {
            // TODO: convert below swtich case to factory
            switch (relationType) {
                case RELATION_TYPES.TVSHOW:
                case RELATION_TYPES.VIDEO:
                    getRelatedContentDispatch({
                        contentId: targetId,
                        programTypes,
                    });
                    break;

                case RELATION_TYPES.PEOPLE:
                    peopleRelatedContentActionDispatch({
                        peopleId: targetId,
                        programTypes,
                    });
                    break;

                default:
                    break;
            }
        }
    }

    getFilteredItems = (items, programType) => {
        switch (programType) {
            case PROGRAM_TYPES.TVSHOW:
                return items.filter(item => item.programType === PROGRAM_TYPES.TVSHOW);
            case PROGRAM_TYPES.VIDEO:
                return items.filter(item => item.programType === PROGRAM_TYPES.VIDEO);
            default:
                return items.filter(item => item.programType === PROGRAM_TYPES.MOVIE);
        }
    }

    getRailTitle = (relationType, programTypes) => {
        const LANGUAGES = LanguageProvider();
        switch (relationType) {
            case RELATION_TYPES.PEOPLE:
                switch (programTypes) {
                    case PROGRAM_TYPES.TVSHOW:
                        return LANGUAGES.LABEL_TV_SHOWS;
                    default:
                        return LANGUAGES.LABEL_MOVIES;
                }
            case RELATION_TYPES.VIDEO:
            case RELATION_TYPES.TVSHOW:
                return LANGUAGES.YOU_MAY_ALSO_LIKE;
            default:
                return null;
        }
    }

    fetchChannels() {
        const { channels, fetchAllChannelsDispatch } = this.props;

        if (channels.length <= 1) {
            fetchAllChannelsDispatch();
        }
    }


    // TODO: Remove hardcoding
    render() {
        const {
            items,
            relationType,
            programTypes,
            channels,
            cpDetailsById,
            subscriptionDataById,
            pageId,
            webPageId
        } = this.props;

        const filteredItems = items && items.length > 0 ? this.getFilteredItems(items, programTypes) : items;

        const railTitle = this.getRailTitle(relationType, programTypes);
        const tileType = getTileFromProgramType(programTypes, relationType, pageId, webPageId);

        const railProps = buildRail({
            items: filteredItems,
            explicitTileType: tileType,
            explicitRailTitle: railTitle,
            channels,
            cpDetailsById,
            subscriptionDataById,
            pageId,
            webPageId,
            preferredArtwork : CONTENT_IMAGE_TYPES.PORTRAIT
        });

        const railElement = getRail({
            railType: railProps.railType,
            railProps,
        });

        return railElement;
    }
}

RelatedContentContainer.defaultProps = {
    items: [],
    channels: [],
};

RelatedContentContainer.propTypes = {
    targetId: PropTypes.string.isRequired,
    relationType: PropTypes.string.isRequired,
    getRelatedContentDispatch: PropTypes.func.isRequired,
    fetchAllChannelsDispatch: PropTypes.func.isRequired,
    items: PropTypes.oneOfType([
        PropTypes.array, // eslint-disable-line react/forbid-prop-types
        PropTypes.object, // eslint-disable-line react/forbid-prop-types
    ]),

    programTypes: PropTypes.string.isRequired,
    peopleRelatedContentActionDispatch: PropTypes.func.isRequired,
    channels: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    cpDetailsById: PropTypes.objectOf(PropTypes.object).isRequired,
    subscriptionDataById: PropTypes.objectOf(PropTypes.object).isRequired,
};


const mapStateToProps = (state, props) => {
    const { relatedContents, appConfig, userConfig } = state;
    const { targetId, programTypes, relationType } = props;

    let key = targetId;
    if (relationType === RELATION_TYPES.PEOPLE) {
        key = targetId + programTypes;
    }

    const prop = {
        items: isItemObject(relatedContents[key]) ? relatedContents[key] : [],
        cpDetailsById: appConfig.cpDetailsById,
        subscriptionDataById: userConfig.subscriptionDataById,
    };

    return prop;
};


export default connect(mapStateToProps, {
    getRelatedContentDispatch: getRelatedContent,
    peopleRelatedContentActionDispatch: peopleRelatedContentAction,
    fetchAllChannelsDispatch: fetchAllChannels,
})(RelatedContentContainer);
