import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getTile } from '../factories/TileFactory';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { getCurrentWindowSourceName } from '../../../utilities/WindowUtil';
import { MAX_TILE_LIMIT_MWEB, MAX_TILE_LIMIT_WEB } from '@airtel-tv/constants/LayoutConstants';
// import { railCtaClickGaEvent } from '../../../utilities/GaEvents';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import HorizontalLazyLoaderHOC from '../../shared/hoc/HorizontalLazyLoaderHOC';
import { findTileLimit } from '../../../utilities/CommonUtil';
import RailEventHOC from '../../shared/hoc/RailEventHOC';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { IMAGE_PATHS } from '../../../constants/AppConst';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import styles from './../../../modules/navigation/components/top-navigation/DesktopNavigationComponent.scss'
import withStyles from 'isomorphic-style-loader/withStyles';

const TrendingRailComponent = React.forwardRef((props, ref) => {
    const [
        hoveredTile,
        setHoveredTile,
    ] = useState('');
    const {
        deviceUtil, tiles, railTitle, railCtaText, railCtaActionUrl, railIndex, railId, imageSettings, showMore, tileType, onRailScroll,
        visibleWithClickEvent, packageId, isSearchEvent = false,
    } = props;
    const isBot = deviceUtil.isBot();
    const isMobile = deviceUtil.isMobile();
    const windowWidth = deviceUtil.getWindowWidth();
    const tileLimit = findTileLimit({
        windowWidth,
        tileType,
    });

    const carouselElementRef = React.createRef();
    const [
        indexOfRail,
        setIndexOfRail,
    ] = useState(-1);

    useEffect(() => {
        let indRail;
        if (carouselElementRef && carouselElementRef.current) {
            carouselElementRef.current.addEventListener('scroll', () => {
                if (carouselElementRef && carouselElementRef.current) {
                    indRail = Math.ceil(carouselElementRef.current.scrollLeft / 160);
                    setIndexOfRail(indRail);
                }
            });
        }
    });

    function next() {
        if (carouselElementRef.current) {
            const { clientWidth } = carouselElementRef.current;
            carouselElementRef.current.scrollLeft += clientWidth;
        }
        visibleWithClickEvent();
    }

    function previous() {
        if (carouselElementRef.current) {
            const { clientWidth } = carouselElementRef.current;
            carouselElementRef.current.scrollLeft -= clientWidth + (indexOfRail === 3 ? 20 : 0);
        }
        visibleWithClickEvent();
    }

    let tileElements = [];
    let lazyTiles = [];

    if (tiles && Array.isArray(tiles) && tiles.length < 1) {
        return null;
    }

    const tilesToShow = (isMobile && !isBot) ? tiles.slice(0, MAX_TILE_LIMIT_MWEB.LANDSCAPE) : tiles.slice(0, MAX_TILE_LIMIT_WEB.LANDSCAPE);
    if (tilesToShow && Array.isArray(tilesToShow)) {
        tileElements = tilesToShow.map((tile, i) => {
            const tileProps = {
                key: `${tile.id}-${i}`,
                ...tile,
                railCtaActionUrl,
                railPosition: railIndex,
                tilePosition: i,
                railId,
                imageSettings,
                hoveredTile,
                setHoveredTile,
                packageId,
                isSearchEvent,
            };

            const tileElement = getTile({
                tileProps,
            });

            return tileElement;
        });
        const key = `${railId}-${railIndex}`;
        lazyTiles = (
            <HorizontalLazyLoaderHOC
                tileElements={tileElements}
                tileLimit={tileLimit}
                railKey={key}
            />
        );
    }
    // check if there is an error
    else if (typeof tilesToShow === 'object') {
        // TODO: Handle error
    }

    const showArrows = (tileElements && tileElements.length > tileLimit - 1);
    const moreAnalyticsMeta = {
        rail_id: railId,
        //   action: "more_click",
        // source_name: getCurrentWindowSourceName(),
        action: ANALYTICS_ACTIONS.RAIL_CTA_CLICK,
        asset_name: ANALYTICS_ASSETS.SEE_ALL,
        package_id: packageId,
    };

    const { scrollLeft = 0, clientWidth = 0, scrollWidth = 0 } = carouselElementRef && carouselElementRef.current ? carouselElementRef.current : {};
    const showNext = scrollLeft < (scrollWidth - clientWidth) || (indexOfRail < tileElements.length - Math.floor(clientWidth / 160));

    return (
        <div
            className="desktop-trending-search-container"
            ref={ref}
            id="atm_trending-content-rail"
        >
            <div className="desktop-trending-search-wrapper">
                <div className="desktop-trending-search">
                    <ThumborImage
                        src={IMAGE_PATHS.TRENDING_SEARCH_ICON}
                    />
                    <AnalyticsLinkComponent
                        to={railCtaActionUrl}
                        className="home-page-font text-bold-20 mobile-text-bold-16  d-inline-block"
                        meta={moreAnalyticsMeta}
                        onClick={visibleWithClickEvent}
                        title={railTitle}
                    >
                        {`${railTitle}`}
                    </AnalyticsLinkComponent>
                </div>
                <div className={(showArrows || showMore) ? 'd-flex justify-content-between align-items-center' : 'd-none'}>
                    <div className={!showArrows ? 'd-none' : 'carousel-arrows'}>
                        <span className="button-ripple">
                            <AnalyticsButtonComponent
                                type="button"
                                className="button button-link"
                                onClick={previous}
                                aria-label="Scroll Rail left"
                            >
                                { /* eslint-enable */}
                                <i className="icon20 icon-carousel-prev-arrow" />
                            </AnalyticsButtonComponent>
                        </span>

                        <span className="button-ripple">
                            <AnalyticsButtonComponent
                                type="button"
                                className="button button-link"
                                onClick={next}
                                aria-label="Scroll Rail right"
                            >
                                { /* eslint-enable */}
                                <i className="icon20 icon-carousel-arrow icon-carousel-next-arrow" />
                            </AnalyticsButtonComponent>
                        </span>
                    </div>

                    {railCtaActionUrl
                        ? (
                            <AnalyticsLinkComponent
                                to={railCtaActionUrl}
                                className="cta cta--link cta--link--samll cta--link--theme cta-link--vdo-hasMoreButton d-inline-block"
                                meta={moreAnalyticsMeta}
                                onClick={visibleWithClickEvent}
                                title={railCtaText}
                            >
                                {railCtaText}
                            </AnalyticsLinkComponent>
                        ) : null}
                </div>

            </div>
            <div
                className="desktop-trending-search-crousel"
                draggable="false"
                onScroll={onRailScroll}
                // eslint-disable-next-line jsx-a11y/aria-role
                role="carousel"
            >
                { indexOfRail > 0 && (
                    <AnalyticsButtonComponent
                        className="left-chevron"
                        onClick={previous}
                        type="button"
                        aria-label="Scroll Rail left"
                    >
                        <ThumborImage
                            src="/static/search/trending-left-chevron.svg"
                        />
                    </AnalyticsButtonComponent>
                ) }
                <div
                    className="card-row"
                    ref={carouselElementRef}
                >
                    <div className="card-row_inner">
                        {lazyTiles}
                    </div>

                </div>
                { showNext && (
                    <AnalyticsButtonComponent
                        className="right-chevron"
                        type="button"
                        onClick={next}
                        aria-label="Scroll Rail right"
                    >
                        <ThumborImage
                            src="/static/search/trending-right-chevron.svg"
                        />
                    </AnalyticsButtonComponent>
                ) }
            </div>

        </div>
    );
});

TrendingRailComponent.defaultProps = {
    railIndex: 0,
    railId: '',
    railCtaActionUrl: '',
    packageId: '',
};

TrendingRailComponent.propTypes = {
    tiles: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    railTitle: PropTypes.string.isRequired,
    railCtaText: PropTypes.string.isRequired,
    railCtaActionUrl: PropTypes.string,
    railIndex: PropTypes.number,
    railId: PropTypes.string,
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showMore: PropTypes.bool.isRequired,
    tileType: PropTypes.string.isRequired,
    onRailScroll: PropTypes.func.isRequired,
    visibleWithClickEvent: PropTypes.func.isRequired,
    packageId: PropTypes.string,
};

export default withDeviceUtil(React.memo(RailEventHOC(withStyles(styles)(TrendingRailComponent))));
