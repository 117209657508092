import {
    select,
    call,
    put,
    getContext,
    delay,
    takeEvery,
    fork,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import {
    ALL_PAGE_DATA_FETCH_DELAY, RAIL_LIMIT, RAIL_TYPES, TILE_TYPES,
} from '@airtel-tv/constants/LayoutConstants';
import {
    getAppConfigFromReducer, getAuthConfigFromReducer, getLayoutDetailsFromReducer, getPackageContentsFromReducer, getUserConfigFromReducer,
} from '@airtel-tv/redux/StoreListing';
import LocalRailUtil from '@airtel-feature/layout/utils/LocalRailUtil';
import LayoutActions, {
    layoutFetchActionSuccess, allLayoutFetchAction, purgeLayoutData, layoutFetchActionError,
    isfetchingLayout,
    layoutReset,
} from '@airtel-feature/layout/actions/LayoutActions';
import { apiLogEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { LAYOUT_MOCK_PAGES } from '@airtel-feature/layout/sagas/LayoutMock';
import { mockRailsIfNeeded } from '@airtel-tv/utils/GlobalUtil';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { purgePackageData, packageFetchActionSuccess } from '../actions/PackageActions';
import { getAdConfigContentFromRail, getPackageContentFromRailList } from '../helpers/LayoutHelper';
import { filterStringWithPrefix } from '../../../utilities/StringUtil';
import { getPaginatedPageLayout } from '../../../service/end-points/LayoutApiService';
import { updateUserConfig } from '../../user/UserAction';
// import SubscriptionMock from './SubscriptionMock';
// import SubscriptionMock from './SubscribedMock';

function* getPaginatedLayoutData({
    pageId, count,
}) {
    const deviceUtil = yield getContext('deviceUtil');
    const { userContentProperties } = yield select(getUserConfigFromReducer);
    const authConfig = yield select(getAuthConfigFromReducer);

    // get layout details
    const layoutPackages = yield call(getPaginatedPageLayout, {
        userContentProperties,
        pageId,
        deviceUtil,
        offset: 0,
        count,
        token: authConfig.token,
        uid: authConfig.uid,
    });


    const railList = LocalRailUtil.mapLocalPackageToRail(layoutPackages.railList);
    const totalFetched = lodashGet(layoutPackages, 'total', 0);
    const fetchMore = lodashGet(layoutPackages, 'nextPage', false);
    const packageData = getPackageContentFromRailList(railList);
    const layoutPayload = {
        [pageId]: {
            layoutPackages: [
                ...railList,
            ],
            error: null,
            isSSRData: false,
            totalFetched,
            fetchMore,
        },
        allLayoutFetch: true,
    };
    // update layout
    yield put(packageFetchActionSuccess({ payload: packageData }));
    yield put(layoutFetchActionSuccess({ payload: layoutPayload }));
}

function* fetchPageData({ pageId }) {
    try {
        yield call(getPaginatedLayoutData, {
            pageId,
            count: RAIL_LIMIT.USER,
        });
    }
    catch (error) {
        console.error(`error fetching${pageId}`, error);
    }
}

// END LOCAL FUNCTIONS

function* fetchAllPageLayoutSaga({
    delaySeconds = 0,
}) {
    yield delay(delaySeconds);
    const layoutDataCollection = yield select(getLayoutDetailsFromReducer);

    const { pages } = yield select(getAppConfigFromReducer);
    if (pages) {
        const pageIds = [];
        for (let index = 0; index < pages.length; index++) {
            const page = pages[index];
            // if layout data does not exists
            if (!layoutDataCollection[page.id]) {
                pageIds.push(page.id);
                yield call(fetchPageData, { pageId: page.id });
            }
        }
    }
}


function* resetLayoutSaga() {
    const allPackagesContent = yield select(getPackageContentsFromReducer);
    const { staticPackagePrefix } = yield select(getAppConfigFromReducer);
    let staticPackages = {};
    filterStringWithPrefix(Object.keys(allPackagesContent), staticPackagePrefix)
        .forEach((packageID) => {
            staticPackages = {
                ...staticPackages,
                [packageID]: allPackagesContent[packageID],
            };
        });
    const payload = {
        staticPackages,
    };
    yield put(purgeLayoutData());
    yield put(purgePackageData(payload));
}

function* callLayoutLatency(meta) {
    yield call(apiLogEvent, meta);
}

function* fetchPaginatedLayoutSaga(action) {
    yield put(isfetchingLayout({ isFetching: true }));
    const deviceUtil = yield getContext('deviceUtil');
    const { userContentProperties } = yield select(getUserConfigFromReducer);
    const layoutDetails = yield select(getLayoutDetailsFromReducer);
    const { web_pageId: webPageId } = yield select(getAppConfigFromReducer);
    const authConfig = yield select(getAuthConfigFromReducer);
    const {
        pageId, count, contentId, paramsFromUrl, isOnehubRoute, query, msisdnNo,
        useFallBackLayout,
    } = action;
    const { cpDetailsById } = yield select(getAppConfigFromReducer);
    const cdpTrailers = layoutDetails?.contentDetail?.layoutPackages[0].contents[0].details?.content[0].trailers || [];

    const {
        MORE_LIKE_THIS,
        CAST_N_MORE,
        CONTENT_DETAIL,
        SDK_AD_CONFIG = 'adsConfig',
    } = webPageId;
    const isCdp = pageId === CONTENT_DETAIL;
    const isMultiListRail = [
        MORE_LIKE_THIS,
        CAST_N_MORE,
    ].includes(pageId);
    const layoutPageId = `${pageId}${isMultiListRail && contentId ? `_${contentId}` : ''}${isOnehubRoute && query ? `_${query}` : ''}`;
    const currentLayoutData = lodashGet(layoutDetails, `[${layoutPageId || pageId}]`, {});
    const currentRailData = lodashGet(currentLayoutData, 'layoutPackages', []);
    const defaultFocusedTileId = '';
    const {
        isSSRData,
        focusedTileId = defaultFocusedTileId,
        focusSelectorDimensions = {},
        railTileDimensionsMap: currentRailTileDimensionsMap = [],
        // layoutRailScrollTopUpdate = {},
        lastFocusDetails: currentFocusDetails,
    } = currentLayoutData;
    const offset = !isCdp && isSSRData && authConfig?.isAuthenticated ? 0 : lodashGet(currentLayoutData, 'totalFetched', 0);
    const locallayoutPackages = null;

    try {
        const locallayoutPackage = lodashGet(locallayoutPackages, `[${pageId}].layoutPackages`, null);
        if (!locallayoutPackage || Object.keys(locallayoutPackage).length < 1) {
            const layoutPackages = yield call(getPaginatedPageLayout, {
                userContentProperties,
                pageId,
                offset,
                deviceUtil,
                count,
                token: authConfig.token,
                uid: authConfig.uid,
                contentId,
                paramsFromUrl,
            });

            yield fork(callLayoutLatency, {
                page_id: pageId,
                offset,
                count,
                request_response_time: layoutPackages?.api_response_time,
            });

            let railList = LocalRailUtil.mapLocalPackageToRail(layoutPackages.railList, [
                TILE_TYPES.PLAYER_WIDGET,
            ]);
            let adConfigCpMap = {};
            try {
                if (pageId === SDK_AD_CONFIG) {
                    adConfigCpMap = getAdConfigContentFromRail(railList);
                }
            } catch (err) {
                console.error('Ad config parsing error', err);
            }
            railList = LocalRailUtil.filterAndBuildRail({
                railList,
                cpDetailsById,
                tilesToFilter: [
                    TILE_TYPES.PLAYER_WIDGET,
                ],
                cdpTrailers,
                msisdnNo,
            });

            railList = mockRailsIfNeeded(railList);

            const totalFetched = lodashGet(layoutPackages, 'total', 0);
            const fetchMore = lodashGet(layoutPackages, 'nextPage', false);
            const packageData = getPackageContentFromRailList(railList);
            const layoutPayload = {
                [layoutPageId]: {
                    layoutPackages: [
                        ...currentRailData,
                        ...railList,
                    ],
                    ...(Object.keys(adConfigCpMap).length ? { adConfigCpMap } : {}),
                    error: null,
                    isSSRData: false,
                    totalFetched: offset + totalFetched,
                    fetchMore,
                    focusedTileId,
                    focusSelectorDimensions,
                    railTileDimensionsMap: currentRailTileDimensionsMap,
                    lastFocusDetails: currentFocusDetails,
                    // layoutRailScrollTopUpdate,
                },
            };
            // update layout
            yield put(packageFetchActionSuccess({ payload: packageData }));
            yield put(layoutFetchActionSuccess({ payload: layoutPayload }));
        }
        else {
            yield put(layoutFetchActionSuccess({ payload: locallayoutPackages }));
        }
        yield put(isfetchingLayout({ isFetching: false }));
    }
    catch (e) {
        if (useFallBackLayout && LAYOUT_MOCK_PAGES[pageId]) {
            const layoutPackages = LAYOUT_MOCK_PAGES[pageId];
            yield fork(callLayoutLatency, {
                page_id: pageId,
                offset,
                count,
                request_response_time: layoutPackages?.api_response_time,
            });
            let railList = LocalRailUtil.mapLocalPackageToRail(layoutPackages.railList, [
                TILE_TYPES.PLAYER_WIDGET,
            ]);
            railList = LocalRailUtil.filterAndBuildRail({
                railList,
                cpDetailsById,
                tilesToFilter: [
                    TILE_TYPES.PLAYER_WIDGET,
                ],
                cdpTrailers,
                msisdnNo,
            });
            const totalFetched = lodashGet(layoutPackages, 'total', 0);
            const fetchMore = lodashGet(layoutPackages, 'nextPage', false);
            const packageData = getPackageContentFromRailList(railList);
            const layoutPayload = {
                [layoutPageId]: {
                    layoutPackages: [
                        ...currentRailData,
                        ...railList,
                    ],
                    error: null,
                    isSSRData: false,
                    // totalFetched: offset + totalFetched,
                    totalFetched: totalFetched,
                    fetchMore,
                    focusedTileId,
                    focusSelectorDimensions,
                    railTileDimensionsMap: currentRailTileDimensionsMap,
                    lastFocusDetails: currentFocusDetails,
                    // layoutRailScrollTopUpdate,
                },
            };
            // update layout
            yield put(packageFetchActionSuccess({ payload: packageData }));
            yield put(layoutFetchActionSuccess({ payload: layoutPayload }));
            yield put(isfetchingLayout({ isFetching: false }));
            return;
        }
        const error = e.data ? e.data : e;
        yield put(layoutFetchActionError({ payload: { error } }));
    }
}

function* updateUserConfigPurgeLayout() {
    yield put(updateUserConfig({ stopLayoutReset: false }));
    // yield put(layoutReset());
}

export default [
    // takeFirst(LayoutActions.LAYOUT_FETCH, fetchPageLayoutSaga),
    takeFirst(LayoutActions.ALL_LAYOUT_FETCH, fetchAllPageLayoutSaga),
    takeFirst(LayoutActions.LAYOUT_RESET, resetLayoutSaga),
    takeEvery(LayoutActions.LAYOUT_PAGINATED_FETCH, fetchPaginatedLayoutSaga),
    takeFirst(LayoutActions.UPDATE_USER_CONFIG_PURGE_LAYOUT, updateUserConfigPurgeLayout),
];
