import {
    call, put, select, getContext,
} from 'redux-saga/effects';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { ChannelAction, fetchAllChannelsSuccess } from '../actions/ChannelAction';
import { getChannelsApi } from '../../../service/end-points/EpgApiService';
import { DEFAULT_CHANNEL_PRODUCT_ID } from '../../../constants/ContentProviderConst';
import { fetchEpgChannelDetails } from '../actions/EpgAction';
import { getUserConfigFromReducer, getEpgFromReducer, getDistroDataConfigFromReducer } from '@airtel-tv/redux/StoreListing';
import { getDistroEpgDetails } from '@airtel-tv/services/DistroApiService';
import { fetchDistroDetails } from '@airtel-feature/layout/actions/DistroAction';


function* fetchChannelContent() {
    try {
        const userConfig = yield select(getUserConfigFromReducer);
        let distro = yield select(getDistroDataConfigFromReducer) || {};
        let { distroData = [] } = distro;

        const {
            ln,
            mwTvPack = DEFAULT_CHANNEL_PRODUCT_ID,
        } = userConfig.userContentProperties;

        const { lang } = userConfig.userInfo;
        const deviceUtil = yield getContext('deviceUtil');
        const response = yield call(getDistroEpgDetails , {
            deviceUtil,
            cpId: 'DISTROTV',
            includePlayableOnly: true,
            start: 'now',
            end: 'nowPLUS1d',
        });

        // const channelResponse = yield call(getChannelsApi, {
        //     userContentProperties: userConfig.userContentProperties,
        //     mwTvPack,
        //     ln: lang && lang.length > 0 ? lang[0] : ln,
        //     deviceUtil,
        // });
        if (distroData.length === 0) {
            yield put(fetchDistroDetails());
            distro = yield select(getDistroDataConfigFromReducer) || {};
            distroData = distro.distroData || [];
        }
        const channelsId = response?.data?.map(ele=>ele.channelId);
        const distroChannels = distroData.filter(item => !channelsId?.includes(item?.id))?.map(ele => {
            return {
                ...ele,
                channelId: ele?.id,
                channelImages: ele?.images,
                channelName: ele?.title,
                epgList: [],
            }
        })
        yield put(fetchAllChannelsSuccess({
            channelResponse: { channels: [...response.data, ...distroChannels] },
        }));
    }
    catch (e) {
        console.error(e);
    }
    finally {
        const epgDetails = yield select(getEpgFromReducer);
        if (!epgDetails.fetching && Object.keys(epgDetails.programGuide).length < 1) {
            yield put(fetchEpgChannelDetails());
        }
    }
}

export default [
    takeFirst(ChannelAction.FETCH_CHANNELS, fetchChannelContent),
];
