import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getTile } from '../factories/TileFactory';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from '../../dimension-pages/DimensionPage.scss';
import { ParagraphElement } from '@airtel-tv/ui-lib/atoms';

// javascript for filter movies


const DimensionRailComponent = (props) => {
    const {
        tiles,
        propertyClassName,
        railCtaActionUrl,
        railIndex,
        railId,
        imageSettings,
        onRemoveIconClick,
        packageId,
    } = props;

    const items = tiles;
    const [
        hoveredTile,
        setHoveredTile,
    ] = useState('');

    let tileElements = [];
    if (items && Array.isArray(items)) {
        tileElements = items.map((tile, i) => {
            const tileProps = {
                key: `${tile.id}-${railId}`,
                propertyClassName,
                ...tile,
                railCtaActionUrl,
                railPosition: railIndex,
                tilePosition: i,
                railId,
                imageSettings,
                hoveredTile,
                setHoveredTile,
                packageId,
            };

            const tileElement = getTile({
                tileProps,
                onRemoveIconClick,
            });

            return tileElement;
        });
    }
    // check if there is an error
    else if (typeof tiles === 'object') {
    // TODO: Handle error
    }

    return (

        <section
            id="filter_cards"
            className="end-to-end-rail-wrapper cards-portrait-wrapper cards-portrait-wrapper--mobile d-flex flex-wrap justify-content-start"
        >
            <ParagraphElement />
            <section className="wrapper-inner">
                {tileElements}
            </section>

        </section>

    );
};

DimensionRailComponent.defaultProps = {
    propertyClassName: '',
    railCtaActionUrl: '',
    railIndex: 0,
    railId: '',
    packageId: '',
};

DimensionRailComponent.propTypes = {
    tiles: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    propertyClassName: PropTypes.string,
    railCtaActionUrl: PropTypes.string,
    railIndex: PropTypes.number,
    railId: PropTypes.string,
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onRemoveIconClick: PropTypes.func, // eslint-disable-line react/require-default-props
    packageId: PropTypes.string,
};

export default React.memo(withStyles(styles)(DimensionRailComponent));
