import {
    call, put, select, takeEvery, getContext,
} from 'redux-saga/effects';
import { RelatedContentActions, getRelatedContentSuccess, getRelatedContentError } from '../actions/RelatedContentActions';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { searchVideoRelatedContent, searchPeopleRelatedContent } from '../../../service/end-points/SearchApiService';
import { getAppId } from '../../../utilities/CommonUtil';
import { getUserConfigFromReducer } from '@airtel-tv/redux/StoreListing';


function* fetchRelatedContentItems(action) {
    const { contentId, programTypes } = action;
    try {
        const userConfig = yield select(getUserConfigFromReducer);

        const deviceUtil = yield getContext('deviceUtil');
        const xAtvDid = deviceUtil.getXAtvDid();

        const relatedContentItems = yield call(searchVideoRelatedContent, {
            userContentProperties: userConfig.userContentProperties,
            contentId,
            appId: getAppId(),
            type: programTypes,
            xAtvDid,
        });

        const payload = {
            [contentId]: relatedContentItems.PLAYABLE_CONTENT.results,
        };

        yield put(getRelatedContentSuccess({
            payload,
        }));
    }
    catch (e) {
        console.error(e);
        const errorData = e.data ? e.data : e;

        const payload = {
            [contentId]: errorData,
        };

        yield put(getRelatedContentError({ payload }));
    }
}

function* fetchPeopleRelatedContent(action) {
    const { peopleId, programTypes } = action;
    try {
        const userConfig = yield select(getUserConfigFromReducer);

        const deviceUtil = yield getContext('deviceUtil');

        const artistRelatedContent = yield call(searchPeopleRelatedContent, {
            userContentProperties: userConfig.userContentProperties,
            creditRef: peopleId,
            appId: getAppId(),
            type: programTypes,
            xAtvDid: deviceUtil.getXAtvDid(),
        });

        const key = peopleId + programTypes;

        const payload = {
            [key]: artistRelatedContent.PLAYABLE_CONTENT.results,
        };


        yield put(getRelatedContentSuccess({
            payload,
        }));
    }
    catch (e) {
        console.error(e);
        const errorData = e.data ? e.data : e;

        const payload = {
            [peopleId]: errorData,
        };

        yield put(getRelatedContentError({ payload }));
    }
}

export default [
    takeFirst(RelatedContentActions.FETCH_RELATED_CONTENT, fetchRelatedContentItems),
    takeEvery(RelatedContentActions.PEOPLE_RELATED_CONTENT_FETCH, fetchPeopleRelatedContent),
];
