import { RelatedContentActions } from '../actions/RelatedContentActions';

const initialState = JSON.parse('{}');

const RelatedContentRecuder = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case RelatedContentActions.FETCH_RELATED_CONTENT_ERROR:
            changes = {
                ...action.payload,
            };
            break;

        case RelatedContentActions.FETCH_RELATED_CONTENT_SUCCESS:
            changes = {
                ...action.payload,
            };
            break;
        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default RelatedContentRecuder;
