import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTile } from '../factories/TileFactory';
// import AnalyticsLinkComponent from '@airtel-tv/analytics/AnalyticsLinkComponent';
// import { getCurrentWindowSourceName } from '../../../utilities/WindowUtil';
import {
    LOCAL_PACKAGE_NAME, RAIL_TYPES, RAIL_CTA_LIST, LOCAL_TILE_TYPES,

        TILE_TYPES,
} from '@airtel-tv/constants/LayoutConstants';
// import { railCtaClickGaEvent } from '../../../utilities/GaEvents';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { watchListContentRemove } from '@airtel-tv/sync/SyncActions';
import { buildTile } from '@airtel-feature/layout/builders/TileBuilder';
import HorizontalLazyLoaderHOC from '../../shared/hoc/HorizontalLazyLoaderHOC';
import { findTileLimit } from '../../../utilities/CommonUtil';
import LinkComponent from '../../shared/components/link/LinkComponent';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { ARIA_LABEL, ELEMENT_ID } from '@airtel-tv/constants/GlobalConst';
import { getPywWatchListComputedTiles } from '@airtel-feature/layout/utils/LayoutUtil';

class WatchlistRailContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;

        this.isMobile = this.deviceUtil.isMobile();
        this.watchListCarouselElementRef = React.createRef();
        this.state = {
            tiles: [],
            indexOfRail: -1,
            scrollLeft: 0, 
            clientWidth: 0, 
            scrollWidth: 0,
            hoveredTile: ''
        };
        const windowWidth = this.deviceUtil.getWindowWidth();
        const { tileType } = props;
        this.tileLimit = findTileLimit({
            windowWidth,
            tileType,
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            watchList,
            cpDetailsById,
            subscriptionDataById,
            preferredArtwork,
            isPywWatchListRail,
        } = nextProps;

        if (!prevState || watchList !== prevState.watchList) {
            if (isPywWatchListRail) {
                return {
                    watchList,
                    tiles: getPywWatchListComputedTiles({
                        watchList,
                        cpDetailsById,
                        subscriptionDataById,
                        buildTile,
                        RAIL_TYPES,
                        RAIL_CTA_LIST,
                        LOCAL_TILE_TYPES,
                        preferredArtwork,
                        railElementId: ELEMENT_ID.PYW_WATCHLIST_RAIL,
                        isWatchListTile: true,
                    }),
                };
            }
            return {
                watchList,
                tiles: WatchlistRailContainer.getComputedTiles({
                    watchList,
                    cpDetailsById,
                    subscriptionDataById,
                    railElementId: ELEMENT_ID.WATCHLIST_RAIL,
                    isWatchListTile: true,
                }),
            };
        }

        return null;
    }

    static getComputedTiles({
        watchList,
        cpDetailsById,
        subscriptionDataById,
        railElementId,
        isWatchListTile,
    }) {
        const railType = RAIL_TYPES.WATCHLIST_RAIL_V2;
        const tileCtaType = RAIL_CTA_LIST.LISTING;
        let tiles = null;
        let content = [];
        const { length } = Object.keys(watchList);
        content = Object.keys(watchList).map((watchListContentId) => {
            const { contentResponse, lastUpdatedTimeStamp } = watchList[watchListContentId];
            return {
                ...contentResponse,
                lastUpdatedTimeStamp,
            };
        });
        content.sort((a, b) => (b.lastUpdatedTimeStamp - a.lastUpdatedTimeStamp));
        tiles = content.map(item => buildTile({
            item,
            tileCtaType,
            railType,
            tileType: LOCAL_TILE_TYPES.WATCHLIST,
            length,
            cpDetailsById,
            subscriptionDataById,
            railElementId,
            isWatchListTile,
            showCatalogTag: true,
        }));
        return tiles;
    }

    componentDidMount() {
        let indRail;
        if (this.watchListCarouselElementRef && this.watchListCarouselElementRef.current) {
            const { scrollLeft = 0, clientWidth = 0, scrollWidth = 0 } = this.watchListCarouselElementRef && this.watchListCarouselElementRef.current ? this.watchListCarouselElementRef.current : {};
            this.setState({ scrollLeft, clientWidth, scrollWidth })
            this.watchListCarouselElementRef.current.addEventListener('scroll', (e) => {
                if (this.watchListCarouselElementRef && this.watchListCarouselElementRef.current) {
                    indRail = Math.ceil(this.watchListCarouselElementRef.current.scrollLeft / 260);
                    this.setState({ indexOfRail: indRail})
                }
            });
        }
    };

    removeWatchListContent = (contentDetails) => {
        const { watchListContentRemoveDispatch, isPywWatchListRail } = this.props;
        if (isPywWatchListRail) {
            watchListContentRemoveDispatch({ contentDetails, watchlistMeta: {isFromPYW: true} });
            return;
        }
        watchListContentRemoveDispatch({ contentDetails });
    }

    next = () => {
        if (this.watchListCarouselElementRef.current) {
            const { clientWidth } = this.watchListCarouselElementRef.current;
            this.watchListCarouselElementRef.current.scrollLeft += clientWidth;
        }
    }

    previous = () => {
        if (this.watchListCarouselElementRef.current) {
            const { clientWidth } = this.watchListCarouselElementRef.current;
            this.watchListCarouselElementRef.current.scrollLeft -= clientWidth;
        }
    }

    showNext = () => {
        const { indexOfRail, scrollLeft, clientWidth, scrollWidth, tiles } = this.state;
        return (scrollLeft < (scrollWidth - clientWidth) || (indexOfRail < tiles.length - Math.floor(clientWidth/275)));
    }

    setHoveredTile = (hoveredTile) => {
        this.setState({
            hoveredTile
        })
    }

    render() {
        const {
            railTitle, railCtaActionUrl, railIndex, railId, imageSettings, packageId, tileType, isPywWatchListRail,
        } = this.props;
        const { tiles, hoveredTile } = this.state;
        // const { showArrows } = this;
        let lazyTiles = [];

        if (tiles && Array.isArray(tiles) && tiles.length < 1) {
            return null;
        }

        let showArrows = false;
        // const tilesToShow = this.isMobile ? tiles.slice(0, MAX_TILE_LIMIT_MWEB.LANDSCAPE) : tiles.slice(0, MAX_TILE_LIMIT_WEB.LANDSCAPE);
        const showMore = tiles.length > this.tileLimit;
        if (tiles && Array.isArray(tiles)) {
            const tileElements = tiles.map((tile, i) => {
                const tileProps = {
                    key: `${tile.id}-${railId}`,
                    ...tile,
                    railCtaActionUrl,
                    railPosition: railIndex,
                    tilePosition: i,
                    railId,
                    imageSettings,
                    hoveredTile,
                    setHoveredTile: this.setHoveredTile,
                    packageId
                };

                const tileElement = getTile({
                    tileProps,
                    onRemoveIconClick: this.removeWatchListContent,
                });

                return tileElement;
            });
            const key = `${railId}-${railIndex}`;
            lazyTiles = (
                <HorizontalLazyLoaderHOC
                    tileElements={tileElements}
                    tileLimit={this.tileLimit}
                    railKey={key}
                />
            );
            showArrows = (tileElements && tileElements.length > this.tileLimit - 1);
        }
        // check if there is an error
        else if (typeof tiles === 'object') {
            // TODO: Handle error
        }

        // const moreAnalyticsMeta = {
        //     rail_id: railId,
        //     action: 'more_click',
        //     source_name: getCurrentWindowSourceName(),
        // };

        const { indexOfRail } = this.state;
        const { SCROLL_RAIL_LEFT = "Scroll Rail Left", SCROLL_RAIL_RIGHT = "Scroll Rail Right" } = ARIA_LABEL;

        return (
            
            <section
                className=" m-b-30 home-row margin-padding-0-mweb m-30-bottom"
                name = {`${railTitle || LOCAL_PACKAGE_NAME.CONTINUE_WATCHING}`} 
                id={`${
                    tileType === TILE_TYPES.X_PYW_RAIL ? 'atm_pyw_watchlist-container' : 'atm_watchlist-container'}
                `}
                data-id={`${ELEMENT_ID.RAIL_ID}-${railIndex}`}
            >
                {/* to be reviewed */}
                <div className=" title-side-margin title title-with-cta d-flex justify-content-between align-items-center watch-list-align">

                    <LinkComponent
                        to={ROUTE_PATHS.WATCHLIST_PAGE}
                        className="home-page-font text-bold-20 mobile-text-bold-16 d-inline-block"
                        title={railTitle}
                        id={`${ELEMENT_ID.RAIL_TITLE}-${railIndex}`}
                        // onClick={() => railCtaClickGaEvent(railCtaText)}
                    >
                        <h2 className="text-bold-20 mobile-text-bold-16" id={ELEMENT_ID.WATCHLIST_TITLE_TEXT}>{`${railTitle || LOCAL_PACKAGE_NAME.CONTINUE_WATCHING}`}</h2>
                    </LinkComponent>

                    {/*  When Watchlist Rail page is built remove this */}
                    <div className={(showArrows || showMore) ? 'd-flex justify-content-between align-items-center' : 'd-none'}>
                        <div className={!showArrows ? 'd-none' : 'carousel-arrows'}>
                            <span className="button-ripple">
                                <AnalyticsButtonComponent
                                    type="button"
                                    className="button button-link"
                                    onClick={this.previous}
                                    aria-label={SCROLL_RAIL_LEFT}
                                >
                                    { /* eslint-enable */}
                                    <i className="icon20 icon-carousel-prev-arrow" />
                                </AnalyticsButtonComponent>
                            </span>

                            <span className="button-ripple">
                                <AnalyticsButtonComponent
                                    type="button"
                                    className="button button-link"
                                    onClick={this.next}
                                    aria-label={SCROLL_RAIL_RIGHT}
                                >
                                    { /* eslint-enable */}
                                    <i className="icon20 icon-carousel-arrow icon-carousel-next-arrow" />
                                </AnalyticsButtonComponent>
                            </span>
                        </div>

                        {/* {railCtaActionUrl
                            ? (
                                <AnalyticsLinkComponent
                                    to={railCtaActionUrl}
                                    className="cta cta--link cta--link--medium cta--link--theme cta-link--vdo-hasMoreButton d-inline-block"
                                    meta={moreAnalyticsMeta}
                                // onClick={() => railCtaClickGaEvent(railCtaText)}
                                >
                                    {railCtaText}
                                </AnalyticsLinkComponent>
                            ) : null} */}
                    </div>
                </div>


                {/* <div className="cards-row" ref={watchListCarouselElementRef} onMouseDown={handleMouseDown} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} draggable="false" role="carousel"> */}
                <div
                    className='pos-relative buttons-external-holder height-fill small'
                    id={`${
                        isPywWatchListRail ? ELEMENT_ID.PYW_WATCHLIST_RAIL : ELEMENT_ID.WATCHLIST_RAIL
                    }`}
                >
                { !this.isMobile && indexOfRail > 0 && (
                    <AnalyticsButtonComponent
                        className="slide-btn-cdp left landscape"
                        onClick={this.previous}
                        aria-label={SCROLL_RAIL_LEFT}
                    >
                        <ThumborImage
                            src="/static/cdp-assets/chev-sside.svg"
                        />
                    </AnalyticsButtonComponent>
                ) }
                <section
                    className="cards-row continue-row cw-only-padding margin-padding-0-mweb m-30-bottom"
                    ref={this.watchListCarouselElementRef}
                    draggable="false"
                    // eslint-disable-next-line jsx-a11y/aria-role
                    role="carousel"
                >
                    <section className="cards-row__inner" id={`${railId}`}>
                        {lazyTiles}
                    </section>
                </section>
                { !this.isMobile && this.showNext() && (
                    <AnalyticsButtonComponent
                        className="slide-btn-cdp right landscape"
                        onClick={() => this.next(lazyTiles.length)}
                        aria-label={SCROLL_RAIL_RIGHT}
                    >
                        <ThumborImage
                            src="/static/cdp-assets/chev-sside.svg"
                        />
                    </AnalyticsButtonComponent>
                ) }
            </div>
                
            </section>
        );
    }
}

function mapStateToProps(state) {
    const {
        syncContent: {
            watchList,
        },
        appConfig: { cpDetailsById },
        userConfig: { subscriptionDataById },
    } = state;
    return {
        watchList,
        cpDetailsById,
        subscriptionDataById,
    };
}

WatchlistRailContainer.defaultProps = {
    railIndex: 0,
    railId: '',
    railCtaActionUrl: '',
    packageId: '',
    isPywWatchListRail: false,
};

WatchlistRailContainer.propTypes = {
    railTitle: PropTypes.string.isRequired,
    // railCtaText: PropTypes.string.isRequired,
    railCtaActionUrl: PropTypes.string,
    railIndex: PropTypes.number,
    railId: PropTypes.string,
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    watchList: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    watchListContentRemoveDispatch: PropTypes.func.isRequired,
    isPywWatchListRail: PropTypes.bool,
    tileType: PropTypes.string.isRequired,
    cpDetailsById: PropTypes.objectOf(PropTypes.object).isRequired,
    subscriptionDataById: PropTypes.objectOf(PropTypes.object).isRequired,
    packageId: PropTypes.string,
};

export default connect(mapStateToProps, {
    watchListContentRemoveDispatch: watchListContentRemove,
})(withDeviceUtil(WatchlistRailContainer));
