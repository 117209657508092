/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import lodashThrottle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from '@airtel-tv/common-scss/_banner-carousel.scss';
import { BANNER_TILE_SIZE, BANNER_INTERVAL } from '@airtel-tv/constants/LayoutConstants';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics/AnalyticsLinkComponent';
import SubscriptionUtil from '@airtel-tv/utils/SubscriptionUtil';
import AnalyticsButtonComponent from '@airtel-tv/analytics/AnalyticsButtonComponent';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import { deepLinkParser, getSourceName } from '@airtel-tv/utils/GlobalUtil';
import CrashCaptureHOC from '@airtel-tv/lib/hoc/CrashCaptureHOC';
import LanguageWebView from '@airtel-tv/ui-lib/atoms/icons/components/LanguageWebView';
import SearchIcon from '@airtel-tv/ui-lib/atoms/icons/components/SearchIcon';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import { DEEPLINK_INGRESS_KEYS, MODAL_POPUP } from '@airtel-tv/constants';
import { ANALYTICS_ACTIONS } from '../../../constants/AnalyticsConst';
import { IMAGE_PATHS, PROGRAM_TYPES, SCREEN_SIZES } from '../../../constants/AppConst';
import { SlickButtonComponent } from './SlickButtonComponent';
import { hideModalComponentAction, showModalComponentAction } from '../../modal-popup/ModalPopupActions';

const tileOffset = 2;
class FeaturedSliderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dragging: '',
            refresh: false,
            currentIndex: tileOffset,
            hideImages: true,
            tilesRendered: false,
            bannerSpeed: null,
            playSlider: true,
        };
        this.carouselElementRef = React.createRef();
        this.deviceUtil = props.deviceUtil;
        this.LANGUAGE = LanguageProviderUtil.getLanguage();
        this.lottieRefs = {};
        this.bannerSpeed = this.props.bannerSpeed;
        this.observer = null;
        this.containerRef = React.createRef();
        // Debounce on next and prev click
        // this.debounceTranslateBanner = lodashDebounce(this.translateBanner, BANNER_INTERVAL.TRANSITION_DURATION, {
        //     trailing: false,
        //     leading: true,
        // });
        this.debounceOnWheel = lodashThrottle(this.onWheelEvent, BANNER_INTERVAL.WHEEL_EVENT, {
            trailing: false,
            leading: true,
        });
        this.touchStartX = 0;
        this.touchStartY = 0;
        this.carouselTimer = setTimeout(this.slideBannerInterval, 0);
        this.intervalRef;
        this.isScopedWebview = this.deviceUtil.isScopedWebview();
    }


    componentDidMount() {
        if (this.carouselWrapperRef) {
            this.carouselWrapperRef.addEventListener('wheel', this.debounceOnWheel, { passive: false });
            this.carouselWrapperRef.addEventListener('touchstart', this.touchStartEvent, { passive: false });
            this.carouselWrapperRef.addEventListener('touchend', this.touchEndEvent, { passive: false });
            this.init();
            this.setState({ hideImages: false });
        }
        const observerOptions = {
            root: null,
            rootMargin: '0px 0px 0px 0px',
            threshold: 0.5,
        };
        this.observer = new IntersectionObserver((entries, _) => {
            entries.forEach((entry) => {
                const { isIntersecting } = entry;
                this.setState({ playSlider: isIntersecting });
            });
        }, observerOptions);
        if (this.containerRef?.current) {
            this.observer.observe(this.containerRef?.current);
        }
    }

    componentWillUnmount() {
        if (this.carouselWrapperRef) {
            this.carouselWrapperRef.removeEventListener('wheel', this.debounceOnWheel, { passive: false });
            this.carouselWrapperRef.removeEventListener('touchstart', this.touchStartEvent, { passive: false });
            this.carouselWrapperRef.removeEventListener('touchend', this.touchEndEvent, { passive: false });
            // this.debounceTranslateBanner.cancel();
        }
        if (this.containerRef?.current) {
            this.observer?.unobserve(this.containerRef.current);
        }
        if (this.intervalRef?.current) {
            clearInterval(this.intervalRef);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { bannerSpeed: prevBannerSpeed, playSlider: prevPlaySlider } = prevState;
        // const { bannerSpeed } = this.props;
        const { bannerSpeed, playSlider, currentIndex } = this.state;
        if (prevState.currentIndex !== this.state.currentIndex) {
            if (bannerSpeed) {
                // this.props.setBannerSpeedDispatch({ bannerSpeed: null });
                this.setState({
                    bannerSpeed: null,
                });
                this.bannerSpeed = null;
            }
        }
        if (bannerSpeed !== prevBannerSpeed) {
            this.bannerSpeed = bannerSpeed;
        }
        // if (prevPlaySlider !== playSlider) {
        //     // this.translateBanner(currentIndex);
        // }
    }

    updateBannerSpeed = (bannerSpeed) => {
        this.setState({
            bannerSpeed,
        });
    };

    slideBannerInterval = (time) => {
        if (this.carouselTimer) {
            clearTimeout(this.carouselTimer);
        }
        let timer = time || (this.bannerSpeed * 1000 || BANNER_INTERVAL.NEXT_TILE) / 1000;
        if (this.intervalRef) {
            clearInterval(this.intervalRef);
        }
        this.intervalRef = setInterval(() => {
            this.state.playSlider && timer--;
            if (timer === 0) {
                this.next();
                clearInterval(this.intervalRef);
                return;
            }
            if (!this.state.playSlider) {
                clearInterval(this.intervalRef);
                this.slideBannerInterval(timer);
            }
        }, 1000);
        // this.carouselTimer = setTimeout(this.next, this.bannerSpeed * 1000 || BANNER_INTERVAL.NEXT_TILE);
    };

    touchStartEvent = (e) => {
        this.touchStartX = e.changedTouches[0].clientX;
        this.touchStartY = e.changedTouches[0].clientY;
    };

    touchEndEvent = (e) => {
        const { currentIndex } = this.state;
        const touchEndX = e.changedTouches[0].clientX;
        const touchEndY = e.changedTouches[0].clientY;
        const xMoveDiff = touchEndX - this.touchStartX;
        const yMoveDiff = touchEndY - this.touchStartY;
        if (Math.abs(xMoveDiff) > Math.abs(yMoveDiff)) {
            if (xMoveDiff < 0) {
                this.translateBanner(currentIndex + 1);
            }
            else if (xMoveDiff > 0) {
                this.translateBanner(currentIndex - 1);
            }
        }
        this.touchStartX = 0;
    };

    onWheelEvent = (e) => {
        if (e.deltaX > 0) {
            this.next();
        }
        else if (e.deltaX < 0) {
            this.previous();
        }
        this.touchStartX = 0;
        e.preventDefault();
    };

    init = () => {
        const { currentIndex } = this.state;
        const translatePercent = this.calculateTanslatePercent(currentIndex);
        // this.carouselElementRef.current.style.cssText = `transform: translateX(${0}%)`;
        this.carouselElementRef.current.style.cssText = `transform: translateX(${translatePercent}%)`;
    };

    calculateTanslatePercent = (currentIndex) => {
        const tileSize = this.deviceUtil.getWindowWidth() < SCREEN_SIZES.IPAD_SIZE ? BANNER_TILE_SIZE.MOBILE : BANNER_TILE_SIZE.DESKTOP;
        const initialOffset = tileSize - (100 - tileSize) / 2;
        return -initialOffset - tileSize * (currentIndex - 1);
    };

    translateBanner = (currentIndex) => {
        const { tiles } = this.props;
        if (!this.state.playSlider) {
            if (this.carouselTimer) {
                clearTimeout(this.carouselTimer);
            }
            return;
        }
        if (tiles.length === 1) {
            return;
        }
        const translatePercent = this.calculateTanslatePercent(currentIndex);
        this.carouselElementRef.current.style.cssText = `transition-duration: 1s; transform: translate3d(${translatePercent}%, 0, 0)`;
        this.setState({
            currentIndex,
        }, this.slideBannerInterval);
        if (currentIndex === tiles.length + tileOffset) {
            setTimeout(() => {
                this.setState({
                    currentIndex: tileOffset,
                }, this.init);
            }, BANNER_INTERVAL.TRANSITION_DURATION);
        }

        else if (currentIndex === 1) {
            setTimeout(() => {
                this.setState({
                    currentIndex: tiles.length + 1,
                }, this.init);
            }, BANNER_INTERVAL.TRANSITION_DURATION);
        }
    };

    next = () => {
        if (this.carouselElementRef && this.carouselElementRef.current) {
            const { currentIndex } = this.state;
            this.intervalRef && clearInterval(this.intervalRef);

            this.translateBanner(currentIndex + 1);
        }
    };

    previous = () => {
        if (this.carouselElementRef && this.carouselElementRef.current) {
            const { currentIndex } = this.state;
            // this.debounceTranslateBanner(currentIndex - 1);
            this.translateBanner(currentIndex - 1);
        }
    };

    setRefs = (id, ref) => {
        this.lottieRefs = {
            ...this.lottieRefs,
            [id]: ref,
        };
        if (id === tileOffset) {
            this.setState({
                tilesRendered: true,
            });
        }
    };

    render() {
        const {
            tiles, railCtaActionUrl, railIndex, railId, imageSettings, history, showWatchNowOnBanner = 'true',
            getTile, packageId, bannerSpeed, setBannerSpeedDispatch, headerStatic, deepLinkConfig
        } = this.props;
        const {
            dragging, currentIndex, hideImages, tilesRendered,
        } = this.state;
        const isMobile = this.deviceUtil.isMobile();
        let tileElements = [];
        if (tiles && Array.isArray(tiles) && tiles.length < 1) {
            return null;
        }
        const slickDots = tiles.map((tile, index) => {
            const activeIndex = ((currentIndex + tiles.length - tileOffset) % tiles.length);
            const isActive = activeIndex === index;
            return (
                <SlickButtonComponent
                    key={tile.id}
                    isActive={isActive}
                    index={index}
                    activeIndex={activeIndex}
                    sliderTimer={this.lottieRefs[currentIndex]?.current?.getDuration() || BANNER_INTERVAL.NEXT_TILE / 1000}
                    translateBanner={this.translateBanner}
                    tilesRendered={tilesRendered}
                    bannerSpeed={this.state.bannerSpeed}
                    playSlider={this.state.playSlider}
                />
            );
        });
        if (tiles && Array.isArray(tiles)) {
            const newTiles = tiles.slice(-1 * tileOffset)
                .concat(tiles)
                .concat(tiles.slice(0, tileOffset));
            tileElements = newTiles.map((tile, i) => {
                const lottieRef = React.createRef();
                const tileProps = {
                    key: `${tile.id}-${i}`,
                    ...tile,
                    // bgImgUrlMap: { bgImgUrlLS: 'https://image.airtel.tv/grandslam/content/2023_10_19/Watch_For_Free.json'},
                    railCtaActionUrl,
                    railPosition: railIndex,
                    tilePosition: i,
                    railId,
                    dragging,
                    imageSettings,
                    isMobile,
                    index: i,
                    tileOffset,
                    lottieRef,
                    setRefs: this.setRefs,
                    next: this.next,
                    packageId,
                    isActive: currentIndex === i,
                    updateBannerSpeed: this.updateBannerSpeed,
                };
                return getTile({
                    tileProps,
                });
            });
        }
        // check if there is an error
        else if (typeof tiles === 'object') {
            // TODO: Handle error
        }
        // if (this.currentIndex === tiles.length + 2) {
        //     this.currentIndex = 2;
        //     this.init();
        // }
        // const settings = {
        //     accessibility: false,
        //     autoplay: true,
        //     dots: true,
        //     className: 'center',
        //     centerMode: true,
        //     infinite: true,
        //     centerPadding: (this.deviceUtil.isMobile() ? '30px' : '250px'),
        //     slidesToShow: 1,
        //     speed: 500,
        //     nextArrow: <NextArrowComponent onClickNext={this.next} />,
        //     prevArrow: <PreviousArrowComponent onClickPrev={this.previous} />,
        //     afterChange: () => {
        //         this.setState({
        //             dragging: '',
        //         });
        //     },
        // };
        const {
            subscriptionDataById, cpDetailsById, isAuthenticated, showModalComponentActionDispatch, hideModalComponentActionDispatch, isXppUser, maxAllowedPlaybackTime, userContentProperties, pageId, location, planClaimStatusClient,
        } = this.props;
        const currentTile = tiles && tiles[currentIndex - 2] || {};
        const { cpId = '', tileCtaActionUrl = '' } = currentTile;

        const channelId = SubscriptionUtil.getChannelId({
            cpDetailsById,
            cpId,
        });
        const channelDetails = lodashGet(cpDetailsById, `[${channelId}]`, null);
        const subscriptionDetails = lodashGet(subscriptionDataById, `[${channelId}]`, null);
        const targetHierarchy = lodashGet(channelDetails, 'targetHierarchy', 0);
        const isSubscribed = SubscriptionUtil.isSubscribed(subscriptionDetails, targetHierarchy);
        let deepLink = lodashGet(currentTile, 'contentDetails.deepLink', '');
        // let deepLinkParsed = '';
        // try {
        //     deepLinkParsed = deepLink ? new URL(deepLink) : '';
        // }
        // catch (e) {
        //     console.log('invalid url', e);
        // }

        // deepLink = deepLinkParsed !== '' ? (deepLinkParsed.pathname + deepLinkParsed.search) : null;
        deepLink = deepLinkParser(deepLink, { planClaimStatusClient });
        const ctaText = currentTile?.contentDetails?.cta?.name;
        const watchNowCtaActionUrl = (isAuthenticated && isSubscribed && tiles && tileCtaActionUrl) ? `${tileCtaActionUrl}?showPlayback=true&fromHome=true` : currentTile?.tileCtaActionUrl;
        const watchNowAnalyticsMeta = currentIndex > 2 && tiles && (currentIndex) < tiles.length || true ? {
            cp_name: currentTile.cpId,
            content_id: currentTile.id,
            action: ANALYTICS_ACTIONS.BANNER_CTA_CLICK,
            rail_position: currentTile.railPosition,
            tile_type: currentTile.tileType,
            rail_id: railId,
            asset_position: currentTile.tilePosition,
            asset_name: ctaText,
            package_id: packageId,
            rail_title: 'Banner',
        } : {};
        const tileAnalyticsMeta = currentIndex > 2 && tiles && (currentIndex) < tiles.length || true ? {
            cp_name: currentTile.cpId,
            content_id: currentTile.id,
            // action: ANALYTICS_ACTIONS.TILE_CLICK,
            rail_position: currentTile.railPosition,
            tile_type: currentTile.tileType,
            rail_id: railId,
            asset_position: currentTile.tilePosition,
            // asset_name: 'banner_tile_click',
            asset_name: ctaText || 'banner_tile_click',
            action: ANALYTICS_ACTIONS.BANNER_CTA_CLICK,
            package_id: packageId,
        } : {};
        const { BANNER_WATCH_NOW = 'Watch Now' } = this.LANGUAGE;
        const showFallbackCta = cpId && currentTile?.contentDetails?.programType && [
            PROGRAM_TYPES.TVSHOW,
            PROGRAM_TYPES.MOVIE,
        ].includes(currentTile.contentDetails?.programType);
        // ctaText = !ctaText && showFallbackCta ? BANNER_WATCH_NOW : ctaText;

        const { language: languageIcon = {} } = headerStatic || {};
        return (
            <div
                className={`pos-relative home-player-section ${hideImages ? 'hide-img' : ''}`}
                ref={this.containerRef}
            >
                <div
                    id="home-banner"
                    className="banner_container"
                    ref={(ref) => {
                        this.carouselWrapperRef = ref;
                    }}
                >
                    {this.isScopedWebview ? (
                        <div className="language-webview">
                            <AnalyticsLinkComponent
                                to={languageIcon?.to || ''}
                                id={languageIcon?.id || 'language-icon'}

                            >
                                <LanguageWebView />
                            </AnalyticsLinkComponent>
                            <AnalyticsLinkComponent
                                to={ROUTE_PATHS.SEARCH}
                                aria-label="SEARCH PAGE"
                                style={{ marginLeft: '20px' }}
                            >
                                <SearchIcon />
                            </AnalyticsLinkComponent>
                        </div>
                    ) : null}

                    <div
                        id="featurebanner"
                        className="container_banner "
                        ref={this.carouselElementRef}
                        style={{ transform: 'translateX(-200%)' }}
                    >
                        {tileElements}
                    </div>
                </div>
                {tiles.length > 1 && (
                    <>
                        <div
                            id="Left_container"
                            className=""
                        >
                            <AnalyticsButtonComponent
                                id="innerleftbutton"
                                onClick={this.previous}
                                className="previous_button"
                                type="button"
                                aria-label="Scroll Banner Left"
                            >
                                <span className="arrow_previous">
                                    <img
                                        src={IMAGE_PATHS.BANNER_LEFT_NEW}
                                        alt="Scroll Left"
                                        loading="lazy"
                                    />
                                </span>

                            </AnalyticsButtonComponent>
                        </div>
                        <div
                            id="Right_container"
                            className=""
                        >
                            <AnalyticsButtonComponent
                                id="innerrightbutton"
                                onClick={this.next}
                                className="next_button"
                                type="button"
                                aria-label="Scroll Banner Right"
                            >
                                <span className="arrow_next">
                                    <img
                                        src={IMAGE_PATHS.BANNER_RIGHT_NEW}
                                        alt="Scroll Right"
                                        loading="lazy"
                                    />
                                </span>

                            </AnalyticsButtonComponent>
                        </div>
                    </>
                ) }
                {ctaText && tiles && (currentIndex) <= tiles.length + 1 && (
                    <div className="watch-now-btn-wrapper">
                        <AnalyticsLinkComponent
                            to={deepLink || watchNowCtaActionUrl}
                            onClick={(event) => {
                                if (!ScopedWebviewUtil.canPlayPlayback(maxAllowedPlaybackTime, userContentProperties, currentTile?.contentDetails)) {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    showModalComponentActionDispatch({
                                        showModalValue: true,
                                        componentNameValue: MODAL_POPUP.DOWNLOAD_XSTREAM_APP_POPUP,
                                        overrideCrossButtonValue: true,
                                        payload: {
                                            contentDetails: currentTile?.contentDetails || {},
                                            isSubscribed: isXppUser,
                                            pageId,
                                            meta: {
                                                popup_id: 'download_hard_popup',
                                                source_name: getSourceName(location.pathname),
                                                content_category: 'Content',
                                            },
                                            notNowBtnHandler: () => {
                                                hideModalComponentActionDispatch({
                                                    showModalValue: false,
                                                    componentNameValue: null,
                                                    overrideCrossButtonValue: false,
                                                });
                                            },
                                            downloadNowBtnHandler: () => {
                                                ScopedWebviewUtil.navigateToDeepLinkOnCta(deepLinkConfig, DEEPLINK_INGRESS_KEYS.HARD_POPUP);
                                            },
                                        },
                                    });
                                }
                            }}
                            meta={watchNowAnalyticsMeta}
                            className="play-btn-banner hover-effect common-white-btn"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M21.3889 11.36L7.8911 3.11102C7.77741 3.04154 7.64726 3.0036 7.51404 3.00111C7.38083 2.99861 7.24935 3.03165 7.13314 3.09683C7.01692 3.162 6.92017 3.25696 6.85283 3.37193C6.7855 3.4869 6.75 3.61774 6.75 3.75098V20.2488C6.75 20.3821 6.7855 20.5129 6.85283 20.6279C6.92017 20.7429 7.01692 20.8378 7.13314 20.903C7.24935 20.9682 7.38083 21.0012 7.51404 20.9987C7.64726 20.9962 7.77741 20.9583 7.8911 20.8888L21.3889 12.6399C21.4985 12.5729 21.5891 12.4788 21.652 12.3668C21.7148 12.2547 21.7478 12.1284 21.7478 11.9999C21.7478 11.8714 21.7148 11.7451 21.652 11.633C21.5891 11.521 21.4985 11.427 21.3889 11.36Z"
                                    fill="#0C0F12"
                                />
                            </svg>
                            <p>{ ctaText }</p>
                        </AnalyticsLinkComponent>
                    </div>
                )
                }
                {tiles.length > 1 && (
                    <div
                        id="indicator_div"
                        className="indicator_div"
                    >
                        {slickDots}
                    </div>
                ) }
                {!isMobile ? (
                    <AnalyticsLinkComponent
                        to={deepLink || tileCtaActionUrl}
                        meta={tileAnalyticsMeta}
                    >
                        <div className="gradient-banner-custom" />
                        <div className="gradient-banner-backdrop" />
                    </AnalyticsLinkComponent>
                ) : null}
            </div>

        );
    }
}

FeaturedSliderComponent.defaultProps = {
    railCtaActionUrl: '',
    railIndex: 0,
    imageSettings: [],
    packageId: '',
};

FeaturedSliderComponent.propTypes = {
    tiles: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    railCtaActionUrl: PropTypes.string,
    railIndex: PropTypes.number,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    railId: PropTypes.string.isRequired,
    imageSettings: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    packageId: PropTypes.string,
    planClaimStatusClient: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
    const {
        appConfig: {
            showWatchNowOnBanner, cpDetailsById, headerStatic = {}, maxAllowedPlaybackTime, deepLinkConfig,
        },
        userConfig: { subscriptionDataById, userContentProperties: { oSeg = '' } = {}, userContentProperties },
        authConfig,
        notify: { bannerSpeed = 0 },
        plansAndSubscriptionData: {
            svodPlans: {
                all_plans: {
                    planClaimStatusClient = {},
                } = {},
            } = {},
        } = {},
    } = state;
    const isXppUser = !!(oSeg || '').split(',').filter(item => item === 'xstreampremium_paid' || item === 'xstreampremium_telco_paid').length;

    return {
        showWatchNowOnBanner,
        cpDetailsById,
        subscriptionDataById,
        isAuthenticated: authConfig.isAuthenticated,
        bannerSpeed,
        headerStatic,
        isXppUser,
        maxAllowedPlaybackTime,
        userContentProperties,
        deepLinkConfig,
        planClaimStatusClient,
    };
}

export default withRouter(withDeviceUtil(connect(mapStateToProps, {
    showModalComponentActionDispatch: showModalComponentAction,
    hideModalComponentActionDispatch: hideModalComponentAction,
})(withStyles(styles)(CrashCaptureHOC(FeaturedSliderComponent)))));
