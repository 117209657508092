import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import LazyIconFatory, { ICON_MAP } from '@airtel-tv/ui-lib/atoms/icons/iconFactory';
import { AnalyticsLinkComponent, popUpVisibleEvent } from '@airtel-tv/analytics';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { ANALYTICS_ACTIONS, RAIL_TYPES, WARNING_TYPE } from '@airtel-tv/constants';
import styles from './xWarningRailComponent.scss';

const XWarningRailComponent = (props) => {
    useEffect(() => {
        popUpVisibleEvent({
            rail_type: RAIL_TYPES.X_WARNING_RAIL,
        });
    }, []);
    const { zionDisplay: { description: railSecondaryText = '', type = '', link = '' } = {} } = props;
    const pattern = /(.*?)<\/n>(.*?)<\/n>/;
    const match = railSecondaryText.match(pattern) || [
        '',
        'Your recharge of ₹{value} is',
        'expiring in 3 days',
    ];
    const color = [WARNING_TYPE.DESTRUCTIVE, WARNING_TYPE.DESCRUTIVE].includes(type.toLowerCase()) ? '#F29090' : '#F2CB90';
    return (
        <section
            className="xWarning-container"
        >
            <section className="xpp-benefit-info-container expiry-text">
                <p
                    className="warning-para mobile-text-12 text-13 line-height-18  text-normal"
                    style={{ color }}
                >
                    {match[1] + match[2]}
                </p>
                <AnalyticsLinkComponent
                    to={ROUTE_PATHS.SUBSCRIPTION_PAGE}
                    style={{ color }}
                    className="recharge-link text-bold-13 mobile-text-bold-12 line-height-18"
                    meta={{
                        rail_type: RAIL_TYPES.X_WARNING_RAIL,
                        asset_name: ANALYTICS_ACTIONS.RECHARGE_NOW,
                    }
                    }
                >
                    {link}
                </AnalyticsLinkComponent>
            </section>
        </section>
    );
};

const mapStateToProps = ({
    allPlans: {
        amount = '',
    } = {},
}) => ({
    amount,
});
XWarningRailComponent.propTypes = {
    zionDisplay: PropTypes.object,
};

XWarningRailComponent.defaultProps = {
    zionDisplay: {},
};
export default connect(mapStateToProps)(withStyles(styles)(XWarningRailComponent));
