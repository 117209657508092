const PackageActions = {
    PACKAGES_FETCH: 'PACKAGES_FETCH',
    PACKAGE_FETCH: 'PACKAGE_FETCH',
    PACKAGE_FETCH_ERROR: 'PACKAGE_FETCH_ERROR',
    PACKAGE_FETCH_COMPLETED: 'PACKAGE_FETCH_COMPLETED',
    PACKAGE_FETCH_ALL_DATA: 'PACKAGE_FETCH_ALL_DATA',

    PACKAGE_FETCH_PARTIAL: 'PACKAGE_FETCH_PARTIAL',
    PACKAGE_FETCH_PARTIAL_SUCCESS: 'PACKAGE_FETCH_PARTIAL_SUCCESS',
    PACKAGE_FETCH_SYNC_CONTENT: 'PACKAGE_FETCH_SYNC_CONTENT',
    PURGE_PACKAGE_DATA: 'PURGE_PACKAGE_DATA',
    REMOVE_PACKAGE_ERROR: 'REMOVE_PACKAGE_ERROR',
};

export default PackageActions;

export const packagesFetchAction = payload => ({
    type: PackageActions.PACKAGES_FETCH,
    ...payload,
});


export const packageFetchAction = payload => ({
    type: PackageActions.PACKAGE_FETCH,
    ...payload,
});

export const packageFetchAllDataAction = payload => ({
    type: PackageActions.PACKAGE_FETCH_ALL_DATA,
    ...payload,
});

export const packageFetchActionSuccess = payload => ({
    type: PackageActions.PACKAGE_FETCH_COMPLETED,
    ...payload,
});

export const packageFetchActionError = payload => ({
    type: PackageActions.PACKAGE_FETCH_ERROR,
    ...payload,
});


export const packagePartialFetchAction = payload => ({
    type: PackageActions.PACKAGE_FETCH_PARTIAL,
    ...payload,
});

export const packagePartialFetchActionSuccess = payload => ({
    type: PackageActions.PACKAGE_FETCH_PARTIAL_SUCCESS,
    ...payload,
});

export const purgePackageData = payload => ({
    type: PackageActions.PURGE_PACKAGE_DATA,
    ...payload,
});

export const removePackageError = payload => ({
    type: PackageActions.REMOVE_PACKAGE_ERROR,
    ...payload,
});

// export const packageFetchSyncContent = payload => ({
//     type: PackageActions.PACKAGE_FETCH_SYNC_CONTENT,
//     ...payload,
// });
