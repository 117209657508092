const RailActions = {
    RAIL_FETCH: 'RAIL_FETCH',
    RAIL_FETCH_ERROR: 'RAIL_FETCH_ERROR',
    RAIL_FETCH_COMPLETED: 'RAIL_FETCH_COMPLETED',
};

export default RailActions;

export const railFetchAction = payload => ({
    type: RailActions.RAIL_FETCH,
    ...payload,
});

export const railFetchActionSuccess = payload => ({
    type: RailActions.RAIL_FETCH_COMPLETED,
    ...payload,
});

export const railFetchActionError = payload => ({
    type: RailActions.RAIL_FETCH_ERROR,
    ...payload,
});
