import React from 'react';
// import PropTypes from 'prop-types';
import { getTile } from '../factories/TileFactory';

const AddSectionRailComponent = (tileProps) => {
    const tileElement = getTile({ tileProps });
    return (
        <div className="ads-banner-wrap section-bottom-padding">
            {tileElement}
        </div>
    );
};

AddSectionRailComponent.defaultProps = {
};

AddSectionRailComponent.propTypes = {
};

export default React.memo(AddSectionRailComponent);
