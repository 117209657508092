import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getTile } from '../factories/TileFactory';
import HorizontalLazyLoaderHOC from '../../shared/hoc/HorizontalLazyLoaderHOC';
import { findTileLimit } from '../../../utilities/CommonUtil';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { getCurrentWindowSourceName } from '../../../utilities/WindowUtil';
import RailEventHOC from '../../shared/hoc/RailEventHOC';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { ELEMENT_ID } from '../../../constants/AppConst';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';

const TvShowLogo43RailComponent = React.forwardRef((props, ref) => {
    const {
        tiles, railTitle, railCtaActionUrl, railIndex, railId, deviceUtil, imageSettings, tileType, railCtaText, onRailScroll,
        visibleWithClickEvent, deviceUtil: { isMobile }, packageId, titleType, maxTileTitleLines, isSearchEvent = false,
    } = props;
    const [
        hoveredTile,
        setHoveredTile,
    ] = useState('');

    const windowWidth = deviceUtil.getWindowWidth();
    const tileLimit = findTileLimit({
        windowWidth,
        tileType,
    });
    const carouselElementRef = React.createRef();

    const next = () => {
        if (carouselElementRef.current) {
            const { clientWidth } = carouselElementRef.current;
            carouselElementRef.current.scrollLeft += clientWidth / 2;
        }
        visibleWithClickEvent();
    };

    const previous = () => {
        if (carouselElementRef.current) {
            const { clientWidth } = carouselElementRef.current;
            carouselElementRef.current.scrollLeft -= clientWidth / 2;
        }
        visibleWithClickEvent();
    };

    let tileElements = [];
    let lazyTiles = [];

    if (tiles && Array.isArray(tiles) && tiles.length < 1) {
        return null;
    }

    if (tiles && Array.isArray(tiles)) {
        tileElements = tiles.map((tile, i) => {
            const tileProps = {
                key: `${tile.id}-${i}`,
                ...tile,
                railCtaActionUrl,
                railPosition: railIndex,
                tilePosition: i,
                railId,
                imageSettings,
                railTitle,
                hoveredTile,
                setHoveredTile,
                packageId,
                titleType,
                maxTileTitleLines,
                isSearchEvent,
            };

            const tileElement = getTile({
                tileProps,
            });

            return tileElement;
        });
        const key = `${railId}-${railIndex}`;
        lazyTiles = (
            <HorizontalLazyLoaderHOC
                tileElements={tileElements}
                tileLimit={tileLimit}
                railKey={key}
            />
        );
    }
    // check if there is an error
    else if (typeof tiles === 'object') {
        // TODO: Handle error
    }
    const showArrows = (tileElements && tileElements.length > tileLimit - 1);

    const moreAnalyticsMeta = {
        rail_id: railId,
        // action: 'more_click',
        // source_name: getCurrentWindowSourceName(),
        action: ANALYTICS_ACTIONS.RAIL_CTA_CLICK,
        asset_name: ANALYTICS_ASSETS.SEE_ALL,
        package_id: packageId,
    };
    const { scrollLeft = 0, clientWidth = 0, scrollWidth = 0 } = carouselElementRef && carouselElementRef.current ? carouselElementRef.current : {};
    const showNext = scrollLeft < (scrollWidth - clientWidth) || (scrollLeft === 0 && tileElements.length > 7);
    return (
        <section
            ref={ref}
            className=" component-top-padding m-b-30 "
            id="atm_43-logo-container"
            data-id={`${ELEMENT_ID.RAIL_ID}-${railIndex}`}
            name={railTitle}
        >
            {/* to be reviewed */}
            <section className="title title-with-cta d-flex justify-content-between align-items-center watch-list-align home-page-font heading-side-padding landscape-side-space">
                {railCtaActionUrl
                    ? (
                        <AnalyticsLinkComponent
                            to={railCtaActionUrl}
                            meta={moreAnalyticsMeta}
                            title={railTitle}
                            className="home-page-font text-bold-20 mobile-text-bold-16"
                            // onClick={() => railCtaClickGaEvent(railCtaText)}
                        >
                            {`${railTitle || String.fromCharCode(160)}`}
                        </AnalyticsLinkComponent>
                    ) : (
                        <h2>
                            {` ${railTitle}`}
                        </h2>
                    )}
                <section className={showArrows ? 'd-flex justify-content-between align-items-center' : 'd-none'}>
                    <section className="carousel-arrows">
                        <span className="button-ripple">
                            { /* eslint-disable */}
                            <AnalyticsButtonComponent
                             type="button" className="button button-link" onClick={previous} aria-label="Scroll Rail left">
                                { /* eslint-enable */}
                                <i className="icon20 icon-carousel-prev-arrow" />
                            </AnalyticsButtonComponent>
                        </span>

                        <span className="button-ripple">
                            { /* eslint-disable */}
                            <AnalyticsButtonComponent type="button" className="button button-link" onClick={next} aria-label="Scroll Rail Right">
                                { /* eslint-enable */}
                                <i className="icon20 icon-carousel-arrow icon-carousel-next-arrow" />
                            </AnalyticsButtonComponent>
                        </span>
                    </section>
                    {railCtaActionUrl
                        ? (
                            <AnalyticsLinkComponent
                                to={railCtaActionUrl}
                                className="cta cta--link cta--link--small cta--link--theme cta-link--vdo-hasMoreButton d-inline-block"
                                meta={moreAnalyticsMeta}
                                onClick={visibleWithClickEvent}
                                title={railCtaText}
                            >
                                {railCtaText}
                            </AnalyticsLinkComponent>
                        ) : null}
                </section>
            </section>
            <section className="pos-relative buttons-external-holder height-fill">

                { !isMobile() && scrollLeft > 0 && (
                    <button
                        className="slide-btn-cdp live-rail-btn left"
                        onClick={previous}
                    >
                        <ThumborImage
                            src="/static/cdp-assets/chev-sside.svg"
                        />
                    </button>
                ) }
                <section
                    className="cards-row m95-p95"
                    ref={carouselElementRef}
                    onScroll={onRailScroll}
                >

                    <section className="cards-row__inner">
                        {lazyTiles}
                    </section>

                </section>
                { !isMobile() && showNext && (
                    <button
                        className="slide-btn-cdp live-rail-btn right"
                        onClick={() => next(tileElements.length)}
                    >
                        <ThumborImage
                            src="/static/cdp-assets/chev-sside.svg"
                        />
                    </button>
                ) }
            </section>
        </section>
    );
});

TvShowLogo43RailComponent.defaultProps = {
    railCtaActionUrl: '',
    railIndex: 0,
    railId: '',
    packageId: '',
    titleType: '',
    maxTileTitleLines: 0,
};

TvShowLogo43RailComponent.propTypes = {
    tiles: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    railTitle: PropTypes.string.isRequired,
    railCtaText: PropTypes.string.isRequired,
    railCtaActionUrl: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    railIndex: PropTypes.number,
    railId: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    deviceUtil: PropTypes.object.isRequired,
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    tileType: PropTypes.string.isRequired,
    onRailScroll: PropTypes.func.isRequired,
    visibleWithClickEvent: PropTypes.func.isRequired,
    packageId: PropTypes.string,
    titleType: PropTypes.string,
    maxTileTitleLines: PropTypes.number,
};

export default withDeviceUtil(RailEventHOC(TvShowLogo43RailComponent));
