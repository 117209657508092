const ChannelScheduleAction = {
    FETCH_CHANNEL_SCHEDULE: 'FETCH_CHANNEL_SCHEDULE',
    FETCH_CHANNEL_SCHEDULE_SUCCESS: 'FETCH_CHANNEL_SCHEDULE_SUCCESS',
    FETCH_FULL_CHANNEL_SCHEDULE: 'FETCH_FULL_CHANNEL_SCHEDULE',
    FETCH_FULL_CHANNEL_SCHEDULE_SUCCESS: 'FETCH_FULL_CHANNEL_SCHEDULE_SUCCESS',
};

export const fetchChannelSchedule = payload => ({
    type: ChannelScheduleAction.FETCH_CHANNEL_SCHEDULE,
    ...payload,
});

export const fetchChannelScheduleSuccess = payload => ({
    type: ChannelScheduleAction.FETCH_CHANNEL_SCHEDULE_SUCCESS,
    ...payload,
});

export const fetchFullChannelSchedule = payload => ({
    type: ChannelScheduleAction.FETCH_FULL_CHANNEL_SCHEDULE,
    ...payload,
});

export const fetchFullChannelScheduleSuccess = payload => ({
    type: ChannelScheduleAction.FETCH_FULL_CHANNEL_SCHEDULE_SUCCESS,
    ...payload,
});

export default ChannelScheduleAction;
