import RailActions from '../actions/RailActions';

const initialState = JSON.parse('{}');

const PackageReducer = (state = initialState, action = {}) => {
    let changes = {};
    switch (action.type) {
        case RailActions.RAIL_FETCH_COMPLETED:
            changes = {
                ...action.payload,
            };
            break;

        case RailActions.RAIL_FETCH_ERROR: {
            const { error } = action.payload;

            changes = {
                ...state,
                error,
            };
        }
            break;

        case RailActions.RAIL_FETCH_PARTIAL_SUCCESS:
            changes = {
                ...action.payload,
            };
            break;

        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default PackageReducer;
