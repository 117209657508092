import { PropTypes } from 'prop-types';
import React from 'react';
import { LOCAL_TILE_TYPES, TILE_TYPES } from '@airtel-tv/constants/LayoutConstants';
import TabContainer from '@airtel-feature/content-details/content-detail-page/container/TabContainer';
import PillTileComponent from '@airtel-tv/ui-lib/tiles/PillTileComponent';
import PartnerChannelTileComponent from '../../shared/components/tile/PartnerChannelTileComponent';
import { LIST_TYPE_CLASS } from '../../../constants/AppConst';

// import TvShowLogo43TileComponent from '../../shared/components/tile/TvShowLogo43TileComponent';
import TvShowBig43TileComponent from '../../shared/components/tile/TvShowBig43TileComponent';
import BannerTileComponent from '../../shared/components/tile/BannerTileComponent';
// import MovieNoLogoTileComponent from '../../shared/components/tile/MovieNoLogoTileComponent';

import ExploreTileComponent from '../../shared/components/tile/ExploreTileComponent';

import ChannelTileContainer from '../containers/ChannelTileContainer';

import {
    LazyFooterTileComponent,
    LazyHeaderTileComponent,
    LazyContinueWatchingTileComponent,
} from '../../../utilities/code-splitting/LazyTiles';
import ChannelScheduleTileComponent from '../../shared/components/tile/ChannelScheduleTileComponent';
import CatchupEpisodeTileComponent from '../../shared/components/tile/CatchupEpisodeTileComponent';
import LiveTvShowTileComponent from '../../shared/components/tile/LiveTvShowTileComponent';
import LandscapeCardTileGridComponent from '../../shared/components/tile/LandscapeCardTileGridComponent';
import TrendingCardTileComponent from '../../shared/components/tile/TrendingCardTileComponent';
import LandscapeCardTileGridComponentSmall from '../../shared/components/tile/LandscapeCardTileGridComponentSmall';
import ChannelCardTileGridComponent from '../../shared/components/tile/ChannelCardTileGridComponent';
import TvShowDefault169TileComponent from '../../shared/components/tile/TvShowDefault169TileComponent';
import RelatedContentTileComponent from '../../shared/components/tile/RelatedContentTile';


// import NativeBannerAdTileComponent from '../../shared/components/tile/NativeBannerAdTileComponent';

import ChannelLogoTileComponent from '../../shared/components/tile/ChannelLogoTileComponent';
import PeopleTileComponent from '../../shared/components/tile/PeopleTile';
import TrailerTileComponent from '../../shared/components/tile/TrailerTileComponent';
import PYWTileComponent from '../../shared/components/tile/PYWTileComponent';
import SubscriptionTile from '../../shared/components/tile/SubscriptionTile';
import ActivePlanTile from '@airtel-feature/onehub/components/ActivePlanTile';
import SubscriptionActivationTile from '@airtel-feature/onehub/components/SubscriptionActivationTile';

export const getTile = ({ tileProps, onRemoveIconClick }) => {
    switch (tileProps.tileType) {
        case TILE_TYPES.TVSHOW_LOGO_43:
            return (
                <RelatedContentTileComponent
                    {...tileProps}
                    tileClass="custom-landscape-size"
                    useImagesFromProps
                />
            );
        case TILE_TYPES.DEFAULT_169:
            return (
                <RelatedContentTileComponent
                    {...tileProps}
                    useImagesFromProps
                    tileClass="custom-landscape-size"
                />
            );

            // return <TvShowDefault169TileComponent {...tileProps} />;
        case TILE_TYPES.RELATED_CONTENT_RAIL:
            return (
                <RelatedContentTileComponent
                    {...tileProps}
                    clearCurrentCdp
                />
            );

        case TILE_TYPES.TVSHOW_BIG_43:
        case TILE_TYPES.TVSHOW_NOLOGO_169:
            return (
                <RelatedContentTileComponent
                    {...tileProps}
                    useImagesFromProps
                    tileClass="custom-landscape-size"
                />
            );

            // TODO: BELOW LINE IS FOR Missed Episodes
            // return <EpisodesCardsTileComponent {...tileProps} />
        case TILE_TYPES.X_MASTER_RAIL:
        case TILE_TYPES.MOVIE_NOLOGO:
        case TILE_TYPES.MOVIE_LOGO:
        case TILE_TYPES.X_HIGHLIGHTED_PORTRAIT:
            return <RelatedContentTileComponent {...tileProps} />;
        case TILE_TYPES.X_INFINITY_BANNER:
        case TILE_TYPES.BANNER:
            return <BannerTileComponent {...tileProps} />;

        case TILE_TYPES.CHANNEL:
        case TILE_TYPES.CHANNEL_LIST:
            return <ChannelTileContainer {...tileProps} />;

        case TILE_TYPES.HEADER:
            return <LazyHeaderTileComponent {...tileProps} />;

        case TILE_TYPES.LIVE_TV_SHOW:
            return <LiveTvShowTileComponent {...tileProps} />;

        case TILE_TYPES.FOOTER:
            return <LazyFooterTileComponent />;

        case TILE_TYPES.CUSTOM:
        case LOCAL_TILE_TYPES.CUSTOM_EXPLORE:
            return <ExploreTileComponent {...tileProps} />;

        case TILE_TYPES.PARALLAX:
            return <ChannelLogoTileComponent {...tileProps} />;

            // case TILE_TYPES.NATIVE_BANNER_AD:
            //     return <NativeBannerAdTileComponent {...tileProps} />;

        case LOCAL_TILE_TYPES.PORTRAIT_SMALL:
            return (
                <RelatedContentTileComponent
                    {...tileProps}
                    propertyClassName={LIST_TYPE_CLASS.ARTIST}
                />
            );

        case LOCAL_TILE_TYPES.PORTRAIT_LARGE:
            return (
                <RelatedContentTileComponent
                    {...tileProps}
                    nativeRedirect
                    propertyClassName={LIST_TYPE_CLASS.RELATED_CONTENT}
                />
            );

        case LOCAL_TILE_TYPES.LANDSCAPE_CARD_GRID:
            return (
                <RelatedContentTileComponent
                    {...tileProps}
                    tileClass="custom-landscape-size"
                    useImagesFromProps
                />
            );

        case LOCAL_TILE_TYPES.CHANNEL_CARD_TILE_GRID:
            return <ChannelCardTileGridComponent {...tileProps} />;

        case LOCAL_TILE_TYPES.LANDSCAPE_CARD_TILE_GRID:
            return <TvShowBig43TileComponent {...tileProps} />;

        case LOCAL_TILE_TYPES.CATCHUP_EPISODE_TILE:
            return <CatchupEpisodeTileComponent {...tileProps} />;
        case LOCAL_TILE_TYPES.WATCHLIST:
        case LOCAL_TILE_TYPES.CONTINUE_WATCHING_TILE:
        case LOCAL_TILE_TYPES.X_PYW_RAIL:
            return (
                <LazyContinueWatchingTileComponent
                    {...tileProps}
                    onRemoveIconClick={onRemoveIconClick}
                />
            );

        case LOCAL_TILE_TYPES.CHANNEL_SCHEDULE:
            return <ChannelScheduleTileComponent {...tileProps} />;
        case LOCAL_TILE_TYPES.TRENDING_CARD:
            return <TrendingCardTileComponent {...tileProps} />;
        case TILE_TYPES.CONTENT_PARTNER_RAIL:
        case LOCAL_TILE_TYPES.CHANNEL_PARTNER_RAIL:
        case TILE_TYPES.X_CONTENT_PARTNER_RAIL_V2:
        case TILE_TYPES.X_PARTNER_LANDSCAPE_RAIL:
            return <PartnerChannelTileComponent {...tileProps} />;
        case TILE_TYPES.MULTILIST_RAIL:
            return <TabContainer {...tileProps} />;
        case TILE_TYPES.PEOPLE_RAIL:
            return <PeopleTileComponent {...tileProps} />;
        case TILE_TYPES.TRAILERS_RAIL:
            return <TrailerTileComponent {...tileProps} />;
        case TILE_TYPES.X_PILLS_RAIL:
            return <PillTileComponent tileProps={tileProps} />;
        case TILE_TYPES.X_EXPLORE_V2:
        case TILE_TYPES.X_FREE_CHANNELS:
            return (
                <RelatedContentTileComponent
                    {...tileProps}
                    tileClass="square-tile"
                    hidePlayIcon
                    contentDetails={{
                        shouldAddInWL: false,
                        ...tileProps.contentDetails,
                    }}
                    nativeRedirect
                />
            );
        case LOCAL_TILE_TYPES.LOCAL_TILE:
            return (
                <RelatedContentTileComponent
                    {...tileProps}
                    tileClass="square-tile"
                    useImagesFromProps={false}
                    hidePlayIcon
                    contentDetails={{
                        shouldAddInWL: false,
                        ...tileProps.contentDetails,
                    }}
                    nativeRedirect
                />
            );
        case TILE_TYPES.X_VERTICAL_TRAILER_RAIL:
            return (
                <PYWTileComponent
                    {...tileProps}
                />
            );
        case TILE_TYPES.X_PLAN_LISTING_RAIL:
            return (
                <SubscriptionTile
                    tileProps={tileProps}
                />
            );
        case TILE_TYPES.X_ACTIVE_PLAN:
            return (
                <ActivePlanTile
                    tileProps={tileProps}
                />
            );
        case TILE_TYPES.X_PARTNER_ACTIVATION_RAIL:
            return (
                <SubscriptionActivationTile
                    tileProps={tileProps}
                />
            );
        default:
            return null;
    }
};

getTile.propTypes = {
    tileProps: PropTypes.shape({
        tileType: PropTypes.string.isRequired,
    }).isRequired,
    onRemoveIconClick: PropTypes.func.isRequired,
};

export default getTile;
