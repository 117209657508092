import lodashGet from 'lodash/get';
import { trimCaptionsForUrl } from '../../../utilities/CommonUtil';

export const getPackIdsFromLayout = (LayoutData) => {
    if (!LayoutData || (LayoutData && LayoutData.length < 1)) {
        return [];
    }
    const packageIds = LayoutData.map((pack) => {
        const contents = lodashGet(pack, [
            'contents',
        ], []);
        let id;

        if (contents.length > 0) {
            id = lodashGet(contents[0], [
                'packageId',
            ], undefined);
        }

        return id;
    }).filter(id => !!id);

    return packageIds;
};

export const getPageIdFromPageTitle = (pageTitle, appConfig) => {
    const trimmedPageTitle = trimCaptionsForUrl(pageTitle);
    return appConfig.pageIdTitleMap[trimmedPageTitle];
};

export const getPackageContentByLayout = (layoutPackages, packageContents) => {
    const packages = {};
    // fetch layout content : if layout is present
    if (layoutPackages && layoutPackages.length > 0) {
        // filter pakage contnet by layout
        const packageIds = getPackIdsFromLayout(layoutPackages);

        if (packageContents && Object.keys(packageContents).length > 0) {
            packageIds.forEach((packId) => {
                packages[packId] = packageContents[packId];
            });
        }
    }

    return packages;
};

export const getPackageContentFromRailList = (railList) => {
    const packages = {};
    railList.forEach((rail) => {
        const packageId = lodashGet(rail, 'contents[0].packageId', '');
        const packageData = lodashGet(rail, 'contents[0].details', null);
        if (packageId && packageData) {
            packages[packageId] = packageData;
        }
    });

    return packages;
};

export const getAdConfigContentFromRail = (railList) => {
    if (!railList || !Object.keys(railList).length) {
        return {};
    }
    const packages = railList?.[0]?.zionDisplay;
    let { adConfig: adConfigStr = '' } = packages || {};
    if (adConfigStr) {
        try {
            adConfigStr = JSON.parse(adConfigStr);
        }
        catch (err) {
            adConfigStr = {};
        }
    }
    else {
        adConfigStr = {};
    }
    return adConfigStr;
};

export default {
    getPackIdsFromLayout,
    getPackageContentByLayout,
    getPackageContentFromRailList,
    getAdConfigContentFromRail
};
