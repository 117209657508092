import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import DimensionPageContainer from '../../dimension-pages/container/DimensionPageContainer';
import ListingContainer from './ListingContainer';
import DistroTvListingContainer from './DistroTvListingContainer';
import { matchPathname } from '@airtel-tv/utils/GlobalUtil';
import { _ROUTE_PATHS } from '@airtel-tv/constants/RouteConsts';
import DEVICES from '@airtel-tv/constants/DevicesConst';

const ListingContainerWrapper = (props) => {
    const { match = {}, dimensionUrlPackageIdMap, location: { pathname = '' } } = props;
    const { params: { pageTitle } = {} } = match;

    let packageId;

    if (pageTitle && dimensionUrlPackageIdMap && pathname) {
        packageId = dimensionUrlPackageIdMap[pathname] || null;
    }

    const distroChannelPath = _ROUTE_PATHS[DEVICES.BROWSER].DISTRO_CHANNELS_LIST;
    if (matchPathname(distroChannelPath)) {
        return <DistroTvListingContainer {...props} />;
    }

    if (pageTitle && !packageId) {
        return <DimensionPageContainer {...props} />;
    }
    return (
        <ListingContainer {...props} />
    );
};

ListingContainerWrapper.defaultProps = {
    match: {},
    dimensionUrlPackageIdMap: {},
    location: {},
};

ListingContainerWrapper.propTypes = {
    match: PropTypes.object,
    dimensionUrlPackageIdMap: PropTypes.object,
    location: PropTypes.object,
};


const mapStateToProps = (state) => {
    const { appConfig } = state;
    const { dimensionUrlPackageIdMap = {} } = appConfig;

    return {
        dimensionUrlPackageIdMap,
    };
};

export default connect(
    mapStateToProps,
)(withRouter(ListingContainerWrapper));
