
import { RoutingUtil, LocationUtil } from '@airtel-tv/utils';
import { TILE_CTA_LIST, RAIL_CTA_LIST } from '@airtel-tv/constants/LayoutConstants';
import { PROGRAM_TYPES } from '../../../constants/AppConst';
import { getSeasonTitle } from '../../../utilities/CommonUtil';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';

export const getRailCtaUrl = ({
    cta = null,
    title,
    packageId,
    railId,
    category,
    query,
    pageUrlName,
    channelName,
    channelId,
    titleL2,
}) => {
    switch (cta) {
        case RAIL_CTA_LIST.SEARCH:
            return RoutingUtil.getSearchListingUrl({
                category,
                query,
                title,
            });
        case RAIL_CTA_LIST.SCHEDULE:
            return RoutingUtil.getSchedulePageUrl({
                channelName,
                channelId,
            });
        default:
            if (!packageId) {
                return null;
            }
            return RoutingUtil.getPackageListingUrlV2({
                title,
                packageId,
                pageUrlName,
                railId,
                titleL2,
            });
    }
};

export const getTileCtaUrl = ({
    cta = null,
    contentId,
    title,
    category,
    genre,
    lang,
    channelName,
    episodeNumber = '',
    airDate = '',
    tvShowId = '',
    episodeSeasonNum = 1,
    tvShowName = '',
    cpTitle = '',
    pageLink = '',
    cpId = '',
    showPlayback = false,
}) => {
    const explicitCategory = category;

    if (TILE_CTA_LIST.SEARCH === cta) {
        return RoutingUtil.getSearchListingUrl({
            category,
            genre,
            lang,
            title,
        });
    }

    switch (category) {
        case PROGRAM_TYPES.LIVETVSHOW:
        case PROGRAM_TYPES.LIVETVMOVIE:
            return RoutingUtil.getLiveTvShowUrl({
                programType: category,
                channelName,
                showName: title,
                contentId,
            });

        case PROGRAM_TYPES.CATCHUP_EPISODE:
            return RoutingUtil.getLiveEpisodeShowUrl({
                channelName,
                showName: title,
                episodeReleaseDT: airDate,
                episodeNumber,
                contentId,
                tvShowId,
            });
        case PROGRAM_TYPES.PACKAGE:
            if (cta === TILE_CTA_LIST.PARTNER_CHANNEL || cpId) {
                return RoutingUtil.getChannelPartnerPageUrl({
                    programType: category,
                    channelPartnerName: cpTitle,
                    pageLink,
                });
            }
            return RoutingUtil.getPackageListingUrlV2({
                programType: category,
                title,
                packageId: contentId,
            });
        case PROGRAM_TYPES.EPISODE:
            if (showPlayback) {
                return `${RoutingUtil.getTvEpisodeUrlWeb({
                    programType: category,
                    title: tvShowName,
                    episodeNumber,
                    episodeName: title,
                    seasonName: getSeasonTitle(episodeSeasonNum),
                    contentId,
                })}?${LocationUtil.objectToQueryParams({
                    showPlayback: true,
                    ...(DeviceUtil.getDeviceUtil()?.isScopedWebview() ? { viewType: 'scoped_webview' } : {}),
                })}`;
            }
            return RoutingUtil.getContentPlaybackUrlWeb({
                contentId,
                category: explicitCategory,
                contentTitle: title,
            });
        default:
            return RoutingUtil.getContentPlaybackUrlWeb({
                contentId,
                category: explicitCategory,
                contentTitle: title,
            });
    }
};
