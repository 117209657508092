import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { getTile } from '../factories/TileFactory';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { MAX_TILE_LIMIT_MWEB, MAX_TILE_LIMIT_WEB } from '@airtel-tv/constants/LayoutConstants';
import { getCurrentWindowSourceName } from '../../../utilities/WindowUtil';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
// import { railCtaClickGaEvent } from '../../../utilities/GaEvents';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import HorizontalLazyLoaderHOC from '../../shared/hoc/HorizontalLazyLoaderHOC';
import { findTileLimit } from '../../../utilities/CommonUtil';
import RailEventHOC from '../../shared/hoc/RailEventHOC';
import { ELEMENT_ID } from '../../../constants/AppConst';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';

const SmallerLandscapeSliderRailComponent = React.forwardRef((props, ref) => {
    const [
        hoveredTile,
        setHoveredTile,
    ] = useState('');
    const {
        deviceUtil, tiles, railTitle, railCtaText, railCtaActionUrl, railIndex, railId, imageSettings, showMore, tileType, onRailScroll,
        visibleWithClickEvent, packageId, isSearchEvent = false,
    } = props;
    // const [
    //     isDown,
    //     setIsDown,
    // ] = useState(false);
    const isBot = deviceUtil.isBot();
    const isMobile = deviceUtil.isMobile();
    const windowWidth = deviceUtil.getWindowWidth();
    const tileLimit = findTileLimit({
        windowWidth,
        tileType,
    });
    // const [
    //     dragScroll,
    // ] = useState({
    //     startX: null,
    //     scrollLeft: null,
    // });
    const carouselElementRef = React.createRef();

    // function handleMouseDown(e) {
    //     setIsDown(true);
    //     carouselElementRef.current.classList.add('active-grab');
    //     dragScroll.startX = e.pageX - carouselElementRef.current.offsetLeft;
    //     // eslint-disable-next-line prefer-destructuring
    //     dragScroll.scrollLeft = carouselElementRef.current.scrollLeft;
    // }

    // function handleMouseLeave() {
    //     setIsDown(false);
    //     carouselElementRef.current.classList.remove('active-grab');
    // }

    // function handleMouseUp() {
    //     setIsDown(false);
    //     carouselElementRef.current.classList.remove('active-grab');
    // }

    // function handleMouseMove(e) {
    //     if (!isDown) {
    //         return;
    //     }
    //     e.preventDefault();
    //     const x = e.pageX - carouselElementRef.current.offsetLeft;
    //     const walk = (x - dragScroll.startX) * 3; // scroll-fast
    //     carouselElementRef.current.scrollLeft = dragScroll.scrollLeft - walk;
    // }

    function next() {
        if (carouselElementRef.current) {
            const { clientWidth } = carouselElementRef.current;
            carouselElementRef.current.scrollLeft += clientWidth / 2;
        }
        visibleWithClickEvent();
    }

    function previous() {
        if (carouselElementRef.current) {
            const { clientWidth } = carouselElementRef.current;
            carouselElementRef.current.scrollLeft -= clientWidth / 2;
        }
        visibleWithClickEvent();
    }

    let tileElements = [];
    let lazyTiles = [];

    if (tiles && Array.isArray(tiles) && tiles.length < 1) {
        return null;
    }
    let tilesToShow = tiles;
    if (railCtaActionUrl) {
        tilesToShow = (isMobile && !isBot) ? tiles.slice(0, MAX_TILE_LIMIT_MWEB.CHANNEL) : tiles.slice(0, MAX_TILE_LIMIT_WEB.CHANNEL);
    }
    if (tiles && Array.isArray(tilesToShow)) {
        tileElements = tilesToShow.map((tile, i) => {
            const tileProps = {
                key: `${tile.id}-${i}`,
                ...tile,
                railCtaActionUrl,
                railPosition: railIndex,
                tilePosition: i,
                railId,
                imageSettings,
                railTitle,
                hoveredTile,
                setHoveredTile,
                packageId,
                isSearchEvent,
            };

            const tileElement = getTile({
                tileProps,
            });

            return tileElement;
        });
        const key = `${railId}-${railIndex}`;
        lazyTiles = (
            <HorizontalLazyLoaderHOC
                tileElements={tileElements}
                tileLimit={tileLimit}
                railKey={key}
            />
        );
    }
    // check if there is an error
    else if (typeof tilesToShow === 'object') {
        // TODO: Handle error
    }

    const showArrows = (tileElements && tileElements.length > tileLimit - 1);
    const moreAnalyticsMeta = {
        rail_id: railId,
        // action: ANALYTICS_ACTIONS.MORE_CLICK,
        // source_name: getCurrentWindowSourceName(),
        action: ANALYTICS_ACTIONS.RAIL_CTA_CLICK,
        asset_name: ANALYTICS_ASSETS.SEE_ALL,
        package_id: packageId,
    };
    return (
        <div
            ref={ref}
            className="channelRails landscape-side-space heading-side-padding m-b-30"
            id="atm_small-landscape-container"
            data-id={`${ELEMENT_ID.RAIL_ID}-${railIndex}`}
            name={railTitle}
        >
            <div className="title title-with-cta d-flex justify-content-between align-items-center watch-list-align">
                {railCtaActionUrl
                    ? (
                        <AnalyticsLinkComponent
                            to={railCtaActionUrl}
                            className="cta cta--link cta--link--medium cta--link--theme d-inline-block home-page-font"
                            meta={moreAnalyticsMeta}
                            onClick={visibleWithClickEvent}
                            title={railTitle}
                        >
                            {`${railTitle}`}
                        </AnalyticsLinkComponent>
                    ) : <h2 className="home-page-font text-bold-20 mobile-text-bold-16">{`${railTitle}`}</h2>}

                <div className={(showArrows || showMore) ? 'd-flex justify-content-between align-items-center' : 'd-none'}>
                    <div className={!showArrows ? 'd-none' : 'carousel-arrows'}>
                        <span className="button-ripple">
                            { /* eslint-disable */}
                            <AnalyticsButtonComponent type="button" className="button button-link" onClick={previous} aria-label="Scroll Rail Left">
                                { /* eslint-enable */}
                                <i className="icon20 icon-carousel-prev-arrow" />
                            </AnalyticsButtonComponent>
                        </span>

                        <span className="button-ripple">
                            { /* eslint-disable */}
                            <AnalyticsButtonComponent type="button" className="button button-link" onClick={next} aria-label="Scroll Rail Right">
                                { /* eslint-enable */}
                                <i className="icon20 icon-carousel-arrow icon-carousel-next-arrow" />
                            </AnalyticsButtonComponent>
                        </span>
                    </div>
                    {railCtaActionUrl
                        ? (
                            <AnalyticsLinkComponent
                                to={railCtaActionUrl}
                                className="cta cta--link cta--link--small cta--link--theme cta-link--vdo-hasMoreButton d-inline-block"
                                meta={moreAnalyticsMeta}
                                onClick={visibleWithClickEvent}
                                title={railCtaText}
                            >
                                {railCtaText}
                            </AnalyticsLinkComponent>
                        ) : null}
                </div>

            </div>

            {/* <div className="cards-row" ref={carouselElementRef} onMouseDown={handleMouseDown} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} draggable="false" role="carousel"> */}

            {/* eslint-disable-next-line */}
            <div className="cards-row" ref={carouselElementRef} draggable="false" role="carousel" onScroll={onRailScroll}>
                <div className="cards-row__inner">
                    {lazyTiles}
                </div>
            </div>
        </div>
    );
});

SmallerLandscapeSliderRailComponent.defaultProps = {
    railIndex: 0,
    railId: '',
    railCtaActionUrl: '',
    packageId: '',
};

SmallerLandscapeSliderRailComponent.propTypes = {
    tiles: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    railTitle: PropTypes.string.isRequired,
    railCtaText: PropTypes.string.isRequired,
    railCtaActionUrl: PropTypes.string,
    railIndex: PropTypes.number,
    railId: PropTypes.string,
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showMore: PropTypes.bool.isRequired,
    tileType: PropTypes.string.isRequired,
    onRailScroll: PropTypes.func.isRequired,
    visibleWithClickEvent: PropTypes.func.isRequired,
    packageId: PropTypes.string,
};

export default withDeviceUtil(React.memo(RailEventHOC(SmallerLandscapeSliderRailComponent)));
