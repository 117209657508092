import React, { lazy, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import AnalyticsLinkComponent from '@airtel-tv/analytics/AnalyticsLinkComponent';
// import { getCurrentWindowSourceName } from '../../../utilities/WindowUtil';
import {
    LOCAL_PACKAGE_NAME, RAIL_TYPES, RAIL_CTA_LIST, LOCAL_TILE_TYPES,
} from '@airtel-tv/constants/LayoutConstants';
// import { railCtaClickGaEvent } from '../../../utilities/GaEvents';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { syncContentRemove, watchListContentRemove } from '@airtel-tv/sync/SyncActions';
import { buildTile } from '@airtel-feature/layout/builders/TileBuilder';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { ARIA_LABEL, ELEMENT_ID, PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import { findTileLimit } from '../../../utilities/CommonUtil';
import HorizontalLazyLoaderHOC from '../../shared/hoc/HorizontalLazyLoaderHOC';
import { getTile } from '../factories/TileFactory';

class ContinueWatchingRailContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;

        this.isMobile = this.deviceUtil.isMobile();
        this.carouselElementRef = React.createRef();
        this.state = {
            tiles: [],
            indexOfRail: -1,
            scrollLeft: 0,
            clientWidth: 0,
            scrollWidth: 0,
            hoveredTile: '',
        };
        const windowWidth = this.deviceUtil.getWindowWidth();
        const { tileType } = props;
        this.tileLimit = findTileLimit({
            windowWidth,
            tileType,
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            continueWatchingData,
            continueWatchingRail,
            cpDetailsById,
            subscriptionDataById,
            serverEpoch,
        } = nextProps;

        if (!prevState || continueWatchingData !== prevState.continueWatchingData) {
            return {
                continueWatchingData,
                tiles: ContinueWatchingRailContainer.getComputedTiles({
                    continueWatchingData,
                    continueWatchingRail,
                    cpDetailsById,
                    subscriptionDataById,
                    serverEpoch,
                }),
            };
        }

        return null;
    }

    static getComputedTiles({
        continueWatchingData, continueWatchingRail, cpDetailsById, subscriptionDataById, serverEpoch,
    }) {
        const railType = RAIL_TYPES.CONTINUE_WATCHING_RAIL_V2;
        const tileCtaType = RAIL_CTA_LIST.LISTING;
        let tiles = null;
        let content = [];
        const { length } = Object.keys(continueWatchingRail);
        content = Object.keys(continueWatchingRail).map((continueWatchingContentId) => {
            const { contentResponse, lastUpdatedTimeStamp } = continueWatchingData[continueWatchingContentId];
            return {
                ...contentResponse,
                lastUpdatedTimeStamp,
            };
        });
        const currentTime = serverEpoch || new Date().getTime();
        content = content.filter(item => item.programType && item.id && (!item.expiryTime || item.expiryTime > currentTime));

        content.sort((a, b) => (b.lastUpdatedTimeStamp - a.lastUpdatedTimeStamp));
        tiles = content.map(item => buildTile({
            item,
            tileCtaType,
            railType,
            tileType: LOCAL_TILE_TYPES.CONTINUE_WATCHING_TILE,
            length,
            continueWatchingData,
            cpDetailsById,
            subscriptionDataById,
            isContinueWatchingRail: true,
        }));
        return tiles;
    }

    componentDidMount() {
        let indRail;
        if (this.carouselElementRef && this.carouselElementRef.current) {
            const { scrollLeft = 0, clientWidth = 0, scrollWidth = 0 } = this.carouselElementRef && this.carouselElementRef.current ? this.carouselElementRef.current : {};
            this.setState({
                scrollLeft,
                clientWidth,
                scrollWidth,
            });
            this.carouselElementRef.current.addEventListener('scroll', (e) => {
                if (this.carouselElementRef && this.carouselElementRef.current) {
                    indRail = Math.ceil(this.carouselElementRef.current.scrollLeft / 260);
                    this.setState({ indexOfRail: indRail });
                }
            });
        }
    }

    removeContinueWatchingContent = (contentDetails) => {
        const { syncContentRemoveDispatch } = this.props;
        syncContentRemoveDispatch(
            {
                contentDetails,
                lastWatchedPosition: -1,
                manuallyRemovedFromCW: true,
            },
        );
    };

    setHoveredTile = (hoveredTile) => {
        this.setState({
            hoveredTile,
        });
    };


    next = () => {
        if (this.carouselElementRef.current) {
            const { clientWidth } = this.carouselElementRef.current;
            this.carouselElementRef.current.scrollLeft += clientWidth;
        }
    };

    previous = () => {
        if (this.carouselElementRef.current) {
            const { clientWidth } = this.carouselElementRef.current;
            this.carouselElementRef.current.scrollLeft -= clientWidth;
        }
    };

    showNext = () => {
        const {
            indexOfRail, scrollLeft, clientWidth, scrollWidth, tiles,
        } = this.state;
        return (scrollLeft < (scrollWidth - clientWidth) || (indexOfRail < tiles.length - Math.floor(clientWidth / 275)));
    };

    render() {
        const {
            railTitle, railCtaActionUrl, railIndex, railId, imageSettings, showMore, packageId,
            totalRailLength, pageId
        } = this.props;
        const { tiles, hoveredTile } = this.state;
        let lazyTiles = [];
        let filteredTiles = tiles.filter(tile => !tile?.contentDetails?.shouldHideInCW && tile.contentDetails.programType !== PROGRAM_TYPES.TRAILER);
        if (filteredTiles && Array.isArray(filteredTiles) && filteredTiles.length < 1) {
            return null;
        }
        filteredTiles = filteredTiles.slice(0, totalRailLength);


        let showArrows = false;

        // const tilesToShow = this.isMobile ? tiles.slice(0, MAX_TILE_LIMIT_MWEB.LANDSCAPE) : tiles.slice(0, MAX_TILE_LIMIT_WEB.LANDSCAPE);
        if (filteredTiles && Array.isArray(filteredTiles)) {
            const tileElements = filteredTiles.map((tile, i) => {
                const tileProps = {
                    key: `${tile.id}-${railId}`,
                    ...tile,
                    railCtaActionUrl,
                    railPosition: railIndex,
                    tilePosition: i,
                    railId,
                    imageSettings,
                    hoveredTile,
                    setHoveredTile: this.setHoveredTile,
                    packageId,
                    pageId,
                };

                const tileElement = getTile({
                    tileProps,
                    onRemoveIconClick: this.removeContinueWatchingContent,
                });

                return tileElement;
            });
            const key = `${railId}-${railIndex}`;
            lazyTiles = (
                <HorizontalLazyLoaderHOC
                    tileElements={tileElements}
                    tileLimit={this.tileLimit}
                    railKey={key}
                />
            );
            showArrows = (tileElements && tileElements.length > this.tileLimit - 1);
        }
        // check if there is an error
        else if (typeof tilesToShow === 'object') {
            // TODO: Handle error
        }

        // const moreAnalyticsMeta = {
        //     rail_id: railId,
        //     action: 'more_click',
        //     source_name: getCurrentWindowSourceName(),
        // };
        const { indexOfRail } = this.state;
        const { SCROLL_RAIL_LEFT = 'Scroll Rail Left', SCROLL_RAIL_RIGHT = 'Scroll Rail Right' } = ARIA_LABEL;

        return (
            <section
                className="m-b-30 home-row margin-padding-0-mweb m-30-bottom"
                name={`${railTitle || LOCAL_PACKAGE_NAME.CONTINUE_WATCHING}`}
                id="atm_cw-container"
                data-id={`${ELEMENT_ID.RAIL_ID}-${railIndex}`}
            >
                {/* to be reviewed */}
                <div className="title-side-margin title title-with-cta d-flex justify-content-between align-items-center watch-list-align continue-Watching-row">
                    {/* {railCtaActionUrl
                        ? (
                            <AnalyticsLinkComponent
                                to={railCtaActionUrl}
                                className="cta cta--link cta--link--medium cta--link--theme cta-link--vdo-hasMoreButton d-inline-block"
                                meta={moreAnalyticsMeta}
                                // onClick={() => railCtaClickGaEvent(railCtaText)}
                            >
                                <h2 className="mb-0">{`${railTitle || LOCAL_PACKAGE_NAME.CONTINUE_WATCHING}`}</h2>
                            </AnalyticsLinkComponent>
                        ) : <h2 className="mb-0">{`${railTitle || LOCAL_PACKAGE_NAME.CONTINUE_WATCHING}`}</h2>} */}
                    {/*  When Continue Watching Rail page is built remove this */}
                    <h2
                        className="text-bold-20 mobile-text-bold-16 home-page-font  line-height-30"
                        name={`${railTitle || LOCAL_PACKAGE_NAME.CONTINUE_WATCHING}`}
                        id={ELEMENT_ID.CONTINUE_WATCHING_TEXT}
                    >
                        {`${railTitle || LOCAL_PACKAGE_NAME.CONTINUE_WATCHING}`}
                    </h2>

                    <div className={(showArrows || showMore) ? 'd-flex justify-content-between align-items-center' : 'd-none'}>
                        <div className={!showArrows ? 'd-none' : 'carousel-arrows'}>
                            <span className="button-ripple">
                                <AnalyticsButtonComponent
                                    type="button"
                                    className="button button-link"
                                    onClick={this.previous}
                                    aria-label={SCROLL_RAIL_LEFT}
                                >
                                    { /* eslint-enable */}
                                    <i className="icon20 icon-carousel-prev-arrow" />
                                </AnalyticsButtonComponent>
                            </span>

                            <span className="button-ripple">
                                <AnalyticsButtonComponent
                                    type="button"
                                    className="button button-link"
                                    onClick={this.next}
                                    aria-label={SCROLL_RAIL_RIGHT}
                                >
                                    { /* eslint-enable */}
                                    <i className="icon20 icon-carousel-arrow icon-carousel-next-arrow" />
                                </AnalyticsButtonComponent>
                            </span>
                        </div>

                        {/* {railCtaActionUrl
                            ? (
                                <AnalyticsLinkComponent
                                    to={railCtaActionUrl}
                                    className="cta cta--link cta--link--medium cta--link--theme cta-link--vdo-hasMoreButton d-inline-block"
                                    meta={moreAnalyticsMeta}
                                    // onClick={() => railCtaClickGaEvent(railCtaText)}
                                >
                                    {railCtaText}
                                </AnalyticsLinkComponent>
                            ) : null} */}
                    </div>
                </div>


                {/* <div className="cards-row" ref={carouselElementRef} onMouseDown={handleMouseDown} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} draggable="false" role="carousel"> */}
                <div
                    className="pos-relative buttons-external-holder height-fill small gradient-holder"
                    id={`${ELEMENT_ID.CONT_WATCHING_RAIL}`}
                >
                    { !this.isMobile && indexOfRail > 0 && (
                        <AnalyticsButtonComponent
                            className="slide-btn-cdp left landscape"
                            onClick={this.previous}
                            aria-label={SCROLL_RAIL_LEFT}
                        >
                            <ThumborImage
                                src="/static/cdp-assets/chev-sside.svg"
                            />
                        </AnalyticsButtonComponent>
                    ) }
                    <section
                        className="cards-row continue-row cw-only-padding "
                        ref={this.carouselElementRef}
                        draggable="false"
                        // eslint-disable-next-line jsx-a11y/aria-role
                        role="carousel"
                    >
                        <section
                            className="cards-row__inner"
                            id={railId}
                        >
                            {lazyTiles}
                        </section>
                    </section>
                    { !this.isMobile && this.showNext() && (
                        <AnalyticsButtonComponent
                            className="slide-btn-cdp right landscape"
                            onClick={() => this.next(lazyTiles.length)}
                            aria-label={SCROLL_RAIL_RIGHT}
                        >
                            <ThumborImage
                                src="/static/cdp-assets/chev-sside.svg"
                            />
                        </AnalyticsButtonComponent>
                    ) }
                </div>


            </section>
        );
    }
}

function mapStateToProps(state) {
    const {
        syncContent: {
            continueWatchingData,
            continueWatchingRail,
        },
        appConfig: { cpDetailsById, serverEpoch },
        userConfig: { subscriptionDataById },
    } = state;
    return {
        continueWatchingData,
        continueWatchingRail,
        cpDetailsById,
        subscriptionDataById,
        serverEpoch,
    };
}

ContinueWatchingRailContainer.defaultProps = {
    railIndex: 0,
    railId: '',
    railCtaActionUrl: '',
    showMore: false,
    packageId: '',
    serverEpoch: 0,
    totalRailLength: 100,
};

ContinueWatchingRailContainer.propTypes = {
    railTitle: PropTypes.string.isRequired,
    // railCtaText: PropTypes.string.isRequired,
    railCtaActionUrl: PropTypes.string,
    railIndex: PropTypes.number,
    railId: PropTypes.string,
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showMore: PropTypes.bool,
    continueWatchingData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    continueWatchingRail: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    syncContentRemoveDispatch: PropTypes.func.isRequired,
    tileType: PropTypes.string.isRequired,
    cpDetailsById: PropTypes.objectOf(PropTypes.object).isRequired,
    subscriptionDataById: PropTypes.objectOf(PropTypes.object).isRequired,
    packageId: PropTypes.string,
    serverEpoch: PropTypes.number,
    totalRailLength: PropTypes.number,
};

export default connect(mapStateToProps, {
    syncContentRemoveDispatch: syncContentRemove,
    watchListContentRemoveDispatch: watchListContentRemove,
})(withDeviceUtil((ContinueWatchingRailContainer)));
