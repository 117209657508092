/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { LOCAL_TILE_TYPES, RAIL_CTA_LIST } from '@airtel-tv/constants/LayoutConstants';
import { buildRail } from '@airtel-feature/layout/builders/RailBuilder';

import { getRail } from '../factories/RailFactory';
import { PROGRAM_TYPES } from '../../../constants/AppConst';
import { getCurrentProgramIndex } from '../../../utilities/CommonUtil';
import { fetchChannelSchedule } from '../actions/ChannelScheduleAction';

class ChannelScheduleContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
        this.carouselElementRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let changes = null;
        const {
            tiles,
            channelId,
            getCurrentChannelSchedule,
            currentProgramDetails,
        } = nextProps;

        if (tiles !== prevState.tiles
            || channelId !== prevState.channelId
            || getCurrentChannelSchedule !== prevState.getCurrentChannelSchedule
            || currentProgramDetails !== prevState.currentProgramDetails
        ) {
            changes = {
                tiles,
                channelId,
                getCurrentChannelSchedule,
                currentProgramDetails,
            };
        }
        return changes;
    }

    componentDidMount() {
        this.getCurrentChannelSchedule();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState;
    }

    componentDidUpdate(prevProps = {}) {
        const { currentProgramDetails: prevProgramDetail } = prevProps;
        const { currentProgramDetails } = this.state;
        if (prevProgramDetail.startTime !== currentProgramDetails.startTime) {
            this.getCurrentChannelSchedule(currentProgramDetails.startTime);
        }
    }

    getCurrentChannelSchedule = (startTimeStamp = null) => {
        const { fetchChannelScheduleDispatch, getCurrentChannelSchedule } = this.props;
        const { channelId } = this.state;
        if (getCurrentChannelSchedule.length && startTimeStamp) {
            const lastProgramIndex = getCurrentChannelSchedule.length;
            const lastProgramStartTime = lodashGet(getCurrentChannelSchedule[lastProgramIndex - 1], 'startTime', 0);
            const firstProgramStartTime = lodashGet(getCurrentChannelSchedule[0], 'startTime', 0);
            if (startTimeStamp >= firstProgramStartTime && startTimeStamp <= lastProgramStartTime) {
                return;
            }
        }
        else if (getCurrentChannelSchedule.length) {
            return;
        }
        fetchChannelScheduleDispatch({
            startTimeStamp,
            channelId,
        });
    }

    buildChannelScheduleRail = () => {
        const {
            channel, getCurrentChannelSchedule, channelId, railIndex,
        } = this.props;

        const currentProgramIndex = getCurrentProgramIndex(getCurrentChannelSchedule);
        const currentProgramTime = lodashGet(getCurrentChannelSchedule[currentProgramIndex], 'startTime', 0);
        const tilesToShow = getCurrentChannelSchedule.slice();
        const tileItems = tilesToShow.map(item => ({
            ...item,
            id: item.contentId || item.id,
            programType: PROGRAM_TYPES.LIVETVSHOW,
            hasCatchup: !!item.contentId,
            currentProgramTime,
        }));

        const railProps = buildRail({
            items: tileItems,
            explicitTileType: LOCAL_TILE_TYPES.CHANNEL_SCHEDULE,
            explicitRailCtaType: RAIL_CTA_LIST.SCHEDULE,
            explicitRailTitle: 'Full Schedule',
            more: false,
            channelId,
            channels: channel,
            railIndex,
        });

        return getRail({
            railType: railProps.railType,
            railProps: {
                ...railProps,
                currentProgramIndex,
                // railVisibleCallback: this.railVisible,
            },
        });
    }

    render() {
        const {
            getCurrentChannelSchedule,
        } = this.state;

        if (getCurrentChannelSchedule.length > 2) {
            const scheduleRail = this.buildChannelScheduleRail();
            return scheduleRail;
        }
        return null;
    }
}

ChannelScheduleContainer.defaultProps = {
    railIndex: 0,
};

ChannelScheduleContainer.propTypes = {
    tiles: PropTypes.array.isRequired,
    channelId: PropTypes.string.isRequired,
    getCurrentChannelSchedule: PropTypes.array.isRequired,
    currentProgramDetails: PropTypes.object.isRequired,
    channel: PropTypes.array.isRequired,
    fetchChannelScheduleDispatch: PropTypes.func.isRequired,
    railIndex: PropTypes.number,
};


const mapStateToProps = (state, props) => {
    const {
        channelSchedule: { channelGuide },
        channel,
    } = state;
    const { channelId } = props;
    const getCurrentChannelSchedule = channelGuide[channelId] || [];

    return {
        getCurrentChannelSchedule,
        channel: channel.channels,
    };
};


export default connect(mapStateToProps, {
    fetchChannelScheduleDispatch: fetchChannelSchedule,
})(ChannelScheduleContainer);
