import React from 'react';
import PropTypes from 'prop-types';
import { getTile } from '../factories/TileFactory';

const GridRoundSectionRailComponent = (props) => {
    const {
        tiles, railTitle, railCtaActionUrl, railIndex, railId, packageId,
    } = props;

    let tileElements = [];

    if (tiles && Array.isArray(tiles) && tiles.length < 1) {
        return null;
    }

    if (tiles && Array.isArray(tiles)) {
        tileElements = tiles.map((tile, i) => {
            const tileProps = {
                key: `${tile.id}-${i}`,
                ...tile,
                railCtaActionUrl,
                railPosition: railIndex,
                tilePosition: i,
                railId,
                packageId,
            };

            const tileElement = getTile({
                tileProps,
            });

            return tileElement;
        });
    }
    // check if there is an error
    else if (typeof tiles === 'object') {
        // TODO: Handle error
    }
    return (
        <div className="cards-rounded-wrapper component-top-padding">
            <div className="container-fluid p-r-0">
                <div className="title title-with-cta">
                    <h5 className="mb-0">
                        {`${railTitle}`}
                    </h5>
                </div>
                <div className="cards-rounded-list d-flex hideScrollBar">
                    {tileElements}
                </div>
            </div>

        </div>
    );
};

GridRoundSectionRailComponent.defaultProps = {
    railCtaActionUrl: '',
    railIndex: 0,
    railId: '',
    packageId: '',
};

GridRoundSectionRailComponent.propTypes = {
    tiles: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    railTitle: PropTypes.string.isRequired,
    railCtaActionUrl: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    railIndex: PropTypes.number,
    railId: PropTypes.string,
    packageId: PropTypes.string,
};

export default React.memo(GridRoundSectionRailComponent);
