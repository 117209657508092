import React from 'react';
// import PropTypes from 'prop-types';
import { getTile } from '../factories/TileFactory';

const FooterComponentRail = (tileProps) => {
    const tileElement = getTile({ tileProps });

    return (
        <div className="custom-border section-bottom-padding">
            {tileElement}
        </div>
    );
};

FooterComponentRail.defaultProps = {
};

FooterComponentRail.propTypes = {
};

export default React.memo(FooterComponentRail);
