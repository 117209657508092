import ChannelScheduleAction from '../actions/ChannelScheduleAction';

const initialState = JSON.parse(`{
    "channelGuide": {},
    "currentDaySchedule": {
        "channel": {},
        "timeStamp": 0,
        "fetching": false
    }
}`);
const ChannelScheduleReducer = (state = initialState, action = {}) => {
    let changes = {};
    switch (action.type) {
        case ChannelScheduleAction.FETCH_CHANNEL_SCHEDULE_SUCCESS:
            changes = {
                channelGuide: {
                    ...state.channelGuide,
                    ...action.payload,
                },
            };
            break;
        case ChannelScheduleAction.FETCH_FULL_CHANNEL_SCHEDULE:
            changes = {
                currentDaySchedule: {
                    ...state.currentDaySchedule,
                    fetching: true,
                },
            };
            break;
        case ChannelScheduleAction.FETCH_FULL_CHANNEL_SCHEDULE_SUCCESS:
            changes = {
                currentDaySchedule: {
                    ...action.payload,
                    fetching: false,
                },
            };
            break;
        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default ChannelScheduleReducer;
