import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRelatedContent } from '../actions/RelatedContentActions';
import { getRail } from '../factories/RailFactory';
import { LOCAL_TILE_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { buildRail } from '@airtel-feature/layout/builders/RailBuilder';

import { PROGRAM_TYPES } from '../../../constants/AppConst';
import { isItemObject } from '../../../utilities/CommonUtil';
import { fetchAllChannels } from '../actions/ChannelAction';

class ChannelRelatedContentContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            targetId: '',
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let changes = {};

        const {
            targetId,
            items,
            channels,
        } = nextProps;

        if (targetId !== prevState.targetId
            || items !== prevState.items
            || channels !== prevState.channels) {
            changes = {
                targetId,
                items,
                channels,
            };
        }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    componentDidMount() {
        this.fetchChannels();
        this.getRelatedContent();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState;
    }

    componentDidUpdate(prevProps = {}) {
        const { targetId } = this.props;
        this.getRelatedContent(prevProps);

        if (prevProps.targetId !== targetId) {
            this.fetchChannels();
        }
    }

    fetchChannels = () => {
        const { channels, fetchAllChannelsDispatch } = this.props;
        if (channels.length < 1) {
            fetchAllChannelsDispatch();
        }
    }

    getRelatedContent = (prevProps = {}) => {
        //  dispatch fetch rail content
        const {
            getRelatedContentDispatch,
            targetId,
            items,
        } = this.props;
        if (items.length > 0) {
            return;
        }
        if (prevProps.targetId !== targetId) {
            getRelatedContentDispatch({
                contentId: targetId,
                programTypes: `${PROGRAM_TYPES.LIVE},${PROGRAM_TYPES.LIVETVCHANNEL},${PROGRAM_TYPES.LIVETVMOVIE},${PROGRAM_TYPES.LIVETVSHOW}`,
            });
        }
    };

    getFilteredItem = (items, programType) => {
        if (programType && items && items.length) {
            return items.filter(i => i.programType === programType);
        }
        return items;
    }

    render() {
        const {
            items, displayTitle, programType, channels, railIndex,
        } = this.props;


        let tileType = null;

        switch (programType) {
            case PROGRAM_TYPES.LIVETVSHOW:
                // tileType = TILE_TYPES.TVSHOW_LOGO_43;
                tileType = LOCAL_TILE_TYPES.LANDSCAPE_CARD_GRID;
                break;

            default:
                tileType = LOCAL_TILE_TYPES.CHANNEL;
                break;
        }


        const railProps = buildRail({
            items: this.getFilteredItem(items, programType),
            explicitTileType: tileType,
            explicitRailTitle: displayTitle,
            more: false,
            channels,
            railIndex,
        });

        return getRail({
            railType: railProps.railType,
            railProps,
        });
    }
}

ChannelRelatedContentContainer.defaultProps = {
    items: [],
    displayTitle: '',
    railIndex: 0,
};

ChannelRelatedContentContainer.propTypes = {
    targetId: PropTypes.string.isRequired,
    getRelatedContentDispatch: PropTypes.func.isRequired,
    fetchAllChannelsDispatch: PropTypes.func.isRequired,
    items: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]), // eslint-disable-line react/forbid-prop-types
    programType: PropTypes.string.isRequired,
    displayTitle: PropTypes.string,
    channels: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    railIndex: PropTypes.number, // eslint-disable-line react/forbid-prop-types
};


const mapStateToProps = (state, props) => {
    const { relatedContents, channel } = state;
    const { targetId } = props;
    return {
        items: isItemObject(relatedContents[targetId]) ? relatedContents[targetId] : [],
        channels: channel.channels || [],
    };
};


export default connect(mapStateToProps, {
    getRelatedContentDispatch: getRelatedContent,
    fetchAllChannelsDispatch: fetchAllChannels,
})(ChannelRelatedContentContainer);
