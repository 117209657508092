import lodashGet from 'lodash/get';
import {
    call, put, select, getContext,
} from 'redux-saga/effects';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { getEpgContentApi } from '../../../service/end-points/EpgApiService';
import { EpgAction, fetchEpgChannelDetailsSuccess } from '../actions/EpgAction';
import { DEFAULT_CHANNEL_PRODUCT_ID } from '../../../constants/ContentProviderConst';
import { getStartTime, getEndTime, createMockEPG } from '../../../utilities/CommonUtil';
import { getChannelsFromReducer, getUserConfigFromReducer } from '@airtel-tv/redux/StoreListing';
import { fetchAllChannelsSuccess } from '../actions/ChannelAction';


function* fetchEpgContent({
    dtStamp, endDtStamp, channelId, timeGap = 1, setChannelEpgData = false,
}) {
    const userConfig = yield select(getUserConfigFromReducer);

    // timestamp for current whole day
    const startTimeStamp = new Date(new Date().setHours(0, 0, 0, 0));
    const endTimeStamp = new Date(new Date().setHours(23, 59, 59, 999));

    const startTime = getStartTime(dtStamp || startTimeStamp);
    const endTime = getEndTime(endDtStamp || endTimeStamp, timeGap);

    const {
        ln,
        mwTvPack = DEFAULT_CHANNEL_PRODUCT_ID,
    } = userConfig.userContentProperties;


    const { lang } = userConfig.userInfo;
    const { channels } = yield select(getChannelsFromReducer);

    let epgResponse = {};
    if (setChannelEpgData) {
        const channelsEpgData = channels.map((channel, index) => {
            const epgKey = new Date(startTime).getDate();
            if (!channel.epgData) {
                channel.epgData = {
                    [epgKey]: {
                        isFetching: true,
                        epg: [],
                    },
                };
            }
            else {
                channel.epgData[epgKey] = {
                    isFetching: true,
                    epg: [],
                };
            }
            return channel;
        });
        yield put(fetchAllChannelsSuccess({ channels: channelsEpgData }));
    }
    try {
        const deviceUtil = yield getContext('deviceUtil');
        epgResponse = yield call(getEpgContentApi, {
            userContentProperties: userConfig.userContentProperties,
            mwTvPack,
            ln: lang && lang.length > 0 ? lang[0] : ln,
            startTime,
            endTime,
            deviceUtil,
        });
    }
    catch (e) {
        if (channelId) {
            epgResponse = {
                programGuide: {
                    [channelId]: [],
                },
            };
        }
    }

    if (channelId && !lodashGet(epgResponse, `programGuide.${channelId}`)) {
        epgResponse.channelNotFoundId = channelId;
    }

    if (channelId && lodashGet(epgResponse, `programGuide.${channelId}`)) {
        const mockResponse = createMockEPG(lodashGet(epgResponse, `programGuide.${channelId}`), startTime, endTime);
        epgResponse.programGuide[channelId] = mockResponse;
    }

    if (setChannelEpgData) {
        const programGuide = lodashGet(epgResponse, 'programGuide', {});
        const channelsEpgData = channels.map((channel, index) => {
            const epgKey = new Date(startTime).getDate();
            const channelProgramguide = lodashGet(programGuide, `[${channel.id}]`, []);
            channel.epgData[epgKey] = {
                isFetching: false,
                epg: channelProgramguide,
            };
            return channel;
        });
        yield put(fetchAllChannelsSuccess({ channels: channelsEpgData }));
    }
    yield put(fetchEpgChannelDetailsSuccess({
        epgResponse,
        startTime,
        endTime,
    }));
}

export default [
    takeFirst(EpgAction.FETCH_EPG_DETAILS, fetchEpgContent),
];
