import React from 'react';
import { LayoutContainer } from '@airtel-feature/layout/LayoutContainer';
import PropTypes from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import { paginatedLayoutFetchAction, searchLayoutReset } from '@airtel-feature/layout/actions/LayoutActions';
import { fetchAllChannels } from '@airtel-feature/layout/actions/ChannelAction';
import { fetchEpgChannelDetails } from '@airtel-feature/layout/actions/EpgAction';

import lodashGet from 'lodash/get';
import { filterSubscribedPlans, getTimeStamp } from '@airtel-tv/utils/GlobalUtil';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { ERROR_SOURCES } from '@airtel-tv/utils/ErrorCodesWeb';
import CrashCaptureHOC from '@airtel-tv/lib/hoc/CrashCaptureHOC';
import { syncContentInit } from '@airtel-tv/sync/SyncActions';
import { setPreviousPageTitleAction } from '@airtel-feature/content-details/content-details/actions/ContentDetailsAction';
import { checkWindowExist } from '@airtel-tv/utils/WindowUtil';
import { Navigate, matchPath, matchRoutes } from 'react-router';
import { DEFAULT_NAV, ROUTE_PATHS, ROUTE_STATIC_IDS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { BreadCrumbsUtil } from '@airtel-tv/utils/BreadCrumbsUtil';
import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import DeviceUtil from '@airtel-tv/utils/DeviceUtil';
import { IMAGE_PATHS, LAYOUT_TABS_ARRAY } from '@airtel-tv/constants';
import ErrorHandler from '../../error-handler/ErrorHandler';
import LayoutLoaderComponent from '../../shared/components/loader/LayoutLoaderComponent';
import SmallLoaderComponent from '../../shared/components/loader/SmallLoaderComponent';
import LiveChannelBox from '../../live-channel-box/LiveChannelBox';
import RenewBannerComponent from '../components/RenewBannerComponent/RenewBannerComponent';
import { updateOnPageDescriptionAction } from '../../footer/actions/FooterAction';
import getRail from '../../rail/factories/RailFactory';
import {
    changeActiveNavbar, changeMetaData, showRenewBanner,
} from '../../notify/NotifyActions';
import { updatePageBreadCrumbs } from '../../breadcrumbs/actions/BreadCrumbsActions';
import getAllRouters from '../../../routes/routes';
import MarkupFactory from '../../watch-actions/factories/MarkupFactory';
import { getMetaLayout } from '../../../utilities/CommonUtil';
import LoaderComponent from '../../shared/components/loader/LoaderComponent';
import ErrorPageComponent from '../../shared/components/error-page/ErrorPageComponent';
import { CryptoUtil } from '@airtel-tv/utils';
import { isErrorPageShown } from '@airtel-feature/notify/NotifyActions';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';

const getPageIdFromProps = (match, appConfig, webPageId = {}, location) => {
    const {
        params: { pageTitle = '', contentId = '', implicitPageId = '' },
    } = match;
    const { SPORTS_LAYOUT = 'XstreamSports', SEARCH_PAGE_LAYOUT = 'searchLayout' } = webPageId;
    let isSportsRoute = null;
    let isSearchRoute = null;
    if (location?.pathname) {
        isSportsRoute = matchPath({
            path: ROUTE_PATHS.SPORTS_LAYOUT,
            exact: true,
            strict: false,
        }, location?.pathname);
        isSearchRoute = matchPath({
            path: ROUTE_PATHS.SEARCH_PAGE,
            exact: true,
            strict: false,
        }, location?.pathname);
    }
    if (isSportsRoute !== null) {
        return {
            contentId,
            pageId: SPORTS_LAYOUT,
        };
    }
    if (isSearchRoute !== null) {
        return {
            contentId,
            pageId: SEARCH_PAGE_LAYOUT,
        };
    }
    const { pageIdTitleMap } = appConfig;

    let pageId = null;
    if (implicitPageId) {
        pageId = implicitPageId;
    }
    else if (pageTitle) {
        pageId = pageIdTitleMap[pageTitle];
    }
    else {
        pageId = appConfig.pages ? appConfig.pages[0].id : null; // first page is default page
    }

    return {
        contentId,
        pageId,
    };
};

class LayoutContainerWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isBackButtonVisible: false,
            renderLayout: true,
        };
        this.isScrolled = false;
        this.didUpdateTimer = React.createRef();
        this.isCSR = checkWindowExist();
    }

    componentDidMount() {
        this.updateSeoMeta();
        if (checkWindowExist()) {
            window && window.addEventListener('wheel', this.onScroll);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname = '' }, combinedPlansData, showRenewBannerDispatch, isSearchDriven, searchLayoutResetDispatch, query,
            pageId,
            layoutPageId,
            generateNewLayoutInstance,
            reRenderLayoutTimeout,
        } = this.props;
        const {
            pageId: prevPageId,
            layoutPageId: prevLayoutPageId,
        } = prevProps
        const subscribedPlans = filterSubscribedPlans(combinedPlansData);
        if (isSearchDriven && prevProps?.query !== query) {
            searchLayoutResetDispatch({ payload: { isSearchLayout: true } });
        }
        if (pathname !== prevProps.location.pathname) {
            this.updateSeoMeta();
        }
        if (combinedPlansData !== prevProps.combinedPlansData && subscribedPlans.length === 0) {
            showRenewBannerDispatch();
        }
        // if ((prevLayoutPageId !== layoutPageId) || (pageId !== prevPageId)) {
            // clearTimeout(this.didUpdateTimer.current);
            // if (generateNewLayoutInstance) {
                // this.setState({
                //     renderLayout: false,
                // })
                // this.didUpdateTimer.current = setTimeout(() => {
                //     this.setState({
                //         renderLayout: true,
                //     })
                // }, reRenderLayoutTimeout);
            // }
        // }
    }

    componentWillUnmount() {
        const { location } = this.props;
        const isCDPRoute = RoutingUtil.isCdpRoute(location?.pathname, getAllRouters) || false;
        if (checkWindowExist()) {
            !isCDPRoute && window.scroll(0, 0);
            window && window.removeEventListener('wheel', this.onScroll);
        }
    }

    onScroll = (evt) => {
        if (!this.scrolled) {
            this.scrolled = true;

            if (evt.deltaY < 0) {
                this.setState({
                    isBackButtonVisible: false,
                });
            }
            else if (evt.deltaY > 0) {
                this.setState({
                    isBackButtonVisible: true,
                });
            }
        }
        setTimeout(() => {
            this.scrolled = false;
        }, [
            10,
        ]);

        if (!checkWindowExist()) {
            this.updateSeoMeta();
        }
    };


    updateSeoMeta = (newLocation) => {
        const {
            route, appConfig: {
                pageDataById, pageIdTitleMap, pages, cdpPostFooterMapping,
            }, updatePageBreadCrumbsDispatch, location: { pathname = '' },
            updateOnPageDescriptionActionDispatch, blogConfig,
            skipSeoMeta = false,
        } = this.props;
        if (skipSeoMeta) {
            return;
        }
        let { location } = this.props;

        location = newLocation || location;

        // const LANGUAGE = LanguageProvider();

        let pageData = null;
        const deviceUtil = DeviceUtil.getDeviceUtil();
        const allRoutes = getAllRouters();
        const routePresent = matchRoutes(allRoutes, location.pathname);
        const currentRoute = routePresent.find(route => route?.pathname === location.pathname) || {};
        const currentRouteProps = lodashGet(routePresent[0], 'route', {});
        const isSportsRoute = matchPath({
            path: ROUTE_PATHS.SPORTS_LAYOUT,
            exact: true,
            strict: false,
        }, pathname);
        const isOnehubRoute = currentRoute?.route?.isOnehubRoute;
        let category = isSportsRoute ? 'sports' : lodashGet(currentRoute, 'params.pageTitle', '');
        category = isOnehubRoute ? 'onehub' : category;

        const { isHeaderNavigation = false } = currentRouteProps;

        if (!category) {
            if (isHeaderNavigation) {
                category = pages?.find(page => `/${page.urlName}` === currentRouteProps.path)?.urlName || '';
                if (!category && currentRoute.path === ROUTE_PATHS.BLOG_DESCRIPTION) { // ONLY FOR BLOG DESCRIPTION PAGE
                    category = pages?.find(page => `/${page.urlName}` === ROUTE_PATHS.BLOGS)?.urlName || '';
                }
            }
            else {
                category = lodashGet(currentRoute, 'params.category', DEFAULT_NAV.HOME);
            }
        }
        let pageId = DEFAULT_NAV.HOME;
        if (pageIdTitleMap) {
            pageId = pageIdTitleMap[category] || pageIdTitleMap[category.toLowerCase()]
                ? pageIdTitleMap[category] || pageIdTitleMap[category.toLowerCase()]
                : pageIdTitleMap[DEFAULT_NAV.HOME];
            pageData = pageDataById[pageId];
        }
        const isCDPRoute = RoutingUtil.isCdpRoute(location.pathname, getAllRouters);
        if (routePresent.length > 0 && !isCDPRoute) {
            const {
                params: { pageTitle = '' },
            } = this.props.match;
            const isLandingPage = matchPath({
                path: ROUTE_PATHS.LANDING,
                exact: true,
                strict: false,
            }, pathname);

            if (pageData) {
                const breadCrumbsList = BreadCrumbsUtil.layoutPageBreadCrumbs(isLandingPage ? {
                    urlName: pageData.urlName,
                    title: pageTitle,
                } : {
                    urlName: pageData.urlName,
                    title: pageData.title,
                });

                const pageIdMap = new Map(LAYOUT_TABS_ARRAY);
                const selectedTab = pageIdMap.get(pageData.urlName);
                const watchActionCatalogues = MarkupFactory({
                    contentDetails: {
                        programType: selectedTab,
                    },
                    baseUrl: deviceUtil.getBaseUrl(),
                    breadCrumbsList,
                });
                this.changeHeadMeta(
                    pageData.seoTitle,
                    pageData.seoDesc,
                    RoutingUtil.getCustomLayoutUrl({ urlName: pageData.urlName }),
                    IMAGE_PATHS?.[category],
                    watchActionCatalogues,
                    pageData.seoKeywords,
                );
                const { cdpOnPageData = {} } = blogConfig;
                if (pathname !== '' && cdpPostFooterMapping && Object.keys(cdpPostFooterMapping).length > 0) {
                    const postId = cdpPostFooterMapping[pathname];
                    const { content = {}, yoast_head_json: headJson = {} } = (postId && cdpOnPageData?.[postId]) || {};
                    if (content && Object.keys(content).length > 0) {
                        const { rendered } = content;
                        updateOnPageDescriptionActionDispatch(
                            { onPageDescription: rendered },
                        );
                    }
                    else {
                        updateOnPageDescriptionActionDispatch({
                            onPageDescription: pageData.onPageDescription,
                        });
                    }
                }
                else {
                    updateOnPageDescriptionActionDispatch({
                        onPageDescription: pageData.onPageDescription,
                    });
                }
                updatePageBreadCrumbsDispatch({ breadCrumbsList });
                this.changeActiveNavigation(pageId, ROUTE_STATIC_IDS.HOME);
            }
        }
    };

    changeActiveNavigation = (navLinkId, bottomNavId) => {
        const { activeNavigationDispatch } = this.props;
        return activeNavigationDispatch({
            navLinkId,
            bottomNavId,
        });
    };

    changeHeadMeta = (title = '', description = '', link, image = IMAGE_PATHS[DEFAULT_NAV.HOME], watchActionCatalogues = [], keywords = '') => {
        const { changeMetaDataDispatch } = this.props;
        return changeMetaDataDispatch({
            headMetaData: {
                title: getMetaLayout(title.charAt(0).toUpperCase() + title.slice(1)),
                description: description !== '' ? description : title,
                keywords,
                link,
                image,
                watchActionCatalogues,
            },
        });
    };

    getSearchLoader = () => <LoaderComponent pageType="searchPage" />;

    render() {
        const { isBackButtonVisible, renderLayout } = this.state;
        const {
            isSearchDriven, query, searchLayoutMeta, getSearchLayoutHeader = () => {}, pageId, webPageId: { PYW_PAGE = 'pywPage' } = {}, isAuthenticated,
            autoLoginInProgress,
            useFallBackLayout,
            CustomLoaderComponent,
        } = this.props;
        const Loader = CustomLoaderComponent || (isSearchDriven && query ? this.getSearchLoader : LayoutLoaderComponent);
        const {
            isEmptyRail, isFetchedData: searchLayoutFetched, layoutApiError, isSearchLayout = false,
        } = searchLayoutMeta;
        const searchResultEmpty = !layoutApiError && searchLayoutFetched && isEmptyRail;
        const SearchHeader = isSearchDriven ? getSearchLayoutHeader(!searchResultEmpty, query) : null;
        if (isSearchDriven && !layoutApiError && searchLayoutFetched && isEmptyRail) {
            return SearchHeader;
        }

        if (pageId === PYW_PAGE && !isAuthenticated && this.isCSR) {
            return (
                <Navigate
                    to={RoutingUtil.getHomePage()}
                />
            );
        }

        if (autoLoginInProgress) {
            return <Loader />;
        }
        return (
            <>
                {isSearchDriven ? SearchHeader : null}
                {renderLayout ? <LayoutContainer
                    isBackButtonVisible={isBackButtonVisible}
                    ErrorHandler={ErrorHandler}
                    Loadercomponent={Loader}
                    SmallLoaderComponent={CustomLoaderComponent || SmallLoaderComponent}
                    railFactory={getRail}
                    LiveChannelBox={LiveChannelBox}
                    RenewBannerComponent={RenewBannerComponent}
                    ERROR_SOURCES={ERROR_SOURCES}
                    ERROR_CODES={ERROR_CODES}
                    getPageIdFromProps={getPageIdFromProps}
                    webPageId
                    query={query}
                    isSearchDriven={isSearchDriven}
                    ErrorPageComponent={ErrorPageComponent}
                    getAllRoutes={getAllRouters}
                    useFallBackLayout={useFallBackLayout}
                    {...this.props}
                /> : null }
            </>
        );
    }
}

LayoutContainerWrapper.defaultProps = {
    isSearchDriven: false,
    query: '',
    searchLayoutMeta: {},
    getSearchLayoutHeader: () => {},
};

LayoutContainerWrapper.propTypes = {
    isSearchDriven: PropTypes.bool,
    query: PropTypes.string,
    searchLayoutResetDispatch: PropTypes.func.isRequired,
    getSearchLayoutHeader: PropTypes.func,
    searchLayoutMeta: PropTypes.object,
};


function mapStateToProps(state, props) {
    const {
        userConfig,
        appConfig,
        layoutDetails,
        packageContents,
        epg,
        channel,
        authConfig: {
            isAuthenticated,
            autoLoginInProgress,
        },
        themeConfig: {
            configs,
        },
        appConfig: {
            fetchEpgOnLoad = false, showSubscriptionRibbon = true, web_pageId: webPageId = {}, reRenderLayoutTimeout = 100,
        },
        notify: { hideRenewBanner = false },
        blogConfig,
        planConfig: { subscribedPlans: combinedPlansData },
        userConfig: {
            userInfo: {
                msisdn = '',
            } = {},
        },
        authConfig: { token = '' } = {},
    } = state;

    let msisdnNo = '';

    if (msisdn && token) {
        msisdnNo = CryptoUtil.AESDecryptMsdin({
            key: token,
            value: msisdn,
        });
        msisdnNo = msisdnNo.replace('+91', '+91 ');
    }
    const hasEpgData = getTimeStamp() < epg.endTime;

    const {
        match, route, location, isSearchDriven, query, layoutPageId: layoutPageIdFromProps,
    } = props;
    const routePresent = matchRoutes(getAllRouters(), location?.pathname);
    const selectedRoute = routePresent?.length > 1 ? routePresent[1] : routePresent[0];
    const { isOnehubRoute } = selectedRoute?.route || {};

    // get page id
    const { pageId, contentId } = props.pageId ? props : getPageIdFromProps(match, appConfig, webPageId, location);

    const {
        MORE_LIKE_THIS,
        CAST_N_MORE,
    } = webPageId;

    const isMultiListRail = [
        MORE_LIKE_THIS,
        CAST_N_MORE,
    ].includes(pageId);

    const isLandingPage = matchPath({
        path: ROUTE_PATHS.LANDING,
        exact: true,
        strict: false,
    }, location?.pathname);

    const queryParam = LocationUtil.getQueryParams(props);
    const isLandingLangaugePage = isLandingPage ? queryParam?.context_language || '' : '';

    const layoutPageId = layoutPageIdFromProps || `${pageId}${isMultiListRail && contentId ? `_${contentId}` : ''}${isSearchDriven || (isOnehubRoute && query) ? `_${query}` : ''}${isLandingLangaugePage ? `_${queryParam.context_language}` : ''}`;

    const {
        [layoutPageId]: pageLayoutData = null,
        layoutReset = false,
        searchLayoutMeta = {},
    } = layoutDetails;
    // layout data
    const isSSRData = pageLayoutData ? pageLayoutData.isSSRData : null;
    let layoutPackages = null;

    if (!(isSSRData && isAuthenticated)) {
        layoutPackages = layoutDetails[layoutPageId] ? layoutDetails[layoutPageId].layoutPackages : null;
    }
    const generateNewLayoutInstance = !!Object.keys(layoutDetails?.[layoutPageId] || {}).length;

    const canFetchMore = lodashGet(pageLayoutData, 'fetchMore', false);
    const totalRailsFetched = lodashGet(pageLayoutData, 'totalFetched', 0);

    const layoutError = pageLayoutData ? pageLayoutData.error : layoutDetails.error ? layoutDetails.error : null;

    return {
        userConfig,
        appConfig,
        themeConfig: configs,
        layoutPackages,
        layoutError,
        packageContents,
        pageId,
        contentId,
        channels: channel.channels,
        layoutDetails,
        isAuthenticated,
        hasEpgData,
        canFetchMore,
        totalRailsFetched,
        fetchEpgOnLoad,
        hideRenewBanner,
        showSubscriptionRibbon,
        webPageId,
        isSSRData,
        layoutReset,
        blogConfig,
        route,
        combinedPlansData,
        searchLayoutMeta,
        reRenderLayoutTimeout,
        msisdnNo,
        isOnehubRoute,
        // layoutPageId,
        autoLoginInProgress,
        layoutPageId,
        generateNewLayoutInstance,
    };
}

export default withRouter(connect(mapStateToProps, {
    layoutFetchActionDispatch: paginatedLayoutFetchAction,
    fetchAllChannelsDispatch: fetchAllChannels,
    fetchEpgChannelDetailsDispatch: fetchEpgChannelDetails,
    syncContentInitDispatch: syncContentInit,
    setPreviousPageTitleActionDispatch: setPreviousPageTitleAction,
    updateOnPageDescriptionActionDispatch: updateOnPageDescriptionAction,
    changeMetaDataDispatch: changeMetaData,
    updatePageBreadCrumbsDispatch: updatePageBreadCrumbs,
    activeNavigationDispatch: changeActiveNavbar,
    showRenewBannerDispatch: showRenewBanner,
    searchLayoutResetDispatch: searchLayoutReset,
    isErrorPageShownDispatch: isErrorPageShown,
})(CrashCaptureHOC(LayoutContainerWrapper)));
