import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { PropTypes } from 'prop-types';
import { LOCAL_TILE_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { buildRail } from '@airtel-feature/layout/builders/RailBuilder';
import { checkWindowExist } from '@airtel-tv/utils';
import CrashCaptureHOC from '@airtel-tv/lib/hoc/CrashCaptureHOC';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import withStyles from 'isomorphic-style-loader/withStyles';
import { contentVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { getSourceNameFromStorage, toTitleCase } from '@airtel-tv/utils/GlobalUtil';
import ErrorHandler from '../../error-handler/ErrorHandler';
import { getRail } from '../../rail/factories/RailFactory';
import { INTERSECTION_OBSERVER_THRESHOLD } from '../../../constants/AppConst';
import LanguageProvider from '../../../providers/LanguageProvider';
import { changeMetaData } from '../../notify/NotifyActions';
import IntersectionObservableHOC from '../../shared/hoc/IntersectionObservableHOC';
import SmallLoaderComponent from '../../shared/components/loader/SmallLoaderComponent';
import { updatePageBreadCrumbs } from '../../breadcrumbs/actions/BreadCrumbsActions';
import { updateOnPageDescriptionAction } from '../../footer/actions/FooterAction';
import { fetchBlogs } from '../../blog/BlogActions';
import styles from './ListingContainer.scss';
import { urlStringToCaption } from '../../../utilities/CommonUtil';
import LoaderComponent from '../../shared/components/loader/LoaderComponent';

class DistroTvListingContainer extends Component {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.state = {
            errorLocal: null,
        };
        this.LANGUAGE = LanguageProvider();
        if (!checkWindowExist()) {
            this.updateSeoMeta();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            distroData,
        } = nextProps;

        if (distroData !== prevState.distroData) {
            return {
                distroData,
            };
        }

        return null;
    }

    componentDidMount() {
        this.init();
        const { prevSource } = getSourceNameFromStorage() || {};
        const meta = {
            source_name: 'grid_page',
            source_page: prevSource,
        };
        contentVisibleEvent(meta);
    }

    componentDidUpdate(prevState, prevProps) {
        this.init(prevState, prevProps);
    }

    componentWillUnmount() {
        const { updateOnPageDescriptionActionDispatch } = this.props;
        updateOnPageDescriptionActionDispatch({
            onPageDescription: null,
        });
    }

    getTitle = () => 'Distro Free Channels';

    findCurrentPageId = (pageUrlName, pageId) => {
        const { pageDataById } = this.props;
        return pageDataById[pageId].urlName === pageUrlName;
    };

    // eslint-disable-next-line consistent-return
    init = (prevState = {}, prevProps = {}) => {
        const {
            distroData,
        } = this.state;
        const { location: prevLocation = {} } = prevState;
        const { pathname: prevPathname = '' } = prevLocation;
        const { pathname = '' } = location;
        if (prevState.distroData !== distroData || prevPathname !== pathname) {
            this.updateSeoMeta();
        }
    };

    updateSeoMeta = () => {
        // const {
        //     packageDetails,
        // } = this.state;

        // // fetch layout
        // const {
        //     changeMetaDataDispatch,
        //     match,
        //     railId,
        //     railFetchActionDispatch,
        //     railDetails,
        //     updatePageBreadCrumbsDispatch,
        //     pageDataById,
        //     updateOnPageDescriptionActionDispatch,
        //     cpDetailsById,
        //     blogConfig,
        //     cdpPostFooterMapping,
        //     pageTitle,
        //     category,
        //     packageId,
        //     lang,
        //     dimensionPageTitle,
        //     customCdpContent,
        //     location: { pathname = '' },
        //     genres,
        //     isListPage
        // } = this.props;
        // // const packageName = lodashGet()
        // const packageTitle = lodashGet(packageDetails, 'title', '');
        // const fallBackTitle = lodashGet(railDetails, 't', packageTitle);
        // const metaTitle = isListPage ? railDetails?.format?.t || lodashStartCase(this.getTitle() || '') || fallBackTitle : railDetails?.format?.t || fallBackTitle;

        // // custom seo meta
        // let pageUrlName;
        // let pageId;
        // if (
        //     packageDetails
        //     && packageDetails.id
        //     && ((railDetails && railId) || (!pageTitle && !category))) {
        //     if (railDetails && railId) {
        //         pageUrlName = lodashGet(railDetails, 'format.action.meta.pageUrlName', 'home');
        //         pageId = Object.keys(pageDataById).find(page => this.findCurrentPageId(pageUrlName, page));
        //     }
        //     else if (!pageTitle
        //         && !category) {
        //         pageId = Object.keys(pageDataById).find(page => this.findCurrentPageId(DEFAULT_NAV.HOME, page));
        //     }

        //     const onPageDescription = lodashGet(packageDetails, 'seoData.seoOnPageContent', '');
        //     const { cdpOnPageData = {} } = blogConfig;
        //     const postId = (cdpPostFooterMapping?.[packageDetails?.id] || cdpPostFooterMapping?.[pathname]) || '';
        //     const { content = {}, yoast_head_json: headJson = {} } = (postId && cdpOnPageData?.[postId]) || {};

        //     const seoTitle = (headJson && headJson.title) || lodashGet(packageDetails, 'seoData.seoTitle', '');
        //     const seoDescription = (headJson && headJson.description) || lodashGet(packageDetails, 'seoData.seoDescription', '');
        //     const seoKeywords = lodashGet(packageDetails, 'seoData.seoKeywords', '');
        //     if (content && Object.keys(content).length > 0) {
        //         const { rendered } = content;
        //         updateOnPageDescriptionActionDispatch({
        //             onPageDescription: rendered,
        //         });
        //     }
        //     else if (onPageDescription) {
        //         updateOnPageDescriptionActionDispatch({
        //             onPageDescription,
        //         });
        //     }

        //     const cpId = lodashGet(packageDetails, 'content[0].cpId', null);
        //     const seoPackageTitle = lodashGet(cpDetailsById[cpId], 'seoPackageTitle', '');
        //     const seoPackageDescription = lodashGet(cpDetailsById[cpId], 'seoPackageDescription', '');
        //     const pageTitle = pageDataById[pageId]?.title;
        //     const curPageLink = RoutingUtil.getPackageListingUrlV2({
        //         packageId: packageDetails.id,
        //         title: metaTitle,
        //         railId,
        //     });
        //     const breadCrumbsList = BreadCrumbsUtil.listingPageBreadCrumbs({
        //         contentDetails: {
        //             title: metaTitle,
        //             programType: pageUrlName,
        //             pageTitle,
        //         },
        //         curPageLink,
        //     });
        //     const seoMarkups = MarkupFactory({
        //         itemList: packageDetails,
        //         baseUrl: this.deviceUtil.getBaseUrl(),
        //         breadCrumbsList,
        //     });
        //     let finalTitle;
        //     let finalDescription;
        //     if (seoPackageTitle && seoPackageDescription) {
        //         finalTitle = seoTitle || replaceAll(seoPackageTitle, '{railName}', metaTitle);
        //         finalDescription = seoDescription || replaceAll(seoPackageDescription, '{railName}', metaTitle);
        //     }
        //     else {
        //         finalTitle = seoTitle || replaceAll(this.LANGUAGE.LISTING_PAGE_META_TITLE, '{railName}', metaTitle);
        //         finalDescription = seoDescription || replaceAll(this.LANGUAGE.LISTING_PAGE_META_DESCRIPTION, '{railName}', metaTitle);
        //     }
        //     changeMetaDataDispatch({
        //         headMetaData: {
        //             title: finalTitle,
        //             description: finalDescription,
        //             link: curPageLink,
        //             keywords: seoKeywords,
        //             watchActionCatalogues: seoMarkups,
        //         },
        //     });
        //     updatePageBreadCrumbsDispatch({ breadCrumbsList });
        // }
        // else if (packageId && pageTitle && dimensionPageTitle && category) {
        //     // seo update for dimension page
        //     const queryParams = LocationUtil.getQueryParams(this.props);
        //     const genreName = genres && genres?.length ? genres[0] : '';
        //     const language = lang && lang?.length ? lang[0] : null;
        //     // breadcrumb update
        //     const breadCrumbTitle = formTitle({
        //         genres: genreName,
        //         pageTitle: dimensionPageTitle[0],
        //         lang: getLangfromCode({ lang: language }),
        //     });

        //     const canonicalUrl = RoutingUtil.getDimensionPageUrlV2({
        //         pageTitle: DIMENSION_PAGE_MAP[dimensionPageTitle[0]],
        //         genres: genreName,
        //         lang: getLangfromCode({ lang: language }),
        //         queryParams,
        //     });
        //     const breadCrumbsList = BreadCrumbsUtil.dimensionPageBreadCrumbs({
        //         pageTitle: dimensionPageTitle[0],
        //         prefix: language,
        //         title: breadCrumbTitle,
        //         curPageLink: canonicalUrl,
        //     });

        //     updatePageBreadCrumbsDispatch({ breadCrumbsList });
        //     // head meta and footer page description update
        //     let seoTitle; let seoDescription; let
        //         seoFooterContent;

        //     // write blogPost logic here
        //     // this is dimention page, whose footer is defined in cms customMapping
        //     const { cdpOnPageData = {} } = blogConfig;
        //     const postId = pathname && cdpPostFooterMapping?.[pathname];
        //     if (postId) {
        //         const { content = {}, yoast_head_json: headJson = {} } = (postId && cdpOnPageData?.[postId]) || {};
        //         if (content && Object.keys(content).length > 0) {
        //             const { rendered } = content;
        //             updateOnPageDescriptionActionDispatch(
        //                 { onPageDescription: rendered },
        //             );
        //         }
        //         if (headJson) {
        //             const { description = '', title = '', og_description = '' } = headJson;
        //             const headMetaData = {
        //                 title,
        //                 description: description || og_description,
        //                 link: canonicalUrl,
        //             };
        //             changeMetaDataDispatch({
        //                 headMetaData,
        //             });
        //         }
        //     }
        //     else {
        //         ({ title: seoTitle, description: seoDescription } = getDimensionMeta({
        //             genres: genreName,
        //             lang: getLangfromCode({ lang: language }),
        //             pageTitle: dimensionPageTitle[0],
        //         }));

        //         (seoFooterContent = getDimensionPageContent({
        //             pageTitle: dimensionPageTitle[0],
        //             genres: genreName,
        //             lang: getLangfromCode({ lang: language }),
        //         }));
        //         updateOnPageDescriptionActionDispatch({
        //             onPageDescription: seoFooterContent,
        //         });
        //         const watchActionCatalogues = MarkupFactory({
        //             contentDetails: {
        //                 programType: BREADCRUMBS_NAME.ARTISTS,
        //             },
        //             baseUrl: this.deviceUtil.getBaseUrl(),
        //             breadCrumbsList,
        //         });
        //         changeMetaDataDispatch({
        //             headMetaData: {
        //                 title: seoTitle,
        //                 description: seoDescription,
        //                 link: canonicalUrl,
        //                 watchActionCatalogues,
        //             },
        //         });
        //     }
        // }
    };

    render() {
        const {
            errorLocal,
            distroData,
        } = this.state;


        const {
            error, cpDetailsById, themeConfig,
            match: {
                params: {
                    pageTitle = '',
                },
            },
        } = this.props;

        let title = (urlStringToCaption(pageTitle));
        title = toTitleCase(title);
        if (error || errorLocal) {
            ErrorHandler({
                error,
                code: error.errorCode || errorLocal,
            });
            return null;
        }

        // check no content found
        const noContent = !distroData?.length;

        // show no content page
        if (noContent) {
            return <LoaderComponent pageType="listing" />;
        }

        const railProps = buildRail({
            items: distroData,
            totalCount: distroData?.length,
            explicitTileType: LOCAL_TILE_TYPES.LOCAL_TILE,
            explicitRailTitle: title,
            explicitShowGrid: true,
            themeConfig,
            cpDetailsById,
            preferredArtwork: 'LOGO_HD',
        });
        const railElement = getRail({
            railProps: {
                ...railProps,
                ...{
                    isFreeChannelsRail: true,
                    hideImageAltTag: true,
                    hideTileBottomeDetails: true,
                    customClassTileLevel: 'distro-tile',
                },
            },
        });
        const loader = !distroData?.length ? <SmallLoaderComponent /> : null;
        return (
            <div className="distro-listing">
                <IntersectionObservableHOC
                    hasMore={false}
                    loaderElem={loader}
                    threshold={INTERSECTION_OBSERVER_THRESHOLD.NOT_LOADED}
                >
                    {railElement}
                </IntersectionObservableHOC>
            </div>
        );
    }
}

DistroTvListingContainer.defaultProps = {
    error: null,
    railId: null,
    railDetails: null,
    packageId: null,
    cdpPostFooterMapping: {},
};

DistroTvListingContainer.propTypes = {
    error: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    updatePageBreadCrumbsDispatch: PropTypes.func.isRequired,
    pageDataById: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    themeConfig: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    cpDetailsById: PropTypes.objectOf(PropTypes.object).isRequired,
    subscriptionDataById: PropTypes.objectOf(PropTypes.object).isRequired,
    updateOnPageDescriptionActionDispatch: PropTypes.func.isRequired,
    cdpPostFooterMapping: PropTypes.object,
};

function mapStateToProps(state, props) {
    const {
        packageContents, channel,
        appConfig: {
            pageDataById,
            cpDetailsById,
            cdpPostFooterMapping,
            customCdpContent,
        },
        userConfig: {
            subscriptionDataById,
        },
        themeConfig: {
            configs,
        },
        blogConfig,
        distroDetails: {
            distroData = [],
        } = {},
    } = state;
    return {
        error: packageContents.error,
        channels: channel.channels,
        pageDataById,
        themeConfig: configs,
        cpDetailsById,
        subscriptionDataById,
        blogConfig,
        cdpPostFooterMapping,
        customCdpContent,
        distroData,
    };
}

export default withRouter(connect(
    mapStateToProps, {
        changeMetaDataDispatch: changeMetaData,
        updatePageBreadCrumbsDispatch: updatePageBreadCrumbs,
        updateOnPageDescriptionActionDispatch: updateOnPageDescriptionAction,
        fetchBlogsDispatch: fetchBlogs,
    },
)(CrashCaptureHOC(withDeviceUtil(withStyles(styles)(DistroTvListingContainer)))));
