import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './RenewBannerComponent.scss';
import { connect } from 'react-redux';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { filterSubscribedPlans, getXStreamDetail } from '../../../../utilities/CommonUtil';
import { checkWindowExist, getWindowLocation } from '../../../../utilities/WindowUtil';
import RoutingUtil from '../../../../utilities/RoutingUtil';
import { purchaseSubscriptionInit } from '../../../subscription-details/action/SubscriptionAction';
import env from '../../../../config';
import { hideRenewBanner } from '../../../notify/NotifyActions';
import { ELEMENT_ID } from '../../../../constants/AppConst';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import  SubscriptionUtil from '@airtel-tv/utils/SubscriptionUtil';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS } from '../../../../constants/AnalyticsConst';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { showNonAirtelUserAction } from '../../../user/UserAction';

const RenewBannerComponent = (props) => {
    const [ hideRibbonForUid, setHideRibbonForUid ] = useState('');
    const bannerRef = useRef();
    const { subscriptionRibbonData, deviceUtil: { isMobile }, combinedPlansData, userConfig, planDetails, purchaseSubscriptionInitDispatch, hideRenewBannerDispatch, logoutTriggered } = props;
    const {
        bannerImageUrl, bannerCtaTextMWeb, bannerCtaTextWeb, bannerMainText, bannerSubtext, bannerCtaUpgradeMWeb, bannerCtaUpgradeWeb,
    } = subscriptionRibbonData;

    const subscribedPlans = filterSubscribedPlans(combinedPlansData);

    const renewSubBannerAnalyticsMeta = {
        asset_name: ANALYTICS_ASSETS.SUBSCRIPTION_RIBBON,
        action: subscribedPlans?.length > 0 ? ANALYTICS_ACTIONS.RIBBON_UPGRADE : ANALYTICS_ACTIONS.RIBBON_SUBSCRIBE,
    }

    const renewSubBannerCrossAnalyticsMeta = {
        asset_name: ANALYTICS_ASSETS.SUBSCRIPTION_RIBBON,
        action:ANALYTICS_ACTIONS.CLOSE,
    }
    const layoutElement = props?.layoutRef?.current;
    const bannerEle = bannerRef?.current;

    useEffect(() => {
        const { userConfig: { userInfo = {}}, deviceUtil: { isMobile }, hideRenewBanner} = props;
        const hideRibbon = sessionStore.get(`${userInfo.uid}_${LOCAL_STORE_KEYS.SHOW_RIBBON}`);

        setHideRibbonForUid(hideRibbon);
        const headerEle = document.getElementById(ELEMENT_ID.HEADER_CONTAINER) || undefined;
        const bannerEle = bannerRef?.current;
        setHideRibbonForUid(hideRibbon);
        if ((SubscriptionUtil.getXStreamDetail(subscribedPlans) && Object.keys(SubscriptionUtil.getXStreamDetail(subscribedPlans)).length  < 1) && bannerEle && !hideRibbon && !isMobile()) {
            // layoutElement.style['padding-top'] = `${(bannerEle.offsetHeight - 6)}px` || 72px';
        }
        if (isMobile() && checkWindowExist() && headerEle && bannerEle && layoutElement) {
            window.onscroll = () => {
                if (headerEle.offsetHeight < window.scrollY) {
                    if(bannerEle) {
                        bannerEle.style['position'] = 'fixed';
                    }
                    layoutElement.style['padding-top'] = hideRibbon || hideRenewBanner ? '60px' : '138px';
                } else {
                    if(bannerEle) {
                        bannerEle.style['position'] = 'unset'
                    }
                    layoutElement.style['padding-top'] = '0px';
                }
            };
        }
    }, []);


    const getCtaText = () => {
        if (isMobile()) {
            if (subscribedPlans?.length > 0) {
                return bannerCtaUpgradeMWeb;
            }
            return bannerCtaTextMWeb;
        } if (subscribedPlans?.length > 0) {
            return bannerCtaUpgradeWeb;
        }
        return bannerCtaTextWeb;
    };

    const closeBanner = (e) => {
        const { userConfig: { userInfo = {}}, layoutRef} = props;
        e.preventDefault();
        bannerRef.current.classList.add('cross-clicked');
        setTimeout(() => {
            bannerRef.current.classList.add('d-none');
            setHideRibbonForUid(true);
        }, 400);
        sessionStore.set(`${userInfo.uid}_${LOCAL_STORE_KEYS.SHOW_RIBBON}`, true);
        hideRenewBannerDispatch();
        layoutRef.current.classList.add('cross-clicked');
    };

    const navigateToPaymentPage = () => {
        if (userConfig.customerType === 'NON_AIRTEL') {
            const { showNonAirtelUserActionDispatch } = props;
            showNonAirtelUserActionDispatch({ cpId: channelpartner });
            return;
        }
        const subscriptionWindowLocation = getWindowLocation();
        const params = new URLSearchParams(window.location.search);

        const selectedPlanId = params.get('planID') ? params.get('planID') : planDetails?.PLAN_ID;
        const channelpartner = planDetails?.CP_ID;
        const skuId = params.get('skuId') ? params.get('skuId') : undefined;
        
        const {successUrl, failureUrl } =  SubscriptionUtil.getSubscriptionUrls(
            {plan : {planId: selectedPlanId, cpId:channelpartner },
            successPage:  RoutingUtil.getSubscriptionSuccessPage(),
            failurePage:  RoutingUtil.getSubscriptionFailurePage()
        });
        purchaseSubscriptionInitDispatch({
            successUrl,
            failureUrl,
            pendingUrl: failureUrl,
            unknownUrl: failureUrl,
            planId: selectedPlanId,
            skuId,
            eventMethod: () => null
        });
    }

    if (logoutTriggered || hideRibbonForUid || (SubscriptionUtil.getXStreamDetail(subscribedPlans) && Object.keys(SubscriptionUtil.getXStreamDetail(subscribedPlans)).length > 0)) {
        layoutElement ? layoutElement.style['padding-top'] = 0 : null;
        return null;
    }else if(!isMobile() && layoutElement){
        //layoutElement.style['padding-top'] = `${(bannerEle && bannerEle.offsetHeight - 6)}px` || '72px';
    }

    return (
        <div className="renew-banner-wrapper" ref={bannerRef}>
            <div className="left-section">
                <div className="renew-banner-img">
                    <ThumborImage
                        src={bannerImageUrl}
                        imageSettings={{
                            320: 500,
                            500: 800,
                            1000: 1000,
                        }}
                        className="renew-banner-img"
                    />
                </div>
                <div className="divider-line" />
                <div>
                    <div className="banner-main-text">{ bannerMainText }</div>
                    <div className="banner-sub-text">{ bannerSubtext }</div>
                </div>
                <div className="banner-btn-wrapper" onClick={navigateToPaymentPage} id="atm_renew-subscription-cta">
                    <AnalyticsLinkComponent
                        meta={renewSubBannerAnalyticsMeta}
                        className="play-btn-banner hover-effect common-white-btn"
                    >
                        <p id="renew-subscription-cta-text">{ getCtaText() }</p>
                    </AnalyticsLinkComponent>
                </div>
            </div>
            {/* <div className="close-button" onClick={closeBanner}> */}
            <AnalyticsLinkComponent
                meta={renewSubBannerCrossAnalyticsMeta}
                className="close-button"
                onClick={closeBanner}
                id="atm_renew-subscription-close-cta"
            >
                { isMobile() ? (
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12.5 3.5L3.5 12.5"
                            stroke="#8A95AD"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M12.5 12.5L3.5 3.5"
                            stroke="#8A95AD"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) : (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18.75 5.25L5.25 18.75"
                            stroke="#4F5866"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M18.75 18.75L5.25 5.25"
                            stroke="#4F5866"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) }
            </AnalyticsLinkComponent>
        </div>
    );
};

function mapStateToProps(state) {
    const subscriptionRibbonDataFallback = {
        bannerImageUrl: "https://image.airtel.tv/grandslam/content/2023_4_10/Group_625476.png",
        bannerMainText: "Get access to 15+ OTTs at the price of one",
        bannerSubtext: "Watch all your favourite shows just at ₹149",
        bannerCtaTextWeb: "Subscribe Now",
        bannerCtaTextMWeb: "Subscribe",
        bannerCtaUpgradeWeb: "Upgrade Now",
        bannerCtaUpgradeMWeb: "Upgrade"
    }
    const DEEPLINK_SUBSCRIPTION_PLAN_FALLBACK = {
        PLAN_ID: 6488,
        CP_ID: "XSTREAMPREMIUM",
        PLAN_ID_1499: 6588
    }
    const { appConfig: { subscriptionRibbonData = subscriptionRibbonDataFallback, DEEPLINK_SUBSCRIPTION_PLAN = DEEPLINK_SUBSCRIPTION_PLAN_FALLBACK },
            planConfig: { subscribedPlans: combinedPlansData },
            authConfig,
            userConfig,
            notify: { hideRenewBanner = false }
        } = state;
    const planDetails = DEEPLINK_SUBSCRIPTION_PLAN;
    return {
        subscriptionRibbonData,
        combinedPlansData,
        userConfig,
        planDetails,
        hideRenewBanner,
        logoutTriggered: authConfig.logoutTriggered,
    };
}

export default connect(mapStateToProps, {
    purchaseSubscriptionInitDispatch: purchaseSubscriptionInit,
    hideRenewBannerDispatch: hideRenewBanner,
    showNonAirtelUserActionDispatch: showNonAirtelUserAction,
})(withDeviceUtil(withStyles(styles)(RenewBannerComponent)));
