import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import TabContainer from '@airtel-feature/content-details/content-detail-page/container/TabContainer';
import EpisodeRailComponent from '@airtel-feature/content-details/content-detail-page/component/EpisodeRailComponent/EpisodeRailComponent';
import styles from '@airtel-feature/content-details/content-detail-page/container/TabContainer.scss';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import EventType from '@airtel-tv/analytics/EventType';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { checkWindowExist, getElementById, LocationUtil } from '@airtel-tv/utils';
import { ELEMENT_ID } from '@airtel-tv/constants';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';
import MultiListTabContainer from '@airtel-feature/layout/MultiListTabContainer';
import { TAB_POSITION } from '../../../constants/AppConst';
import { getSourceName } from '../../../utilities/CommonUtil';
import LayoutContainerWrapper from '../../layout/containers/LayoutContainerWrapper';

class MultiRailsListContainer extends Component {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.state = {
            activeTab: 0,
            hasTrailer: false,
        };
    }

    componentDidMount() {
        const {
            tiles, deviceUtil, location, navigate,
        } = this.props;
        const { pageId } = LocationUtil.getQueryParams(this.props);
        let tile;
        if (tiles && tiles.length > 0) {
            if (pageId && pageId !== '') {
                tile = tiles.find((t) => {
                    if (t.contentDetails
                        && t.contentDetails.hydrationAdditionalQueryParams
                        && t.contentDetails.hydrationAdditionalQueryParams.pageId
                        && t.contentDetails.hydrationAdditionalQueryParams.pageId === pageId) {
                        return true;
                    }
                });
            }
            else {
                tile = tiles[0];
            }
            this.setActivetab({ index: 0 });
            // Below code leads to removal iof qp after loging
            const { fromHome } = LocationUtil.getQueryParams(this.props);
            if (!fromHome || fromHome !== 'true') {
                navigate(`${location.pathname}`, { replace: true });
            }
        }

        if (checkWindowExist()) {
            window && window.addEventListener('scroll', this.scrollEventHandling);
        }
    }

    scrollEventHandling = () => {
        // const mwebTrailer = document.getElementsByClassName('mweb-trailer')[0]
        // if (isMobile() && mwebTrailer && mwebTrailer.classList.contains('opaque')) {
        //     this.setState({ hasTrailer: true });
        // };
        // const { hasTrailer } = this.state;
        // const bannerEle = document.getElementsByClassName(isMobile() ? 'mobile-banner-wrapper' : 'cdp-banner-wrapper')[0];

        // const tabEle = document.getElementById('tabs-holder') || null;
        // const xfullbanner= document.getElementsByClassName('x-full-banner-wrapper');
        // const xfullbannerHeight = xfullbanner&& xfullbanner.length ? xfullbanner[0].offsetHeight : 0;
        // if (tabEle && bannerEle && Math.abs(bannerEle.getBoundingClientRect().top) + (isMobile() ? hasTrailer ? 228 : 100 : 100 )  > (bannerEle.offsetHeight + (xfullbannerHeight ? xfullbannerHeight : 0))) {
        //     tabEle.classList.add('fixed-tab-area');
        //     if (isMobile()) {
        //         tabEle.style['top'] = hasTrailer ? TAB_POSITION.TOP_208 : TAB_POSITION.TOP_62;
        //     }
        // } else if ((tabEle && tabEle.classList.contains('fixed-tab-area'))) {
        //     tabEle.classList.remove('fixed-tab-area');
        // }

        const { deviceUtil } = this.props;

        const { isMobile } = deviceUtil;
        const mwebTrailer = document.getElementsByClassName('mweb-trailer')[0] || document.getElementsByClassName('mobile-player-container')[0];
        const videoPlayerHeight = document.getElementsByClassName('current-vdo__player ')[0];
        const headerEle = getElementById(ELEMENT_ID.HEADER_WEB);
        if (isMobile() && (mwebTrailer && mwebTrailer.classList.contains('opaque'))) {
            this.setState({ hasTrailer: true });
        }
        const { hasTrailer } = this.state;
        const episodeEle = getElementById('bottom-tile-holder');
        const tabEle = document.getElementById('tabs-holder') || null;
        if (!tabEle || !headerEle) {
            return;
        }
        const topHeight = hasTrailer ? (headerEle?.offsetHeight + (videoPlayerHeight)?.offsetHeight) : (headerEle?.offsetHeight + tabEle?.offsetHeight);
        if (tabEle.getBoundingClientRect().top - topHeight <= 0 && Math.abs(episodeEle.getBoundingClientRect().top) - topHeight < 0) {
            tabEle.classList.add('fixed-tab-area');
            if (isMobile()) {
                tabEle.style.top = hasTrailer ? `${topHeight}px` : TAB_POSITION.TOP_62;
            }
        }
        else if (episodeEle.getBoundingClientRect().top > topHeight && (tabEle && tabEle.classList.contains('fixed-tab-area'))) {
            tabEle.classList.remove('fixed-tab-area');
        }
    };

    componentWillUnmount() {
        if (checkWindowExist()) {
            window && window.removeEventListener('scroll', this.scrollEventHandling);
        }
    }

    sendTabChangeEvent(activeTab) {
        const title = activeTab?.contentDetails?.title || '';
        const meta = {
            action: `${title} click`,
            source_name: SOSUtil.getRouteSourceNameMappedFromCMS(getSourceName(location.pathname)),
            asset_name: title,
        };

        contentDetailsEvent(meta, EventType.TAB_CLICK);
    }

    setActivetab = ({ index }) => {
        this.setState({ activeTab: index });
    };

    autoScroll = () => {
        setTimeout(() => {
            requestAnimationFrame(() => {
                const el = document.querySelector('.episode-down-area') || document.getElementById('layoutSectionElement');
                if (!el) {
                    // autoScroll();
                    return;
                }
                const { y } = el?.getBoundingClientRect() || Infinity;

                let headerRect = document.querySelector('header')?.getBoundingClientRect();
                if (this.deviceUtil.isMobile()) {
                    headerRect = document.getElementById('mobile-top-name-wrapper')?.getBoundingClientRect();
                }
                const tabsHolderRect = document.querySelector('#tabs-holder')?.getBoundingClientRect();
                const buffer = headerRect?.height + tabsHolderRect?.height - 24;
                if (y < buffer) {
                    window?.scrollTo({
                        top: window.scrollY + el.getBoundingClientRect().y - buffer,
                        behavior: 'smooth',
                    });
                }
            });
        }, 100);
    };

    render() {
        const { activeTab } = this.state;
        const {
            tiles, contentId, showPlayback, playingOnBanner, pageId: analyticsPageId
        } = this.props;
        if (showPlayback || playingOnBanner) {
            return null;
        }
        const {
            contentDetails: {
                hydrationType = '',
                hydrationAdditionalQueryParams: {
                    pageId = '',
                } = {},
            } = {},
        } = tiles[activeTab] || {};
        return (
            <div>
                {tiles && tiles.length > 1
                && (
                    <div>
                        <div
                            id="tabs-holder"
                            className="tab-holder-area"
                            style={{ display: 'flex' }}
                        >
                            <TabContainer
                                tiles={tiles}
                                setActiveTab={this.setActivetab}
                                cdpActiveTabInitial={this.state.activeTab}
                                contentId={contentId}
                                autoScroll={this.autoScroll}
                            />
                        </div>
                    </div>
                )
                }
                <div
                    id="bottom-tile-holder"
                    className="bottom-tile-area"
                >
                    <MultiListTabContainer
                        hydrationType={hydrationType}
                        pageId={pageId}
                        analyticsPageId={analyticsPageId}
                        contentId={contentId}
                        props={this.props}
                        LayoutContainerWrapper={LayoutContainerWrapper}
                        EpisodeRailComponent={EpisodeRailComponent}
                        underMultiListRail
                    />
                </div>
            </div>
        );
    }
}

MultiRailsListContainer.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    tiles: PropTypes.array,
    contentId: PropTypes.string,
    showPlayback: PropTypes.bool,
};

MultiRailsListContainer.defaultProps = {
    tiles: [],
    contentId: '',
    showPlayback: false,
};

function mapStateToProps(state) {
    const {
        notify: {
            showPlayback,
            playingOnBanner,
        },
        appConfig: { web_pageId },
    } = state;
    return {
        showPlayback,
        playingOnBanner,
        webPageId: web_pageId,
    };
}

export default withDeviceUtil(withRouter(connect(
    mapStateToProps, {
    },
)(withStyles(styles)(MultiRailsListContainer))));
