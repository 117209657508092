import React from 'react';
import { getTile } from '../factories/TileFactory';

const NormalRailComponent = (tileProps) => {
    const tileElement = getTile({ tileProps });
    return (
        <div className="section-title-wrapper">
            {tileElement}
        </div>
    );
};

export default React.memo(NormalRailComponent);
