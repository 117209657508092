import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BANNER_INTERVAL } from '@airtel-tv/constants/LayoutConstants';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './SlickButtonComponent.scss';

export const SlickButtonComponent = (props) => {
    const {
        isActive, translateBanner, index, tilesRendered, bannerSpeed, playSlider, activeIndex
    } = props;
    // useEffect(() => {
    //     const spacer = document.getElementById('progress');
    //     setTimeout(() => {
    //         spacer.style.animation = `slide-animation ${bannerSpeed || (BANNER_INTERVAL.NEXT_TILE / 1000)}s linear`;
    //         if(playSlider === false) {
    //             spacer.style.animationPlayState = 'paused';
    //         } else if(playSlider) {
    //             spacer.style.animationPlayState = 'running';
    //         }
    //     });
    // }, []);
    useEffect(() => {
        if (isActive) {
            const spacer = document.getElementsByClassName('progress')[0];
            spacer.style.animation = `slide-animation ${bannerSpeed || (BANNER_INTERVAL.NEXT_TILE / 1000)}s linear`;
            if(playSlider === false) {
                spacer.style.animationPlayState = 'paused';
            } else if(playSlider) {
                spacer.style.animationPlayState = 'running';
            }
        }
        else {
            const spacer = document.getElementsByClassName('progress-start');
            for (let i = 0; i < spacer.length; i++) {
                if(activeIndex !== i) {
                    // console.log(spacer[i], '-------->',i, isActive);
                    // spacer[i].style.width = 0;
                    spacer[i].style.removeProperty('animation');
                } 
            }
        }
    }, [
        isActive,
        tilesRendered,
        bannerSpeed,
        playSlider
    ]);

    return (
        <button
            className="indicatore_button "
            type="button"
            onClick={() => {
                translateBanner(index + 2);
            }}
        >
            <div
                id="indicate"
                className={`indicator ${isActive ? 'highlight' : ''}`}
            >
                <span
                    id="progress"
                    className={`progress-start ${isActive ? 'progress' : ''}`}
                />
            </div>

        </button>
    );
};

SlickButtonComponent.defaultProps = {
    sliderTimer: '',
    tilesRendered: false,
};

SlickButtonComponent.propTypes = {
    isActive: PropTypes.bool.isRequired,
    translateBanner: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    sliderTimer: PropTypes.string,
    tilesRendered: PropTypes.bool,
};

export default withStyles(styles)(SlickButtonComponent);
