import {
    call, put, getContext, select,
} from 'redux-saga/effects';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { takeFirst } from '../../../utilities/ReduxSagaUtil';
import { isOnline } from '../../../utilities/WindowUtil';
import { getRailDetails } from '../../../service/end-points/LayoutApiService';
import RailActions, {
    railFetchActionError, railFetchActionSuccess,
} from '../actions/RailActions';
import { getUserConfigFromReducer } from '@airtel-tv/redux/StoreListing';

export function* fetchRails(action) {
    const { railIds } = action;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const { userContentProperties } = yield select(getUserConfigFromReducer);
        const response = yield call(getRailDetails, {
            railIds,
            deviceUtil,
            userContentProperties,
        });

        railIds.forEach((railId) => {
            if (!response[railId]) {
                response[railId] = {
                    noData: true,
                };
            }
        });

        const payload = {
            ...response,
            error: null,
        };

        yield put(railFetchActionSuccess({ payload }));
    }
    catch (e) {
        console.error(e);
        let error = e && e.data ? e.data : e;

        if (error === ERROR_CODES.LOCAL1007 || !isOnline()) {
            error = { errorcode: ERROR_CODES.LOCAL1002 };
        }

        const payload = {
            error,
        };

        yield put(railFetchActionError({ payload }));
    }
}

export default [
    takeFirst(RailActions.RAIL_FETCH, fetchRails),
];
