import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ParagraphElement, ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { ELEMENT_ID } from '@airtel-tv/constants/GlobalConst';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { RAIL_TYPES } from '@airtel-tv/constants';
import { getDistroFreeChannelTiles } from '@airtel-feature/layout/utils/LayoutUtil';
import { buildTile } from '@airtel-feature/layout/builders/TileBuilder';
import { connect } from 'react-redux';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { checkWindowExist } from '../../../utilities/WindowUtil';
import { getTile } from '../factories/TileFactory';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';

const ListRailComponent = (props) => {
    const { tiles, showGrid, deviceUtil: { isMobile }, history } = props;
    const carouselElementRef = useRef();
    const [
        indexOfRail,
        setRailIndex,
    ] = useState(0);

    const [
        hoveredTile,
        setHoveredTile,
    ] = useState('');

    /*
    SortedTiles were updated via sortButtonComponent, since we are not using that part
    Hence, commenting it for now
    SortedTiles shouldn't be used, ideally for sorting we will be getting sortedTiles response
    Hence we will not be needing that part
    */
    const {
        propertyClassName, railTitle, railCtaActionUrl, railIndex, railId, imageSettings, onRemoveIconClick,
        preferredArtwork,
        tileWidth,
        tileHeight,
        packageId,
        cpDetailsById,
        distroData,
        isFreeChannelsRail = false,
        hideImageAltTag = false,
        hideTileBottomeDetails = false,
        customClassTileLevel = '',
    } = props;


    let items = tiles;

    if (tiles && Array.isArray(tiles) && tiles.length < 1) {
        return null;
    }

    if (isFreeChannelsRail) {
        items = getDistroFreeChannelTiles({
            cpId: 'DISTROTV',
            distroData,
            buildTile,
            railType: RAIL_TYPES.X_FREE_CHANNELS,
            // RAIL_CTA_LIST: ,
            preferredArtwork: 'LOGO_HD',
            cpDetailsById,
        });
    }
    let tileElements = [];
    if (items && Array.isArray(items)) {
        tileElements = items.map((tile, i) => {
            const tileStyle = {
                width: `${tileWidth}px`,
                height: `${tileHeight}px`,
            };
            const tileProps = {
                key: `${tile.id}-${railId}`,
                propertyClassName,
                ...tile,
                railCtaActionUrl,
                railPosition: railIndex,
                tilePosition: i,
                railId,
                imageSettings,
                showGrid,
                isListRail: true,
                hoveredTile,
                setHoveredTile,
                preferredArtwork,
                tileWidth,
                tileHeight,
                style: tileStyle,
                wrapperId: railId,
                packageId,
                isFreeChannelsRail,
                hideImageAltTag,
                hideTileBottomeDetails,
                customClassTileLevel,
                railElementId: ELEMENT_ID.LIST_RAIL,
            };

            const tileElement = getTile({
                tileProps,
                onRemoveIconClick,
            });

            return tileElement;
        });
    }
    // check if there is an error
    else if (typeof tiles === 'object') {
        // TODO: Handle error
    }

    useEffect(() => {
        if (carouselElementRef && carouselElementRef.current) {
            const images = carouselElementRef.current.children;
            carouselElementRef.current.addEventListener('scroll', () => {
                const railInd = Math.ceil(carouselElementRef.current.scrollLeft / (images[0].offsetWidth + 4));
                setTimeout(() => {
                    setRailIndex(railInd);
                }, 500);
            });
        }
    }, [
        tileElements,
    ]);

    const next = () => {
        if (carouselElementRef.current) {
            const { clientWidth } = carouselElementRef.current;
            carouselElementRef.current.scrollLeft += clientWidth;
        }
    };

    const previous = () => {
        if (carouselElementRef.current) {
            const { clientWidth } = carouselElementRef.current;
            carouselElementRef.current.scrollLeft -= clientWidth;
        }
    };

    const showScrollButtons = () => {
        if (!checkWindowExist()) {
            return;
        }
        const { availWidth } = window.screen;
        const totalTileWidth = tileElements.length * 275;

        return (availWidth - 160) / totalTileWidth < 1;
    };

    const { scrollLeft = 0, clientWidth = 0, scrollWidth = 0 } = carouselElementRef && carouselElementRef.current ? carouselElementRef.current : {};
    const showNext = (scrollLeft < (scrollWidth - clientWidth) || (indexOfRail < tiles.length - Math.floor(clientWidth / 260)));

    return (
        <section
            className="m-b-30 more-like-container m-t-30 grid-page-channel grid-for-mobile "
            name={railTitle}
        >
            <ParagraphElement />
            {/* to be reviewed */}
            <section className="title title-with-cta d-flex justify-content-between align-items-center watch-list-align m-l-10">
                {/* CHANGE THE TITLE DYNAMICALLY BASED ON MOVIE CATEGORY */}
                <h1
                    id="rail-title"
                    className="home-page-font text-bold-20 mobile-text-bold-16 scoped-view-title"
                    name={railTitle}
                >
                    <AnalyticsButtonComponent
                        className="back-btn"
                        onClick={() => {
                            history.go(-1);
                        }}
                    />
                    {railTitle}
                </h1>

                {/*
                    COMMENTING THIS PART, Because sorting is expected to be handled from backend
                    When will get an api from backend with sorted response, we will integrate that api with relevant component
                    till that time, hiding the sort button component
                    {sortConfig ? (
                        null
                        // <SortButtonComponent
                        //     sortedEvent={sortedEvent}
                        //     sortConfig={sortConfig}
                        //     sortingTarget={tiles}
                        // />
                    ) : null} */
                }
            </section>
            <section
                className="pos-relative end-to-end-rail-wrapper"
                id={ELEMENT_ID.LIST_RAIL}
            >

                { !isMobile() && scrollLeft > 0 && (
                    <button
                        className="slide-btn-cdp left landscape"
                        onClick={previous}
                    >
                        <ThumborImage
                            src="/static/cdp-assets/chev-sside.svg"
                        />
                    </button>
                ) }
                <section
                    className="wrapper-inner cards-portrait-wrapper cards-portrait-wrapper--mobile d-flex flex-wrap position-relative grid-page-for-mobile"
                    ref={carouselElementRef}
                    id={railId}
                >


                    {tileElements}

                </section>

                { !isMobile() && showScrollButtons() && showNext && (
                    <button
                        className="slide-btn-cdp right landscape "
                        onClick={() => next(tileElements.length)}
                    >
                        <ThumborImage
                            src="/static/cdp-assets/chev-sside.svg"
                        />
                    </button>
                ) }
            </section>
        </section>
    );
};

ListRailComponent.defaultProps = {
    propertyClassName: '',
    railCtaActionUrl: '',
    railIndex: 0,
    railId: '',
    showGrid: false,
    onRemoveIconClick: () => null,
    packageId: '',
};

ListRailComponent.propTypes = {

    railTitle: PropTypes.string.isRequired, // eslint-disable-next-line react/forbid-prop-types
    tiles: PropTypes.array.isRequired,
    propertyClassName: PropTypes.string,
    railCtaActionUrl: PropTypes.string,
    railIndex: PropTypes.number,
    railId: PropTypes.string, // eslint-disable-next-line react/forbid-prop-types
    imageSettings: PropTypes.object.isRequired,
    onRemoveIconClick: PropTypes.func,
    showGrid: PropTypes.bool,
    deviceUtil: PropTypes.object.isRequired,
    packageId: PropTypes.string,
};

function mapStateToProps(state, props) {
    const {
        appConfig: {
            cpDetailsById,
        },
        distroDetails: {
            distroData = [],
        } = {},
    } = state;
    return {
        cpDetailsById,
        distroData,
    };
}

export default withDeviceUtil(connect(mapStateToProps, {})(React.memo(((withRouter(ListRailComponent))))));
