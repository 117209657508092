import { PropTypes } from 'prop-types';
import React from 'react';
import Loadable from 'react-loadable';
import { LOCAL_RAIL_TYPES, RAIL_TYPES } from '@airtel-tv/constants/LayoutConstants';
import BannerContentRailContainer from '@airtel-feature/content-details/content-detail-page/container/BannerContentRailContainer';
import XstreamLayoutComponent from '@airtel-feature/content-details/content-detail-page/component/XstreamLayoutComponent/XstreamLayoutComponent';
import CastAndMoreComponent from '@airtel-feature/content-details/content-detail-page/component/CastAndMoreComponent/CastAndMoreComponent';
import StarringRailLayoutComponent from '@airtel-feature/content-details/content-detail-page/component/StarringRailLayoutComponent/StarringRailLayoutComponent';
import PillsRailComponent from '@airtel-tv/ui-lib/molecules/rail/PillRailComponent';
import OneHubRail from '@airtel-feature/onehub/components/OneHubRail/OneHubRail';
import ChannelPartnerDetailTile from '@airtel-feature/onehub/components/ChannelPartnerDetail/ChannelPartnerDetailTile';
import ComingSoonComponent from '@airtel-feature/onehub/components/ComingSoon/ComingSoonComponent';
import PendingActivationRail from '@airtel-feature/onehub/components/PendingActivationRail/PendingActivationRail';
import TvShowLogo43RailComponent from '../components/TvShowLogo43RailComponent';
import FeaturedSliderComponent from '../components/FeaturedSliderComponent';
import SmallerLandscapeSliderRailComponent from '../components/SmallerLandscapeSliderRailComponent';
import ListRailComponent from '../components/ListRailComponent';
import {
    LazyContinueWatchingRailContainer,
    LazyWatchlistRailContainer,
    LazyFooterComponentRail,
    LazyGridRoundSectionRailComponent,
    LazyNormalRailComponent,
    LazyAddSectionRailComponent,
} from '../../../utilities/code-splitting/LazyRails';
import ChannelScheduleRailComponent from '../components/ChannelScheduleRailComponent';
import CatchupEpisodeRailComponent from '../components/CatchupEpisodeRailComponent';
import TrendingRailComponent from '../components/TrendingRailComponent';
import SubscribedChannelsRailContainer from '../containers/SubscribedChannelsRailContainer';
import MultiRailsListContainer from '../components/MultiRailsListContainer';
import LoaderComponent from '../../shared/components/loader/LoaderComponent';
import DimensionRailComponent from '../components/DimensionRailComponent';
import TrailersRailComponent from '../components/TrailersRailComponent';
// import PYWRailComponent from '../components/PYWRailComponent';
import ErrorHandler from '../../error-handler/ErrorHandler';
import XFullBannerComponent from '../components/XFullBannerComponent/XFullBannerComponent';
import XTopNavigationBar from '../components/XTopNavigationBar/XTopNavigationBar';
import { getTile } from './TileFactory';

const ChannelPartnerWidgetContainer = Loadable({
    loader: () => import('../../channel-partner/container/ChannelPartnerWidgetContainer' /* webpackChunkName: "Channel Partner Widget" */),
    loading: () => (
        <LoaderComponent
            defaultShimmer
            shimmerHeight="335px"
        />
    ),
});

export const getRail = ({ railProps = {} }) => {
    switch (railProps.railType) {
        case RAIL_TYPES.LARGE_SLIDER_SECTION_RAIL:
            return (
                <FeaturedSliderComponent
                    {...railProps}
                    getTile={getTile}
                />
            );
        case RAIL_TYPES.X_TOP_NAVIGATION_BAR:
            return (
                <XTopNavigationBar {...railProps} />
            );
        case RAIL_TYPES.X_FULL_BANNER:
            return <XFullBannerComponent {...railProps} />;
        case RAIL_TYPES.X_PILLS_RAIL:
            return (
                <PillsRailComponent
                    railProps={{
                        ...railProps,
                        nonQuickViewRail: true,
                    }}
                    getTile={getTile}
                />
            );
        case LOCAL_RAIL_TYPES.CHANNEL_PARTNER_WIDGET:
            return <ChannelPartnerWidgetContainer {...railProps} />;
        case RAIL_TYPES.PARTNER_CHANNEL_DETAILED_RAIL:
            return (
                <ChannelPartnerDetailTile
                    {...railProps}
                    isLargeScreen={false}
                />
            );
        case RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_PORTRAIT:
            return <XstreamLayoutComponent {...railProps} />;
        case RAIL_TYPES.X_EXPLORE_V2:
            return (
                <XstreamLayoutComponent
                    {...railProps}
                    seeAllArtWork="SQUARE"
                    containerClass="Sqaure-tile-wrapper"
                    slideBtnClass="square"
                />
            );
        case RAIL_TYPES.NORMAL_SLIDER_SECTION_RAIL_LANDSCAPE:
            return <XstreamLayoutComponent {...railProps} />;
        case RAIL_TYPES.TVSHOW_LOGO_43_RAIL:
            return <TvShowLogo43RailComponent {...railProps} />;
            // return <XstreamLayoutComponent {...railProps} />;
        case RAIL_TYPES.GRID_RECTANGLE_CHANNEL_RAIL:
            return <SmallerLandscapeSliderRailComponent {...railProps} />;
        case RAIL_TYPES.GRID_SQUARE_EXPLORE_RAIL:
            return <XstreamLayoutComponent {...railProps} />;
        case LOCAL_RAIL_TYPES.LIST:
            if (railProps.webPageId && Object.keys(railProps.webPageId).length > 0 && railProps.pageId && railProps.pageId === railProps.webPageId.ARTIST_DETAIL) {
                return <XstreamLayoutComponent {...railProps} />;
            }
            return <ListRailComponent {...railProps} />;
        case RAIL_TYPES.ADD_SECTION_RAIL:
            return <LazyAddSectionRailComponent {...railProps} />;
        case RAIL_TYPES.GRID_ROUND_SECTION_RAIL:
            return <LazyGridRoundSectionRailComponent {...railProps} />;
        case RAIL_TYPES.BORDER_SECTION_RAIL:
            return <LazyFooterComponentRail {...railProps} />;

        case LOCAL_RAIL_TYPES.CATCHUP_EPISODE_RAIL:
            return <CatchupEpisodeRailComponent {...railProps} />;

        case RAIL_TYPES.HEADING_SECTION_RAIL:
            return <LazyNormalRailComponent {...railProps} />;
        case RAIL_TYPES.CONTINUE_WATCHING_RAIL:
        case RAIL_TYPES.CONTINUE_WATCHING_RAIL_V2:
            return <LazyContinueWatchingRailContainer {...railProps} />;
        case RAIL_TYPES.X_PYW_RAIL:
            return (
                <LazyWatchlistRailContainer
                    {...railProps}
                    isPywWatchListRail
                />
            );
        case RAIL_TYPES.WATCHLIST_RAIL:
        case RAIL_TYPES.WATCHLIST_RAIL_V2:
            return <LazyWatchlistRailContainer {...railProps} />;
        case RAIL_TYPES.YOUR_CHANNELS_RAIL:
            return <SubscribedChannelsRailContainer {...railProps} />;
        case LOCAL_RAIL_TYPES.DIMENSION_LIST:
            return <DimensionRailComponent {...railProps} />;
        case LOCAL_RAIL_TYPES.CHANNEL_SCHEDULE:
            return <ChannelScheduleRailComponent {...railProps} />;

        case LOCAL_RAIL_TYPES.TRENDING_RAIL:
            return <TrendingRailComponent {...railProps} />;
        case RAIL_TYPES.EXPLORE_CHANNEL_PARTNER:
        case RAIL_TYPES.X_FREE_CHANNELS:
            return <XstreamLayoutComponent {...railProps} />;
        case RAIL_TYPES.MULTILIST_RAIL:
            return <MultiRailsListContainer {...railProps} />;
        case RAIL_TYPES.BANNER_CONTENT_RAIL:
            return (
                <BannerContentRailContainer
                    ErrorHandler={ErrorHandler}
                    {...railProps}
                />
            );
        case RAIL_TYPES.RELATED_CONTENT_RAIL:
            return <XstreamLayoutComponent {...railProps} />;
        case RAIL_TYPES.CONTENT_DESCRIPTION_DETAIL_RAIL:
            return <CastAndMoreComponent {...railProps} />;
        case RAIL_TYPES.PEOPLE_RAIL:
            return <StarringRailLayoutComponent {...railProps} />;
        case RAIL_TYPES.TRAILERS_RAIL:
            return <TrailersRailComponent {...railProps} />;
        // case RAIL_TYPES.X_PYW_RAIL:
        //     return <></>;
        case RAIL_TYPES.X_VERTICAL_TRAILER_RAIL:
            return (
                <XstreamLayoutComponent
                    {...railProps}
                    isPYWRail
                    showRailTitle={false}
                    nonQuickViewRail
                    showMore={false}
                    containerClass="pyw_rail"
                    customRailSlideBtnClass="pyw_slide_btn"
                    customTileLimit={railProps?.tiles?.length}
                />
            );
        case RAIL_TYPES.X_PLAN_LISTING_RAIL:
            return (
                <OneHubRail
                    railComponent={XstreamLayoutComponent}
                    railProps={{
                        ...railProps,
                        nonQuickViewRail: true,
                    }}
                    expandRailProps
                    showRailScrollButtons={false}
                    morePlansPageState="show"
                    isPlanListingRail
                    containerClass="plan-listing-rail"
                />
            );
        case RAIL_TYPES.X_ACTIVE_PLAN:
            return (
                <OneHubRail
                    railComponent={XstreamLayoutComponent}
                    railProps={{
                        ...railProps,
                    }}
                    expandRailProps
                    showRailScrollButtons={false}
                    morePlansPageState="hide"
                />
            );
        case RAIL_TYPES.X_PARTNER_ACTIVATION_RAIL:
            return (
                <OneHubRail
                    railComponent={XstreamLayoutComponent}
                    railProps={{
                        ...railProps,
                        isActivationRail: true,
                        railCollapsible: railProps?.zionDisplay?.planKey === 'xppSubscriptionStatus' || '',
                    }}
                    expandRailProps
                    showRailScrollButtons={false}
                    morePlansPageState="hide"
                />
            );
        case RAIL_TYPES.X_PLAN_COMING_SOON:
            return (
                <ComingSoonComponent componentProps={{
                    ...railProps,
                    titleHeading: railProps.railTitle,
                    titleSubheading: railProps.railSubtitle,
                }}
                />
            );
        case RAIL_TYPES.X_PARTNER_LANDSCAPE_RAIL:
            return <XstreamLayoutComponent {...railProps} />;
        // case RAIL_TYPES.X_VERTICAL_TRAILER_RAIL:
        //     return <PYWRailComponent/>;
        case RAIL_TYPES.X_PENDING_ACTIVATION_RAIL:
            return (
                <PendingActivationRail tileProps={{
                    ...railProps,
                    ...railProps?.tiles?.[0] || {},
                }}
                />
            );
        default:
            return null;
    }
};

getRail.propTypes = {
    railProps: PropTypes.shape({
        railType: PropTypes.string.isRequired,
    }).isRequired,
    onRemoveIconClick: PropTypes.func.isRequired,
};

export default getRail;
